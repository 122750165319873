import { useNavigate } from "react-router";
import {setToken, decodeToken, useAuth} from "../../Auth";
import {useState, useCallback, useEffect} from "react";
import FastAPIClient from "../../client";
import config from "../../config";
import {Navigate} from "react-router-dom";

export default function Login() {
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const client = new FastAPIClient(config);


  //check to see if the fields are not empty
  const login = (event) => {
      event.preventDefault();
      if ((username === "") & (password === "")) {
          return;
      } else {
        let loginPayload = {}
        loginPayload.username = username
        loginPayload.password = password

        client.postLogin(loginPayload).then(function (response) {
          if (response.data.access_token) {
            setUser(decodeToken(response.data.access_token));
            setToken(response.data.access_token);
            setTimeout(()=> {
             navigate('/');
            }, 200);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    }
  };

  return (
    <>
      <section className="vh-100 gradient-custom">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="card BookPage text-dark">
                <div className="card-body p-5 text-center">

                  <div className="mb-md-5 mt-md-4 pb-5">

                    <h2 className="fw-bold mb-2 Title">Login</h2>
                    <p className="text-dark mb-5">Please enter your login and password!</p>
                    <form onSubmit={login}>
                    <div className="form-outline form-white mb-4">
                      <input type="username" id="typeEmailX" className="form-control form-control-lg" onChange={(e) => setUsername(e.target.value)}/>
                      <label className="form-label" htmlFor="typeEmailX">User name</label>
                    </div>

                    <div className="form-outline form-white mb-4">
                      <input type="password" id="typePasswordX" className="form-control form-control-lg" onChange={(e) => setPassword(e.target.value)}/>
                      <label className="form-label" htmlFor="typePasswordX">Password</label>
                    </div>

                    <button className="btn btn-outline-dark btn-lg px-5" type="submit">Login</button>
                    </form>
                  </div>

                  <div>
                    <p className="mb-0">Don't have an account? <a href="#!" className="text-dark fw-bold">Sign
                      Up</a>
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
