import React, { useEffect, useState, useContext, useRef} from 'react';
import FastAPIClient from '../../client';
import config from '../../config';
import '../../my.css';
import 'JSON';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from 'react-bootstrap/Container';
import {generatePath, Link, useNavigate, useParams} from "react-router-dom";
import {fetchToken} from "../../Auth";
import SceneList from "../../components/scenelist";
import Collapse from "react-bootstrap/Collapse";
import AreaListMap from "../../components/arealist_map";
import {ListGroup, ListGroupItem} from "react-bootstrap/"

const client = new FastAPIClient(config);

const PlayMap = () => {
    const {token} = useParams();

    let scale = 1.2;
    let campaignId = 0;
    const [loading, setLoading] = useState(true)
    const [areaId, setAreaId] = useState({})
    const [mapId, setMapId] = useState({})
    const [combatId, setCombatId] = useState({})
    const [position, setPosition] = useState([0,0]);
    const [resolution, setResolution] = useState([1900,1080]);
    const [campaignAreas, setCampaignAreas] = useState([])
    const [scene, setScene] = useState({})
    const [combat, setCombat] = useState({})
    const [imageUrl, setImageUrl] = useState({})
    const [fow, setFow] = useState({})
    const [image, setImage] = useState({})



    useEffect(() => {
        if (scene.areas !== undefined)
        moveCam(scene.areas?.filter((area) => area.area_id === areaId)[0])
    },[scene])


    useEffect(() => {
              const ws = new WebSocket('wss://api.sorenkoelbaek.dk/ws/ws?token=' + token);
              ws.onopen = (event) => {
                console.log("logged in")
                  setLoading(false)
              };
              ws.onmessage = function (event) {
                  try {
                      var msg = event.data.split(":")

                      if (msg[0] === "Campaign") {
                           fetchCampaign(parseInt(msg[1]))
                      }
                      if (msg[0] === "Area" && parseInt(msg[1]) !== areaId) {
                          setAreaId(parseInt(msg[1]))
                          setCampaignAreas( campaignAreas => [...campaignAreas, parseInt(msg[1])])
                          fetchArea(parseInt(msg[1]))
                      }
                      if (msg[0] === "combat" && parseInt(msg[1]) !== combatId) {
                          setCombatId(parseInt(msg[1]))
                          if (parseInt(msg[1]) > 0) {
                             fetchCombat(parseInt(msg[1]))
                          }

                      }


                  } catch (err) {
                      console.log(err);
                  }
              };




    }, [token]);

    const [encounters, setEncounters] = useState([])
    const [participants, setParticipants] = useState([])

    const alive = (hp) => {
         if(hp<=0) {
             return "Dead"
         }
         else {
             return "alive"
         }
    }
    useEffect(() => {
    }, [participants])

    useEffect(() => {
        if (combat.party !== undefined) {
           handleParticipants(combat)
        }

    }, [combat])

    const handleParticipants = (combat) => {

        let groupList = []
        let groupIndex = 0;
        let ParticipantList = []
        combat.party.party_members?.sort((a, b) => b.initiative - a.initiative).map(char => {
            console.log(char)
            char.id = char.character_id
            char.type = 'character'
            char.state = alive(char.hit_points - char.damage)

            char.turn = ""
            if (combat.turn_type === 'character' && combat.turn_id === char.character_id) {
                char.turn = 'Active'
            }
            ParticipantList.push(char)
        })
        let encounterlist = []
        combat.encountergroups.map(encgroup => {
            encgroup.encounters.map(enc => {
                encounterlist.push(enc)
                if(groupList.includes(encgroup.encountergroup_id)) {
                    enc.group_name = 'Group_' + groupIndex
                }
                else {
                    groupIndex= groupIndex+1
                    enc.group_name = 'Group_' + groupIndex
                    groupList.push(encgroup.encountergroup_id)

                }
                enc.id = enc.encounter_id
                enc.type = 'encounter'
                enc.group_id = encgroup.encountergroup_id
                enc.state = alive(enc.monster.hit_points - enc.damage)
                enc.name = enc.monster.name
                enc.turn = ""
                if (combat.turn_type === 'encounter' && combat.turn_id === enc.encounter_id) {
                    enc.turn = 'Active'
                } else {

                }
                ParticipantList.push(enc)
            })
        })
        setEncounters(encounterlist)
        setParticipants(ParticipantList)
    }


    useEffect(() => {
        if (scene['maps'] !== undefined) {
            if (scene['maps'][0]['map_id'] !== image.map_id) {
                setImage(scene['maps'][0])
            }
        }
    },[scene])


    const moveCam = (area) => {
        let oldy = position[1]
        let minx = resolution[0]
        let maxx = 0
        let miny = resolution[1]
        let maxy = 0
        let imgwidth = ((scene.maps[0].width*(scene.maps[0].imagescale/100)) * (scene.maps[0].scale/scene.maps[0].imagescale)*scale)
        let imgheight = ((scene.maps[0].height*(scene.maps[0].imagescale/100)) * (scene.maps[0].scale/scene.maps[0].imagescale)*scale)
        let scenewidth = (scene.maps[0].width*(scene.maps[0].imagescale/100))
        let sceneheight = (scene.maps[0].height*(scene.maps[0].imagescale/100))

        let widthConstant = imgwidth/scenewidth
        let heightConstant = imgheight/sceneheight




        area.coordinates?.map(coord => {

            if (coord.x-(parseInt(scene.maps[0].origin.split(":")[0])+10) < minx) {
                minx = coord.x-(parseInt(scene.maps[0].origin.split(":")[0]))+10
            }
             if (coord.x-(parseInt(scene.maps[0].origin.split(":")[0]))+10+coord.width > maxx) {
                maxx = coord.x-((parseInt(scene.maps[0].origin.split(":")[0]))+10)+coord.width
            }
             if (coord.y-(parseInt(scene.maps[0].origin.split(":")[1]))+5 < miny) {
                miny = coord.y-(parseInt(scene.maps[0].origin.split(":")[1]))+5
            }
             if (coord.y-(parseInt(scene.maps[0].origin.split(":")[1]))+5+coord.height > maxy) {
                maxy = coord.y-((parseInt(scene.maps[0].origin.split(":")[1]))+5)+coord.height
            }


        })
        //determining if  Outside bounds on either side
       if (imgwidth > resolution[0])
       {
           let xpos = position[0]
           let Lviewport = (imgwidth-resolution[0])/2
           let Rviewport = (imgwidth-resolution[0])/2+resolution[0]


           //Make sure we are not going both directions
            if (((maxx*widthConstant)>(Rviewport-xpos)) && !((minx*widthConstant)<(Lviewport+xpos)))
            {
                xpos = Rviewport-(maxx*widthConstant)
                console.log("going Right")
            }
             if (((minx*widthConstant)<(Lviewport+xpos)) && !((maxx*widthConstant)>(Rviewport-xpos)))
            {
                xpos = Lviewport-(minx*widthConstant)
                console.log("going Left")
            }

         console.log("SceneHeight", sceneheight)
        console.log("imgheight", imgheight)

           let ypos = position[1]
           let UViewport = (imgheight-resolution[1])/2
           let DViewport = (imgheight-resolution[1])/2+resolution[1]-100
            console.log(UViewport, DViewport, miny, maxy)

            if (((maxy*heightConstant)>(DViewport-ypos)) && !((miny*heightConstant)<(UViewport-ypos)))
            {
                ypos = DViewport-(maxy*heightConstant)
                console.log("going Down")
            }
             if (((miny*heightConstant)<(UViewport-ypos)) && !((maxy*heightConstant)>(DViewport-ypos)))
            {
                ypos = UViewport-(miny*heightConstant)
                console.log("going Going up")
            }




            setPosition([xpos, ypos])

       }

    }

    const fetchCampaign = (id) => {
        client.getCampaign(id).then((data) => {
            setCampaignAreas(data.data['areas'].map((area)=> area.area_id));
            campaignId = id;
      });
    }
    const fetchArea = (id) => {
    client.getSceneFromArea(id).then((data) => {
        setScene(data.data)
        if (data.data.maps[0].imageUrl !== undefined) {
            if (imageUrl !== data.data.maps[0].imageurl) {
            setImageUrl(data.data.maps[0].imageUrl)
            setPosition([0,0])
            }
        }

        if (Number.isInteger(campaignId)) {
            fetchCampaign(campaignId)
        }

      });
    }

    const fetchCombat = (id) => {
    client.getcombat(id).then((data) => {
        setCombat(data.data)
      });
    }

    if(!loading) {
        if (scene.maps !== undefined && campaignAreas.length !== 0) {
            return (
                <div ClassName="Fuck">
                <div className="map_container">

                    <div className="map_image"
                         style={{
                             left: position[0],
                             top: position[1],
                             width: (scene.maps[0].width * (scene.maps[0].imagescale / 100)),
                             height: (scene.maps[0].height * (scene.maps[0].imagescale / 100)),
                             transform: "scale(" + (scene.maps[0].scale / scene.maps[0].imagescale) * scale + ")"
                         }}
                    >
                        <svg style={{
                            zIndex: 10,
                            position: "absolute",
                            //top: (resolution[1]/2)-((scene.maps[0].height*(scene.maps[0].scale/100)))/2,
                            //left: (resolution[0]/2)-((scene.maps[0].width*(scene.maps[0].scale/100)))/2,
                            width: (scene.maps[0].width * (scene.maps[0].imagescale / 100)),
                            height: (scene.maps[0].height * (scene.maps[0].imagescale / 100)),
                        }}>
                            <mask id="svgmask1">
                                {scene.areas?.map(area => {
                                    if (campaignAreas.includes(area.area_id))
                                        return (
                                            area.coordinates?.map(coord => {
                                                return (
                                                    <rect key={coord.coord_id}
                                                          className={"rectangle_mask"}
                                                          x={coord.x - (parseInt(scene.maps[0].origin.split(":")[0])) + 10}
                                                          y={coord.y - (parseInt(scene.maps[0].origin.split(":")[1])) + 5}
                                                          height={coord.height - 10}
                                                          width={coord.width}
                                                    />


                                                )
                                            }))
                                })}
                            </mask>
                            <image
                                xlinkHref={"https://api.sorenkoelbaek.dk/" + scene.maps[0].image_url}
                                xmlnsXlink={"http://www.w3.org/1999/xlink"}
                                width={scene.maps[0].width * (scene.maps[0].imagescale / 100)}
                                height={scene.maps[0].height * (scene.maps[0].imagescale / 100)}

                                style={{filter: "brightness(0%)", zIndex: -50}}
                            >

                            </image>
                            <image
                                xlinkHref={"https://api.sorenkoelbaek.dk/" + scene.maps[0].image_url}
                                xmlnsXlink={"http://www.w3.org/1999/xlink"}
                                width={scene.maps[0].width * (scene.maps[0].imagescale / 100)}
                                height={scene.maps[0].height * (scene.maps[0].imagescale / 100)}
                                mask="url(#svgmask1)"
                            >

                            </image>
                        </svg>


                    </div>
                </div>


            {combat.status === "Active" &&
                <div className="CombatOverlay">
                    <ListGroup className="ParticipantCombat">
                    {participants.sort((a, b) => b.initiative - a.initiative).map(part => {
                        if (!part.hidden)
                            if (part.turn){
                        return (
                                   <ListGroup.Item className={`${part.state + " ParticipantCombat"}`} active
                                   style={{
                                        border: '4px solid '+part.colour
                                    }}
                                   >{part.name}
                                  </ListGroup.Item>
                        )}
                        else {
                          return (
                              <ListGroup.Item className={`${part.state}`}
                              style={{
                            border: '4px solid '+part.colour
                        }}
                              >{part.name}
                                  </ListGroup.Item>
                          )
                            }
                    })}
                    </ListGroup>
                </div>
            }
            </div>
)
        }
        } else if (scene.maps === undefined ||  campaignAreas.length === 0) {
            return (
                <div className="map_container">
                </div>
            )
        }




}

export default PlayMap;


