import {Link} from 'react-router-dom';
import React, {useState} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ModalImage from "react-modal-image";
import region from "./region";
import {FiChevronsDown, FiChevronsUp, FiEdit2} from "react-icons/fi";
import Button from "react-bootstrap/Button";

const Monster = ({Monster, selectSpell, searchSpell, selectMonster, Encounter, handleCastSpell, handleUncastSpell, canClone, addClone}) => {



    const replaceText = (Text) => {
        var textlint = /(?<=\{)(.*?)(?=\})/g
            var matches = textlint.exec(Text)
            if (matches !== null) {
                if (matches.length > 0) {
                var othertext = Text.split(textlint)
                    return (
                othertext?.map(textpieces => {
                    if (textpieces.charAt(0) === '}' || textpieces.slice(-1) === '{') {
                        if (textpieces.charAt(0) === '}')
                            return textpieces.substring(1,textpieces.length).replace("{","")
                        else {
                            return textpieces.substring(0,textpieces.length-1).replace("}","")
                        }
                    } else {
                        //Determine type of replace:
                        if(textpieces.includes("@spell"))
                        {
                            var code = /(?<=spell ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <Link to="#" onClick={() => searchSpell(match[0])}
                                                          >{match[0]}</Link>
                                )
                            }
                        }
                        if(textpieces.includes("@atk"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                if (match[0] == 'mw')
                                return (
                                    <span>(Melee weapon-attack)</span>
                                )
                                if (match[0] == 'rw')
                                return (
                                    <span>(Ranged weapon-attack)</span>
                                )
                                if (match[0] == 'mw,rw')
                                return (
                                    <span>(Melee/Ranged weapon-attack)</span>
                                )
                            }
                        }

                        if(textpieces.includes("@hit"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>+{match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@h"))
                        {
                            var code = /(?<=h).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <u>{match[0]}</u>
                                )
                            }
                        }
                        if(textpieces.includes("@damage"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <i>{match[0]}</i>
                                )
                            }
                        }
                        if(textpieces.includes("@condition"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>{match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@creature")) {
                            var code = /(?<= )(.*?)(?=\|)/g
                            var match = code.exec(textpieces)
                            var bookcode = /(?<=\|)(.*?)(?=\|)/g
                            var bookmatch = bookcode.exec(textpieces)
                            if (match === null) {
                                var code = /(?<= ).*$/g
                                var match = code.exec(textpieces)
                            }
                            if (bookmatch === null) {
                                bookcode = /(?<=\|).*$/g
                                bookmatch = bookcode.exec(textpieces)
                            }
                            if (bookmatch !== null) {
                                if (bookmatch[0].length === 0) {
                                    bookmatch = null;
                                }
                            }
                            if (match !== null && bookmatch !== null) {
                                return (
                                    <Link to="#" onClick={() => selectMonster(match[0], bookmatch[0])}
                                    >{match[0]}</Link>
                                )
                            }
                            if (match !== null && bookmatch ===  null ){

                                return (
                                    <Link to="#" onClick={() => selectMonster(match[0], 'mm')}
                                                          >{match[0]}</Link>
                                )
                            }

                            else {
                                return textpieces
                            }
                        }

                        if(textpieces.includes("@dc"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>dc {match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@dice"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>{match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@sense"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <i><u>{match[0]}</u></i>
                                )
                            }
                        }
                        if(textpieces.includes("@skill"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <u>{match[0]}</u>
                                )
                            }
                        }




                        else {
                            return textpieces
                        }
                    }

                }))
            }
        }else {
                return Text;
            }

    };

    if (Monster.abilities !== undefined)
    {
      return (
          <div>
                <div key={Monster.monster_id} className='BookPage'>
                    <div key='namefield' className='Bestiary Title'> {Monster.name}
                    {canClone !== undefined && canClone==="True" &&
                        <Button onClick={() => addClone(Monster.monster_id)} className="badge badge-pill m-1 addClone" key="addClone">Clone</Button>
                    }
                    </div>
                    <div key='typefield' className='Bestiary SubTitle'>

                        <p className="text-capitalize">

                            {Monster.type}
                            {Monster.alignment?.map(al => {
                                return  ", "+al.alignment
                            })}
                        </p>
                    </div>
                    <div className='statWrapper'>
                        <div className='imageContainer' >
                            <ModalImage className='MonsterImage'
                                        small={'https://api.sorenkoelbaek.dk/static/monsters/' + Monster.book?.abbreviation + "/" + Monster.name + '.jpg'}
                                        large={'https://api.sorenkoelbaek.dk/static/monsters/' + Monster.book?.abbreviation + "/" + Monster.name + '.jpg'}
                            />
                        </div>
                    <div className="leftCol">
                        <div className='dividerTitle'>
                        </div>
                        <div key='AC' className='Bestiary SubList'>
                            <b>Armor Class </b>
                            {Monster.armor_class?.map(ac => {
                                if(ac.type === null && ac.condition === null)
                                {
                                    return  ac.ac
                                }
                                if(ac.type === null && ac.condition !== null)
                                {
                                    return  " ("+ac.ac +" "+ac.condition+"), "
                                }
                                if(ac.type !== null)
                                {
                                    return  " "+ ac.ac +" ("+ac.type+"), "
                                }


                            })}
                        </div>
                        <div key='HP' className='Bestiary SubList'>
                            <b>Hit Points </b> {Monster.hit_points}
                        </div>
                        <div key='Speed' className='Bestiary SubList'>
                            <b>Speed </b>
                            {Monster.speed?.map(speed => {
                                return  speed.value +" ("+speed.name+"), "
                            })}
                        </div>
                        <div className='dividerTitle'>

                        </div>

                        <div key='abilityfield' className='Bestiary row'>
                            {Monster.abilities?.map(ability => {
                                let modifier = '';
                                if (ability.modifier > 0) {
                                    modifier = '+'+ability.modifier;
                                }else {
                                    modifier = ability.modifier;
                                }

                               return <div className='col-2 ability' key ={ability.abbreviation}>
                                   <div className='row abilityTitle justify-content-center'>{ability.abbreviation}</div>
                                   <div className='row abilityValue justify-content-center'>{ability.value} ({modifier})</div> </div>
                            })}

                        </div>
                        <div className='Bestiary divider'></div>
                        <div key='Bestiary propertyfield'>
                            {Monster.properties.map(property => {
                               return <div className='SubList' key ={property.name}>
                                   <b>{property.name} </b>
                                        {property.value.map(propval => {
                                            if (propval.value === null) {
                                                return replaceText(propval.name)+", "
                                            } else {
                                                return replaceText(propval.name+" "+propval.value+", ")
                                            }
                                        })}
                                   </div>
                            })}
                        <div className='Bestiary SubList' key ='Challenge'>
                            <b>Challenge </b>
                            {Monster.challenge_rating.map(cr => {
                                return  cr.value +" ("+cr.experience+" XP), "
                            })}
                        </div>
                        </div>
                    </div>
                        </div>
                    <div className='Bestiary divider'></div>
                    <div key='traitfield' className='Bestiary'>
                         {Monster.traits?.map(trait => {
                           return <div className='traits' key ={trait.name}>
                               <p>
                               <b><i>{trait.name} </i></b> {replaceText(trait.description)}
                                    {trait.description_list?.map(descr => {
                                        return <li><b>{descr.name}</b>{replaceText(descr.description)}</li>
                                    })}
                               </p></div>
                        })}
                    </div>
                    <div className='Bestiary dividerTitle'>actions</div>
                    <div key='actionfield' className='Bestiary'>
                         {Monster.actions?.map(action => {
                             if (action.description_list?.length > 0)
                             {
                                return <div className='actions' key ={action.name}>
                               <b><i>{action.name} </i></b> {replaceText(action.description)}
                                   <ul>
                                    {action.description_list?.map(descr => {
                                        return <li key={descr.name}><b>{descr.name}</b> {replaceText(descr.description)}</li>
                                    })}
                                   </ul>
                               </div>
                             }
                           return <div className='actions' key ={action.name}>
                               <p>
                               <b><i>{action.name} </i></b> {replaceText(action.description)}
                               </p></div>
                        })}
                    </div>
                    {Monster.spells?.length > 0 &&
                        <div className='Bestiary dividerTitle'>spells</div>
                    }

                    {Monster.spellcaster_ability !== null &&
                        <div key='Bestiary spellfield'>
                        <div key='Bestiary spellability'>
                            {Monster.spellcaster_ability?.map(ability => {
                                return <>
                                    <p key={ability.spellcasting}>{replaceText(ability.spellcasting)}</p>
                                </>



                            })}

                        </div>

                        <table className="Bestiary table">
                            <thead className="thead-dark">
                            <tr>
                                <th>Level</th>
                                <th>Slots</th>
                                <th>Spells</th>
                                {Encounter !== undefined &&
                                  <th>Available</th>
                                }

                            </tr>
                            </thead>
                            <tbody>
                            {Monster.spellslots?.map(spellslot => {
                                 return <tr key={spellslot.level}>
                                            <td>{spellslot.level}</td><td>{spellslot.slots}</td><td>
                                            {spellslot.spells.map(slotspell => {
                                                return Monster.spells?.filter(s=>{return s.name.toLowerCase() === slotspell.name}).map(spell => {
                                                    return <Link to="#" onClick={() => selectSpell(spell.spell_id)} key={spell.spell_id} className="badge badge-pill badge-spell m-1">{spell.name}</Link>

                                                })


                                             })}
                                            </td>
                                                 {Encounter !== undefined && spellslot.slots > 0 &&
                                                     <td>
                                                         {Array(spellslot.slots).fill(null).map((value, index) => {
                                                             if (index < Encounter.used_spell_slots?.filter((slot) => slot.level === spellslot.level)[0]?.slots)
                                                                 return(
                                                                      <div
                                                                     onClick={() => handleUncastSpell(spellslot.level)}
                                                                     className="badge badge-pill badge-spell_used"> </div>

                                                         )
                                                             else
                                                                 return(
                                                                     <div
                                                                     onClick={() => handleCastSpell(spellslot.level)}
                                                                     className="badge badge-pill badge-spell_available"> </div>
                                                                 )




                                                         })}
                                                     </td>
                                                 }
                                        </tr>
                            })}


                             </tbody>
                        </table>
                    </div>

                    }


                    {Monster.legendary_actions != null &&
                       <div className='Bestiary dividerTitle'>Legendary actions</div>
                    }

                    <div key='legendaryactionfield' className='Bestiary'>
                        <p>{Monster.legendary_actions?.header}</p>
                        <ul>
                            {Monster.legendary_actions?.description_list?.map(descr => {
                                return <li key={descr.name}><b>{descr.name}</b> {replaceText(descr.description)}</li>
                            })}
                        </ul>
                    </div>


                    {Monster.legendarygroup?.lair_actions != null &&
                       <div className='Bestiary dividerTitle'>Lair actions</div>
                    }

                    <div key='lairactionsfield' className='Bestiary'>
                        {Monster.legendarygroup?.lair_actions?.map(action => {
                            let headerkey = ''
                            if (action.header ==='')
                            {
                                headerkey = action.options[0];
                            } else {
                                headerkey = action.header
                            }
                            return <div key={headerkey}>{action.header}

                            <ul>
                                {action.options?.map(option => {
                                    return <li key={option}>{replaceText(option)}</li>
                                })}
                             </ul>
                            </div>
                        })}

                    </div>
                    {Monster.legendarygroup?.regional_effects != null && Monster.legendarygroup?.regional_effects.length > 0 &&
                       <div className='Bestiary dividerTitle'>Regional effects</div>
                    }

                    <div key='regioneffectsfield' className='Bestiary'>
                        {Monster.legendarygroup?.regional_effects?.map(action => {
                            let headerkey = ''
                            if (action.header ==='')
                            {
                                headerkey = action.options[0];
                            } else {
                                headerkey = action.header
                            }
                            return <div key={headerkey}>{replaceText(action.header)}
                            <ul>
                                {action.options?.map(option => {
                                    return <li key={option}>{replaceText(option)}</li>
                                })}
                             </ul>
                            </div>
                        })}

                    </div>



                </div>


        </div>
    )
    }

}
export default Monster;