import React, { useEffect, useState } from 'react';
import {generatePath, useNavigate} from "react-router-dom";
import FastAPIClient from '../../client';
import config from '../../config';
import 'JSON';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import BookList from "../../components/booklist";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import Menu from "../../components/menu";


const client = new FastAPIClient(config);

const Home = () => {

    const [books, setBooks] = useState([])
    const [loading, setLoading] = useState(true)
    const [bookId, setBookId] = useState()
    const history = useNavigate();
    const navigate = useNavigate();
    const [campaignInputs, setCampaignInputs] = useState({})
    const [addCampaignModalShow, setAddCampaignModalShow] = useState(false)

    useEffect(() => {
        fetchBooks()
        initializeInputs()
    }, [])

     const initializeInputs = () => {
        let object = {}
        object.name = ""
        object.abbreviation = ""
        object.IsHomeBrew = true
        object.published = new Date().toISOString().slice(0, 10)
        object.type = "supplement"
        setCampaignInputs(object)
    }

    const handleAddCampaignModalClose = () => {
        setAddCampaignModalShow(false)

    }
    const fetchBooks = () => {
        setLoading(true)
        client.getBooks().then((data) => {
            setLoading(false)
            setBooks(data.data)
          });
    }

    const AddNewBook = () => {
        setAddCampaignModalShow(true)

    }

    const handleAddCampaignSubmit = () => {
        setAddCampaignModalShow(false)
        console.log(campaignInputs)
        client.postBook(campaignInputs)
        fetchBooks()
    }

    const handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setCampaignInputs(values => ({...values, [name]: value}))
    }
    const handleProceed = (bookId) => {
        setBookId(bookId)
        navigate(generatePath("/book/:bookId", { bookId }));
    };

    return (

           <>
               <Menu/>
            <div className="row">
                <div className="col-3">
                    <BookList
                        books={books}
                        selectBook={handleProceed}
                        AddNewBook={AddNewBook}
                    />

                </div>

                <div className="col-9">
                </div>
            </div>
           <Modal show={addCampaignModalShow} onHide={handleAddCampaignModalClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Add Campaign</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                          <div>
                            <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Name</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="name"
                                  value={campaignInputs.name || ""}
                                  onChange={handleInputChange}
                                />
                          </InputGroup>
                          <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Abbreviation</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="abbreviation"
                                  value={campaignInputs.abbreviation || ""}
                                  onChange={handleInputChange}
                                />
                          </InputGroup>


                        </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                        <div>
                           <Button variant="primary" onClick={handleAddCampaignSubmit}>
                               Save Changes
                            </Button>
                        </div>


                </Modal.Footer>
              </Modal>



       </>


    )

}

export default Home;


