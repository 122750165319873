import {Link} from 'react-router-dom';
import React, {useEffect, useState} from "react";

const SceneList = ({Scenes, selectScene, AddScene, editScene}) => {


      return(
      <div>
      <div className="list-group small">
          {
            Scenes?.map(scene => {

                      return    (
                          <div className="list-group-item list-group-item-action">
                              <div onClick={() => selectScene(scene.scene_id)} key={scene.scene_id} className="list-group-item-action firstbtn" >{scene.name} </div>
                                <div onClick={() => editScene(scene.scene_id)} className="badge badge-pill badge-primary secondbtn" >Edit
                                </div>
                            </div>

                      )
            })
          }
        <Link to="#" onClick={() => AddScene()} key={"newArea"} className="list-group-item list-group-item-info">
            Add Scene
        </Link>


  </div>
  </div>
)



}
export default SceneList;