import React, { useEffect, useState, useContext } from 'react';
import FastAPIClient from '../../client';
import config from '../../config';
import Spell from '../../components/spell'
import '../../my.css';
import 'JSON';
import 'bootstrap/dist/css/bootstrap.min.css';
import {generatePath, Navigate, useNavigate} from "react-router-dom";
import SpellList from "../../components/spellList";
import Menu from "../../components/menu";


const client = new FastAPIClient(config);



const Spells = () => {

    const [loading, setLoading] = useState(true)
    const [spells, setSpells] = useState([])
    const [spell, setSpell] = useState([])
    const [books, setBooks] = useState([])
    const [bookId, setBookId] = useState()
    const navigate = useNavigate();



    const selectSpell = (spelldata) => {
    fetchSpell(spelldata);
  }


    useEffect(() => {
        fetchSpells()
        fetchBooks()
    }, [])



    const fetchBooks = () => {
        client.getBooks().then((data) => {
            setBooks(data.data)
          });
    }



    const fetchSpells = () => {
        setLoading(true)
        client.getSpells().then((data) => {
            setLoading(false)
            setSpells(data.data)
          });
    }

    const fetchSpell = (spelldata) => {
        client.getSpell(spelldata).then((data)=>{
            setSpell(data.data)
        })
    }



    if (loading)
        return ("Loading...")

    return (
        <>
        <Menu/>

            <div className="row">
                <div className="col-3">

                    <SpellList
                        Spells={spells}
                        selectSpell={selectSpell}
                    />
                </div>

                <div className="col-9">
                 <Spell
                        spell={spell}

                    />
                </div>


            </div>

</>




    )

}

export default Spells;


