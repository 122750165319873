import Select from "react-select";
import React, {useEffect, useState} from "react";


const PartySelect = ({parties,onChangeParty}) => {

    const [partyOptions, setPartyOptions] = useState([])

    const initializePartyOptions = () => {
        let objectlist = []
        let newObj = {}
        parties?.map(party => {
            let object = {}
            object.label = party.name
            object.value = party.party_id
            objectlist.push(object)
        })

        setPartyOptions(objectlist)
    }

    useEffect(() => {
        initializePartyOptions()
    }, [parties])


    return (
            <Select className="form-select"
                options = {partyOptions}
                name='party_id'
                onChange={onChangeParty}
            />
        )


}

export default PartySelect