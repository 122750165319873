import {Link} from 'react-router-dom';
import React, {useEffect, useState} from "react";


const RuleList = ({Rules,selectRule}) => {

      return(
      <div>
      <div className="list-group">
          {
            Rules?.map(rule => {
                      return    (
                          <div>
                              <Link to="#" onClick={() => selectRule(rule.rule_id)} key={rule.rule_id} className="list-group-item list-group-item-action">{rule.name}
                              </Link>
                          </div>
                      )







            })
          }

  </div>
  </div>
)

}
export default RuleList;