import React, { useEffect, useState, useContext } from 'react';
import FastAPIClient from '../../client';
import config from '../../config';
import MonsterList from '../../components/monsterList'
import Monster from '../../components/monster'
import Spell from '../../components/spell'
import '../../my.css';
import 'JSON';
import 'bootstrap/dist/css/bootstrap.min.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown from "react-bootstrap/NavDropdown";
import {generatePath, useNavigate} from "react-router-dom";
import Menu from "../../components/menu";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import InputGroup from 'react-bootstrap/InputGroup';


const client = new FastAPIClient(config);

const Monsters = () => {

    const [loading, setLoading] = useState(true)
    const [monsters, setMonsters] = useState([])
    const [monster, setMonster] = useState([])
    const [spell, setSpell] = useState([])
    const [books, setBooks] = useState([])
    const [bookId, setBookId] = useState()
    const history = useNavigate();
    const navigate = useNavigate();
    const [spellshow, setSpellShow] = useState(false);
    const handleClose = () => setSpellShow(false);
    const handleShow = () => setSpellShow(true);



    const selectMonster = (childdata) => {
    fetchMonster(childdata);
  }

    const selectSpell = (spelldata) => {
    fetchSpell(spelldata);
    handleShow();
  }



    useEffect(() => {
        fetchMonsters()
        fetchBooks()
    }, [])


    const fetchBooks = () => {
          // GET THE MONSTERS FROM THE API
        client.getBooks().then((data) => {
            setBooks(data.data)
          });
    }



    const handleProceed = (bookId) => {
        setBookId(bookId)
        navigate(generatePath("/book/:bookId", { bookId }));
    };

      const selectMonsterSearch = (MonsterName, book) => {
        searchMonster(MonsterName, book);
    }

    const fetchMonsters = () => {
        setLoading(true)
          // GET THE MONSTERS FROM THE API
        client.getMonsters().then((data) => {
            setLoading(false)
            // SET THE RECIPIES DATA
            setMonsters(data.data)
          });
    }

    const selectSearchSpell = (spelldata) => {
    searchSpell(spelldata);
    handleShow();
    }

      const searchSpell = (spelldata) => {
        client.searchSpell(spelldata).then((data)=>{
            setSpell(data.data)
        })
        }

    const fetchSpell = (spelldata) => {
        client.getSpell(spelldata).then((data)=>{
            setSpell(data.data)
        })
    }

    const handleAddClone = (monster_id) => {
    let obj = {}
    obj.monster_id = monster_id
    obj.monster_name = ""
        setCloneShow(true)
        setCloneInput(obj)
    }

    const addClone = () => {
        client.postMonsterClone(cloneInput).then((data) => {
            console.log(data.data)
            setCloneShow(false)
        })

    }


    const searchMonster = (childdata, book) => {
        client.searchMonster(childdata, book).then((data) => {
            setMonster(data.data)
          });
    }

    const fetchMonster = (childdata) => {
        client.getMonster(childdata).then((data) => {
            setMonster(data.data)
          });
    }

    const [CloneShow, setCloneShow] = useState(false)
    const [cloneInput, setCloneInput] = useState({})

    const handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setCloneInput(values => ({...values, [name]: value}))
    }

    const handleCloneClose = () => {
        setCloneShow(false)
    }

    if (loading)
        return ("Loading...")

    return (
        <>
        <Menu/>

            <div className="row">
                <div className="col-3">

                    <MonsterList
                        Monsters={monsters}
                        selectMonster={selectMonster}
                    />
                </div>
                <div className="col-9">
                 <Monster
                        Monster={monster}
                        selectSpell={selectSpell}
                        searchSpell ={selectSearchSpell}
                        selectMonster={selectMonsterSearch}
                        addClone={handleAddClone}

                    />
                </div>

              <Offcanvas show={spellshow} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Spell
                      spell = {spell}
                    />
                </Offcanvas.Body>
              </Offcanvas>
              <Modal show={CloneShow} onHide={handleCloneClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Add Clone</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                          <div>
                            <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Name</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="monster_name"
                                  value={cloneInput.monster_name || ""}
                                  onChange={handleInputChange}
                                />

                          </InputGroup>

                        </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                        <div>
                           <Button variant="primary" onClick={addClone}>
                               Save Changes
                            </Button>
                        </div>


                </Modal.Footer>
              </Modal>

            </div>

</>




    )

}

export default Monsters;


