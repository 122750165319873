import {Link} from 'react-router-dom';
import React, {useState} from "react";


const SpellList = ({Spells,selectSpell, Limit}) => {

  const [filter, setFilter] = useState("")
  const [filtered, setFiltered] = useState(Spells)


  const doSearch = (filter) => {
        setFilter(filter)
        if(typeof filter === 'string' && filter.trim().length === 0) {
            if (Limit !== undefined && Limit > 0) {
                setFiltered(Spells.splice(0,0))
            }
            if (Limit === 0) {
                setFiltered({})
            }
            else {
                setFiltered(Spells)
            }
        } else {
            let spellarray = Object.values(Spells);
            if (Limit !== undefined && Limit > 0) {
                setFiltered(spellarray.filter(m => {
                    return m.name.toLowerCase().includes(filter)
                    }
            ).splice(0,Limit))}
            else {
                setFiltered(spellarray.filter(m => {
                    return m.name.toLowerCase().includes(filter)
                    }
            ))
            }
       }
    }

  return(
      <div>
      <div className="input-group mb-3">
                        <input className="form-control"
                         type="text"
                         onChange={(e) => doSearch(e.target.value.toLowerCase())}
                         placeholder="Search spells..." />
                    </div>
      <div className="list-group search_result">
          { filtered.length > 0 &&
            filtered.map(spell => (

                <Link to="#" onClick={() => selectSpell(spell.spell_id)} key={spell.spell_id} className="list-group-item list-group-item-action">{spell.name}
                  <span className="badge badge-pill badge-primary m-1" >{spell.level}</span>
                  <span className="badge badge-pill badge-secondary m-1" >{spell.school}</span>
                    <span className="badge badge-pill badge-secondary m-1" >{spell.sourceabbreviation}</span>
                </Link>

        ))
          }

  </div>
  </div>
)

}
export default SpellList;