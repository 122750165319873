import {Link} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import { useAuth } from "../Auth.js";

const AreaListMap = ({Areas,selectArea, AddNewArea, selectedAreaId, scene}) => {
    const { user, setUser } = useAuth();

    const [arealist, setAreaList] = useState([])

    function ItemStyle(areaid) {
      if(areaid === selectedAreaId)
      {
        return "list-group-item-warning"
      }
      else {
        if (scene.areas?.some(elem => elem.area_id === areaid))
            {
                return "list-group-item-action"
            }
            else {
                return "list-group-item-light"
            }

      }
    }

      return(
      <div>
      <div className="list-group">
          {
            Areas?.sort((a,b)=>a.area_id - b.area_id).map(area => {
                if (area.child_areas.length === 0) {
                    if (area.parent_id === null) {

                         return (
                            <div>
                                <Link to="#" onClick={() => selectArea(area.area_id)} key={area.area_id}
                                      className={"list-group-item "+ItemStyle(area.area_id)}>{area.name}

                                </Link>
                            </div>
                        )


                    }


                }
                else {
                    return (
                        <div>
                            <Link to="#" onClick={() => selectArea(area.area_id)} key={area.area_id}
                                  className="list-group-item list-group-item-action">{area.name}</Link>
                            {area.child_areas.sort((a, b) => a.area_id - b.area_id).map(subarea => {

                                return <Link to="#" onClick={() => selectArea(subarea.area_id)}
                                             key={subarea.area_id}
                                             className={"list-group-item list-group-item-sub "+ ItemStyle(subarea.area_id)}>{subarea.name}</Link>


                            })}
                        </div>
                    )
                }




            })
          }
  </div>
  </div>
)



}
export default AreaListMap;