import {Link} from 'react-router-dom';
import React, {useState} from "react";
import {FiEdit2} from "react-icons/fi";
import Collapsible from "./collapsible";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ModalImage from "react-modal-image";

const Rule = ({Rule,selectSpell, selectMonster}) => {

const replaceText = (Text) => {
        var textlint = /(?<=\{)(.*?)(?=\})/g
            var matches = textlint.exec(Text)
            if (matches !== null) {
                if (matches.length > 0) {
                var othertext = Text.split(textlint)
                    return (
                othertext?.map(textpieces => {
                    if (textpieces.charAt(0) === '}' || textpieces.slice(-1) === '{') {
                        if (textpieces.charAt(0) === '}')
                            return textpieces.substring(1,textpieces.length).replace("{","")
                        else {
                            return textpieces.substring(0,textpieces.length-1).replace("}","")
                        }
                    } else {
                        //Determine type of replace:
                        if(textpieces.includes("@skill"))
                        {
                            var code = /(?<=skill ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>{match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@item"))
                        {
                            var code = /(?<= )(.*?)(?=\|)/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>{match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@dice"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>{match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@spell"))
                        {
                            var code = /(?<=spell ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <Link to="#" onClick={() => selectSpell(match[0])}
                                                          >{match[0]}</Link>
                                )
                            }
                        }
                        if(textpieces.includes("@LocalImg"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            var backend = 'https://api.sorenkoelbaek.dk/static/'
                            if (match !== null) {
                                return (
                                   <div className='image_container'>
                                        <ModalImage className='image'
                                          small={backend+match[0]}
                                          large={backend+match[0]}
                                        />
                                    </div>
                                )
                            }
                        }
                        if(textpieces.includes("@creature"))
                        {
                            var code = /(?<= )(.*?)(?=\|)/g
                            var match = code.exec(textpieces)
                            var bookcode = /(?<=\|)(.*?)(?=\|)/g
                            var bookmatch = bookcode.exec(textpieces)
                            if (match === null)
                            {
                               var code = /(?<= ).*$/g
                                var match = code.exec(textpieces)
                            }
                            if (bookmatch === null) {
                                bookcode = /(?<=\|).*$/g
                                bookmatch = bookcode.exec(textpieces)
                            }
                            if (bookmatch !== null) {
                                if (bookmatch[0].length === 0) {
                                bookmatch = null;
                                }
                            }

                            if (match !== null && bookmatch !== null) {
                                return (
                                    <Link to="#" onClick={() => selectMonster(match[0], bookmatch[0])}
                                    >{match[0]}</Link>
                                )
                            }
                            if (match !== null && bookmatch ===  null ){
                                return (
                                    <Link to="#" onClick={() => selectMonster(match[0], 'mm')}
                                                          >{match[0]}</Link>
                                )
                            }

                            else {
                                return textpieces
                            }
                        }

                        else {
                            return textpieces
                        }
                    }

                }))
            }
        }else {
                return Text;
            }

    };


    if (Rule.sections !== undefined)
    {
      return (
            <div className='BookPage'>
                <div key={Rule.rule_id} className='Area'>
                    <div key='namefield' className='Area Title'>
                        {Rule.name}
                    </div>
                    <div className='divider'></div>

                    {Rule.sections.map(description => {
                        {description.description_list.sort((a, b) => a.description_id - b.description_id)}
                        return (
                            <Collapsible
                                Title={description.name}
                                >
                                <div className='Descriptor_name'>
                                <div className='Descriptor_description'>
                                    {description.description}
                                </div>

                                    {description.description_list.map(subde => {
                                        if (subde.name === 'table') {
                                            var tableObject = JSON.parse(subde.description);
                                            return(
                                                <div className={`description_list`}>
                                                <div className='Description_description'>
                                                    <table><tr>
                                                    {tableObject.Header.map(header => {
                                                        return(
                                                            <th>{header}</th>
                                                        )
                                                    })}
                                                        </tr>
                                                        {tableObject.rows.map(row => {
                                                           return(
                                                               <tr>
                                                                {row.map(cell => {
                                                                    return(
                                                                        <td>{replaceText(cell)}</td>
                                                                    )
                                                                })}
                                                               </tr>
                                                            )
                                                    })}


                                                        </table>
                                                     </div>
                                                    </div>
                                            )
                                        }
                                        if (subde.name === 'List') {
                                            var tableObject = JSON.parse(subde.description);
                                            return(<div className={`description_list`}>
                                                    <div className='Description_description'>
                                                    <ul>
                                                        {tableObject.items.map(row => {
                                                           return(
                                                               <li>{replaceText(row)}</li>
                                                            )
                                                    })}


                                                        </ul>
                                                     </div>

                                                 </div>
                                            )
                                        }
                                         if (subde.name === 'insetReadaloud') {
                                            return (<div className={`description_list ${subde.name}`}>

                                                    <div className='Description_description'>
                                                        <div className="description_eng">
                                                         {replaceText(subde.description)}
                                                        </div>
                                                         <div className="description_danish">
                                                         {replaceText(subde.description_translated)}
                                                        </div>
                                                     </div>
                                                   </div>
                                                )
                                        }
                                        else {
                                            return (<div className={`description_list ${subde.name}`}>

                                                    <div className='Description_description'>
                                                         {replaceText(subde.description)}
                                                     </div>
                                                    </div>
                                                )
                                            }

                                    })}


                                </div>
                            </Collapsible>
                            )

                    })}

                </div>

        </div>
      )
    }


}
export default Rule;