import {Link} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import { useAuth } from "../Auth.js";


const BookList = ({books, selectBook, AddNewBook}) => {
    const { user, setUser } = useAuth();

    return(
      <div>
      <div className="list-group">
          {
            books?.map(book => (
                <Link to= {`/book/${book.book_id}`} key={book.book_id} className="list-group-item list-group-item-action">{book.name}
                </Link>
            ))}
          {user.type === "admin" &&
              <Link to="#" onClick={() => AddNewBook()} key={"newBook"}
                    className="list-group-item list-group-item-info"> + Campaign
              </Link>
          }


      </div>
      </div>
)



}
export default BookList;