import {generatePath, Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState, useRef} from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import FastAPIClient from "../../client";
import config from "../../config";
import Arealist from "../../components/arealist";
import Region from "../../components/region";
import Area from "../../components/area";
import Offcanvas from "react-bootstrap/Offcanvas";
import Areaform from "../../components/areaform";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal"
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Form from "react-bootstrap/Form";
import Spell from "../../components/spell";
import Menu from "../../components/menu"
import Monster from "../../components/monster"
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import MonsterList from "../../components/monsterList";
import Collapse from "react-bootstrap/Collapse";
import Encounter from "../../components/encounter";
import Regionlist from "../../components/regionlist";
import Select from 'react-select'
import InputGroup from "react-bootstrap/InputGroup";

const client = new FastAPIClient(config);



const Book = () => {
    const {bookId} = useParams();
    const [loading, setLoading] = useState(true)
    const [region, setRegion] = useState(true)
    const [regions, setRegions] = useState([])
    const [book, setBook] = useState([])
    const [areas, setAreas] = useState([])
    const [flatareas, setFlatAreas] = useState([])
    const [events, setEvents] = useState([])
    const [encounters, setEncounters] = useState([])
    const [area, setArea] = useState([])
    const [areaid, setAreaId] = useState(0)
    const [combatId, setCombatId] = useState(0)
    const [startinput, setstartInput] = useState({});
    const [combatInputs, setCombatInputs] = useState({});
    const [inputs, setInputs] = useState(startinput);
    const [encounterInputs, setEncounterInputs] = useState({});
    const [eventInputs, setEventInputs] = useState({});
    const [descriptionInputs, setdescriptionInputs] = useState({});
    const [conditionOptions, setConditionOptions] = useState({})
    const [conditions, setConditions] = useState({})
    const [show, setShow] = useState(false);
    const [monster, setMonster] = useState([])
    const [spell, setSpell] = useState([])
    const [eventFormName, setEventFormName] = useState('')
    const [encounter, setEncounter] = useState([])
    const [encountergroup, setEncountergroup] = useState([])
    const [combats, setCombats] = useState(undefined)
    const handleClose = () => setShow(false);
    const [spellShow, setSpellShow] = useState(false);
    const handleSpellClose = () => setSpellShow(false);
    const handleSpellShow = () => setSpellShow(true);

    const [monsterShow, setMonsterShow] = useState(false);
    const handleMonsterClose = () => setMonsterShow(false);
    const handleMonsterShow = () => setMonsterShow(true);

    const [encounterSideShow, setEncounterSideShow] = useState(false);
    const handleEncounterSideClose = () => setEncounterSideShow(false);
    const handleEncounterSideShow = () => setEncounterSideShow(true);
    const [parties, setParties] = useState([])
    const [campaign, setCampaign] = useState([])


    const [addRegionModalShow, setaddRegionModalShow] = useState(false)
    const [regionInputs, setRegionInputs] = useState({})

    const [combatEnable, setCombatEnable] = useState(false)
    const navigate = useNavigate();

    const ChooseCampaign = (campaign) => {
        client.wsTalk("Campaign:"+campaign.campaign_id)
        setCampaign(campaign)
    }

     useEffect(() => {
        fetchRegions(bookId)
         fetchBook(bookId)

    }, [bookId])



    const fetchRegions = (bookId) => {
        setLoading(true)
        client.getRegions(bookId).then((data) => {
            setLoading(false)
            setRegions(data.data)
            initializeRegionInputs()
          });

    }

    const selectSpell = (SpellName) => {
    fetchSpell(SpellName);
    handleSpellShow();
  }

  const selectMonster = (MonsterName, book) => {
    fetchMonster(MonsterName, book);
    handleMonsterShow();
  }
   const clickMonster = (monsterId) => {
    fetchMonsterbyId(monsterId);
    handleMonsterShow();
  }
   const clickEncounter = (EncounterId) => {
    fetchEncounterGroup(EncounterId);

  }

  const fetchSpell = (spelldata) => {
        client.searchSpell(spelldata).then((data)=>{
            setSpell(data.data)
        })
    }
    const fetchEncounterGroup = (encountergroupid) => {
        if (encountergroupid !== undefined) {
            client.getEncountergroup(encountergroupid).then((data)=>{
                setEncountergroup(data.data)
                handleEncounterSideShow()
            })
        }

    }
        const fetchEncounter = (encounterid) => {
        if (encounterid !== undefined) {
            client.getEncounter(encounterid).then((data)=>{
                setEncounter(data.data)
                handleEncounterSideShow()
            })
        }

    }

    const fetchMonster = (childdata, book) => {
        client.searchMonster(childdata, book).then((data) => {
            if(data.status == 200) {
               setMonster(data.data)
            }
            else {
               setMonster("")
            }

          });
    }

    const postCombat = () => {
        client.postcombat(combatInputs).then((data) => {
            if(data.status == 200) {
               setCombatId(data.data.combat_id)
            }
            else {
               console.log(data.status,": ", data.data.detail)
            }

          });
    }

    const fetchMonsterbyId = (childdata) => {
        client.getMonster(childdata).then((data) => {
            setMonster(data.data)
          });
    }



    const fetchRegion = (regionId) => {
        let regionUrl = generatePath("/region/"+regionId)
        navigate(regionUrl)
    }


    const fetchBook = (bookId) => {
        client.getBook(bookId).then((data) => {
            setBook(data.data)
          });
    }



  const AddNewRegion = () => {
        setaddRegionModalShow(true)
  }




    const handleAddRegionModelClose = () => {
        setaddRegionModalShow(false)
    }

    const initializeRegionInputs = () => {
        let object = {}
        object.name = ""
        object.chapter = ""
        object.IsHomeBrew = true
        object.book_id = bookId

        setRegionInputs(object)
    }

    const handleRegionInputChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setRegionInputs(values => ({...values, [name]: value}))
    }

    const handleRegionModelSubmit = () => {
        initializeRegionInputs()
        client.postRegion(regionInputs).then((data) => {
            setaddRegionModalShow(false)
            fetchRegions(book.book_id)

        })

    }


    const selectArea = (childdata) => {

        if (childdata === 0) {
            setAreaId(undefined)
        }
        else {
             let regionUrl = generatePath("/region/"+childdata)
            navigate(regionUrl)
        }
  }





    return (
        <div>
            <Menu
                book = {book}
                campaign = {campaign}
                setCampaign = {ChooseCampaign}
            />


     <div className="row">
                <div className="col-3">
                    <Regionlist
                        Regions={regions}
                        selectArea={selectArea}
                        fetchRegion={fetchRegion}
                        AddNewRegion={AddNewRegion}
                    />

                </div>

                <div className="col-9">


                </div>


            </div>





            <Modal show={addRegionModalShow} onHide={handleAddRegionModelClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Add Region</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                          <div>
                            <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Name</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="name"
                                  value={regionInputs.name || ""}
                                  onChange={handleRegionInputChange}
                                />
                          </InputGroup>
                          <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">chapter</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="chapter"
                                  value={regionInputs.chapter || ""}
                                  onChange={handleRegionInputChange}
                                />
                          </InputGroup>


                        </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                        <div>
                           <Button variant="primary" onClick={handleRegionModelSubmit}>
                               Save Changes
                            </Button>
                        </div>


                </Modal.Footer>
              </Modal>





                <Offcanvas show={spellShow} onHide={handleSpellClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Spell
                      spell = {spell}
                    />
                </Offcanvas.Body>
              </Offcanvas>
            <Offcanvas show={monsterShow} onHide={handleMonsterClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Monster
                      Monster={monster}
                    />
                </Offcanvas.Body>
              </Offcanvas>
  </div>
        )
}

export default Book;


