import {Link} from 'react-router-dom';
import React, {useState} from "react";


const MonsterList = ({Monsters, selectMonster, Limit}) => {

  const [filter, setFilter] = useState("")
  const [filtered, setFiltered] = useState(Monsters)


  const doSearch = (filter) => {
        setFilter(filter)
        if(typeof filter === 'string' && filter.trim().length === 0) {
            if (Limit !== undefined && Limit > 0)
            {
                setFiltered(Monsters.slice(0, 0))
            }
            if(Limit === 0) {
                setFiltered({})
            }
            else {
               setFiltered(Monsters)
            }

        } else {
            let monsterarray = Object.values(Monsters);
            if (Limit != undefined && Limit > 0) {
                setFiltered(monsterarray.filter(m => {
                        return m.name.toLowerCase().includes(filter)
                    }
                ).slice(0, Limit))
            }else {
                setFiltered(monsterarray.filter(m => {
                        return m.name.toLowerCase().includes(filter)
                    }
                ))
            }
       }
    }


  return(
      <div>
      <div className="input-group mb-3">
                        <input className="form-control"
                         type="text"
                         onChange={(e) => doSearch(e.target.value.toLowerCase())}
                         placeholder="Search monsters..." />
                    </div>
      <div className="list-group search_result">

          { filtered.length > 0 &&
            filtered.map(monster => (

                <Link to="#" onClick={() => selectMonster(monster.monster_id)} key={monster.monster_id} className="list-group-item list-group-item-action">{monster.name}
                  <span className="badge badge-pill badge-primary m-1" >{monster.challenge_rating[0].value}</span>
                  <span className="badge badge-pill badge-secondary m-1" >{monster.book.abbreviation}</span>
                </Link>

        ))
          }

  </div>
  </div>
)

}
export default MonsterList;