import {generatePath, Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState, useRef} from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import FastAPIClient from "../../client";
import config from "../../config";
import Arealist from "../../components/arealist";
import Region from "../../components/region";
import Area from "../../components/area";
import Offcanvas from "react-bootstrap/Offcanvas";
import Areaform from "../../components/areaform";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal"
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Form from "react-bootstrap/Form";
import Spell from "../../components/spell";
import Menu from "../../components/menu"
import Monster from "../../components/monster"
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import MonsterList from "../../components/monsterList";
import Collapse from "react-bootstrap/Collapse";
import Encounter from "../../components/encounter";
import Regionlist from "../../components/regionlist";
import Select from 'react-select'
import InputGroup from "react-bootstrap/InputGroup";

const client = new FastAPIClient(config);

const RegionPage = () => {
    const {area_Id} = useParams();
    const {regionId} = useParams();
    const [loading, setLoading] = useState(true)
    const [region, setRegion] = useState(true)
    const [regions, setRegions] = useState([])
    const [book, setBook] = useState([])
    const [areas, setAreas] = useState([])
    const [flatareas, setFlatAreas] = useState([])
    const [events, setEvents] = useState([])
    const [encounters, setEncounters] = useState([])
    const [area, setArea] = useState([])
    const [areaid, setAreaId] = useState(0)
    const [combatId, setCombatId] = useState(0)
    const [startinput, setstartInput] = useState({});
    const [combatInputs, setCombatInputs] = useState({});
    const [inputs, setInputs] = useState(startinput);
    const [encounterInputs, setEncounterInputs] = useState({});
    const [eventInputs, setEventInputs] = useState({});
    const [descriptionInputs, setdescriptionInputs] = useState({});
    const [conditionOptions, setConditionOptions] = useState({})
    const [conditions, setConditions] = useState({})
    const [show, setShow] = useState(false);
    const [monster, setMonster] = useState([])
    const [spell, setSpell] = useState([])
    const [eventFormName, setEventFormName] = useState('')
    const [encounter, setEncounter] = useState([])
    const [encountergroup, setEncountergroup] = useState([])
    const [combats, setCombats] = useState(undefined)
    const [regionList, setRegionList] = useState([])
    const handleClose = () => setShow(false);


    const [spellShow, setSpellShow] = useState(false);
    const handleSpellClose = () => setSpellShow(false);
    const handleSpellShow = () => setSpellShow(true);

    const [monsterShow, setMonsterShow] = useState(false);
    const handleMonsterClose = () => setMonsterShow(false);
    const handleMonsterShow = () => setMonsterShow(true);

    const [encounterSideShow, setEncounterSideShow] = useState(false);
    const handleEncounterSideClose = () => setEncounterSideShow(false);
    const handleEncounterSideShow = () => setEncounterSideShow(true);
    const [parties, setParties] = useState([])
    const [campaign, setCampaign] = useState([])


    const [addRegionModalShow, setaddRegionModalShow] = useState(false)
    const [regionInputs, setRegionInputs] = useState({})

    const [combatEnable, setCombatEnable] = useState(false)
    const navigate = useNavigate();

    const ChooseCampaign = (campaign) => {
        client.wsTalk("Campaign:"+campaign.campaign_id)
        setCampaign(campaign)
    }

    const handleSubmit = () => {
        client.putArea(area.area_id, inputs).then((data) => {
            setArea(data.data)
            fetchRegion(region.region_id)
            selectArea(area.area_id)
        });
        setShow(false);
    }
    const handleEncounterSubmit = () => {
        client.postEncounter(encounterMonster.monster_id, encounterInputs).then((data) => {
            selectArea(encounterInputs.areaIds[0])

        });
        setEncounterShow(false);
    }


    const handleShow = () => {
        initializeAreaOptions()
        setShow(true);
    }

    const handleEventSubmit = () => {
        console.log(eventInputs)

        if(eventInputs.event_id === 0)
        {
            client.postEvent(eventInputs).then((data) => {
            selectArea(area.area_id)
            });
        } else {
            client.putEvent(eventInputs).then((data) => {
            selectArea(area.area_id)
            });
        }


        setEventShow(false);

    }

    const handleDescriptionSubmit = () => {
        if(descriptionInputs.description_id === undefined)
        {

        } else {
            client.putDescription(descriptionInputs).then((data) => {
            selectArea(area.area_id)
            handleDescriptionClose()
            });
        }


        setEventShow(false);

    }
    const handleDescriptionDelete = () => {
        if(descriptionInputs.description_id === undefined)
        {

        } else {
            client.putDeleteDescription(descriptionInputs.description_id).then((data) => {
            selectArea(area.area_id)
            handleDescriptionClose()
            });
        }


        setEventShow(false);

    }





    const handleEventNext = () => {
        setEventFormSubmitBtn(true);
        setEventFormNextBtn(false);
    }
      const handleEventBack = () => {
        setEventFormSubmitBtn(false);
        setEventFormNextBtn(true);
    }

    const [eventShow, setEventShow] = useState(false);
    const handleEventClose = () => setEventShow(false);
    const handleEventUpdateShow = (Event) => {
        eventInputs.event_id = Event.event_id
        eventInputs.name = Event.name
        eventInputs.areaIds = Event.areas?.map(area => area.area_id);
        eventInputs.encounterIds = Event.encounters?.map(encounter => encounter.encounter_id);
        eventInputs.description = Event.description;
        eventInputs.condition = Event.condition;
        setAreaInputs(areas.filter(el => el.area_id === area.area_id))
        setEventShow(true)
        setEventFormSubmitBtn(false)
        setEventFormNextBtn(true)
    };
    const handleEventShow = () => {
        eventInputs.event_id = 0
        eventInputs.name = ''
        eventInputs.areaIds = []
        eventInputs.encounterIds = []
        eventInputs.description = ''
        eventInputs.condition = ''
        eventInputs.origin = ""
        setAreaInputs(areas.filter(el => el.area_id === area.area_id))
        setEventShow(true)
        setEventFormSubmitBtn(false)
        setEventFormNextBtn(true)
    };
    const  [areainputs, setAreaInputs] = useState(area);
    const  [localAreaList, setLocalAreaList] = useState(areas);

    const  [eventEncounterInputs, setEventEncounterInputs] = useState(encounters);
    const  [eventEncounterList, seteventEncounterList] = useState(encounters);

    const [encounterShow, setEncounterShow] = useState(false);
    const handleEncounterClose = () => setEncounterShow(false);

    const [descriptionShow, setDescriptionShow] = useState(false);
    const handleDescriptionClose = () => setDescriptionShow(false);
    const handleDescriptionShow = (Description) => {
        descriptionInputs.description_id = Description.description_id
        descriptionInputs.name = Description.name
        descriptionInputs.description = Description.description
        descriptionInputs.description_translated = Description.description_translated
        setDescriptionShow(true)
    };

    const handleEncounterShow = () => {
        fetchMonsters()
        encounterInputs.areaIds = [];
        encounterInputs.areaIds.push(area.area_id)
        encounterInputs.eventIds = [];
        encounterInputs.region_id = region.region_id
        encounterInputs.damage = 0
        encounterInputs.condition = ''
        encounterInputs.quantity = 1
        encounterInputs.isplotencounter=true
        setEncounterMonster(undefined)
        setAreaInputs(areas.filter(el => el.area_id === area.area_id))
        setLocalAreaList(areas.filter(el => areainputs?.some(f => f.area_id !== el.area_id)))
        setFormMonstersOpen(true)
        setEncounterShow(true)
    };

    const [areaOptions, setAreaOptions] = useState([])
    const [regionOptions, setRegionOptions] = useState([])

    useEffect(() => {
        initializseRegionOptions()

        }, [regionList]
    )

    const initializseRegionOptions = () => {
        const options = regionList.map((region) => ({name:region.name, region_id:region.region_id, areas:region.areas.filter(area => (area.parent_id === null)).map((area) => ({area_id: area.area_id, name: area.name, child_areas:area.child_areas.map((child_area) => ({area_id: child_area.area_id, name: child_area.name,}))})) }))
        setRegionOptions(options)
    }

    const initializeAreaOptions = () => {

        let areaoptionlist = []
        let object = {}
            object.label = "None"
            object.value = undefined
            areaoptionlist.push(object)

        areas?.map(area => {
            let object = {}
            object.label = area.name
            object.value = area.area_id
            areaoptionlist.push(object)
            area.child_areas?.map(child => {
                let object = {}
                object.label = area.name+": "+child.name
                object.value = child.area_id
                areaoptionlist.push(object)
            })

        })
        setAreaOptions(areaoptionlist)
    }

    const entry_options=[
          {label:'Read',value:'insetReadaloud'},
          {label:'Text',value:'entries'},
          {label:'Blank',value:'', isDisabled: true},
          {label:'table',value:'table', isDisabled: true},
          {label:'list',value:'List', isDisabled: true},
          {label:'image',value:'Image', isDisabled: true}
      ]
    const [formMonstersOpen, setFormMonstersOpen] = useState(true)
    const [monsters, setMonsters] = useState([]);
    const [encounterMonster, setEncounterMonster] = useState()

    const [eventFormNextBtn, setEventFormNextBtn] = useState(true)
    const [eventFormSubmitBtn, setEventFormSubmitBtn] = useState(false)



    const fetchMonsters = () => {
        setLoading(true)
          // GET THE MONSTERS FROM THE API
        client.getMonsters().then((data) => {
            setLoading(false)
            // SET THE RECIPIES DATA
            setMonsters(data.data)
          });
    }

    const fetchConditions = () => {
        client.getconditions().then((data) => {
            setConditions(data.data)
            handleOptions(data.data)
          });
    }
    const handleOptions = (cond) => {
     let condition_options = []
        cond.map(condition => {
             let option = {}
             option.label = condition.name
             option.value = condition.condition_id
             condition_options.push(option)
         })
        setConditionOptions(condition_options)
    }
    const initializeInputs = (area) => {
      startinput.name = area.name;
      startinput.parent_id = area.parent_id
      startinput.sections = area.sections;
      startinput.area_to = []
      startinput.region_id = area.region_id
      startinput.event_list = []
      startinput.encountergroup_list = []
      {area.to_areas?.map(to => {
          startinput.area_to.push(to.area_id)
      })}
      {area.events?.map(to => {
          startinput.event_list.push(to.event_id)
      })}
      {area.encountergroups?.map(to => {
          startinput.encountergroup_list.push(to.encountergroup_id)
      })}
      setInputs(startinput)

    encounterInputs.areaIds = []
    encounterInputs.areaIds.push(area.area_id)
    encounterInputs.eventIds = []
    }

    useEffect(() => {
        if (region.book !== undefined) {
            fetchRegionList(region.book.book_id)
        }

    }, [region])


     useEffect(() => {
         fetchArea(area_Id)
         fetchRegion(regionId)
         selectArea(area_Id)
         fetchCombats(area_Id)
         fetchConditions()
    }, [regionId,area_Id])



    useEffect(() => {
        buildCombatInput(area.area_id)
    },[area, campaign])

    useEffect(() => {
        if (combatId > 0) {
            navigate(generatePath("/combat/:combatId", {combatId}));
        }
    },[combatId])

    const [map, setMap] = useState({})

    const fetchMap = (area_id) => {
        setMap(undefined)
        client.getSceneFromArea(area_id).then((data) => {
            setMap(data.data)
        })
    }

    const navigateArea = (childdata) => {
        if (childdata === 0) {
            setAreaId(undefined)
        } else {
            let areaUrl = generatePath("/region/"+regionId+"/"+childdata)
            navigate(areaUrl)
        }

    }


    const selectArea = (childdata) => {

        if (childdata === 0) {
            setAreaId(undefined)
        }
        else {
            setAreaId(childdata)

            fetchCombats(childdata)
            if(childdata > 0) {
                fetchArea(childdata)
                fetchMap(childdata)
            }
            else {
                setCombatEnable(false)
            }
        }
  }



    const buildCombatInput = (areaId) => {
            if(campaign.party !== undefined) {
                let comInp = {}
                    comInp.area_id = areaId
                    comInp.party_id = campaign.party.party_id
                    comInp.encountergroups = []
                    area.encountergroups?.map(enc => {
                        comInp.encountergroups.push(enc.encountergroup_id)
                    })
                    setCombatInputs(comInp)

            }

    }

        const fetchCombats = (areaId) => {
          // GET THE MONSTERS FROM THE API
        client.getcombats(areaId).then((data) => {
            setCombats(data.data)
          });
    }


    const fetchArea = (areaId) => {
        setLoading(true)
        client.getArea(areaId).then((data) => {
            setLoading(false)
            setArea(data.data)
            fetchEncounters(region.region_id)
            fetchEvents(region.region_id)
            initializeInputs(data.data)

          });

    }



    const fetchRegionList = (bookId) => {
        client.getRegions(bookId).then((data) => {
            setRegionList(data.data)
          });

    }

    const selectSpell = (SpellName) => {
    fetchSpell(SpellName);
    handleSpellShow();
  }

  const selectMonster = (MonsterName, book) => {
    fetchMonster(MonsterName, book);
    handleMonsterShow();
  }
   const clickMonster = (monsterId) => {
    fetchMonsterbyId(monsterId);
    handleMonsterShow();
  }
     const clickEncounter = (EncounterId) => {
    fetchEncounterGroup(EncounterId);

  }


  const fetchSpell = (spelldata) => {
        client.searchSpell(spelldata).then((data)=>{
            setSpell(data.data)
        })
    }
    const fetchEncounterGroup = (encountergroupid) => {
        if (encountergroupid !== undefined) {
            client.getEncountergroup(encountergroupid).then((data)=>{
                setEncountergroup(data.data)
                handleEncounterSideShow()
            })
        }

    }
        const fetchEncounter = (encounterid) => {
        if (encounterid !== undefined) {
            client.getEncounter(encounterid).then((data)=>{
                setEncounter(data.data)
                handleEncounterSideShow()
            })
        }

    }

    const fetchMonster = (childdata, book) => {
        client.searchMonster(childdata, book).then((data) => {
            if(data.status == 200) {
               setMonster(data.data)
            }
            else {
               setMonster("")
            }

          });
    }

    const postCombat = () => {
        client.postcombat(combatInputs).then((data) => {
            if(data.status == 200) {
               setCombatId(data.data.combat_id)
            }
            else {
               console.log(data.status,": ", data.data.detail)
            }

          });
    }

    const fetchMonsterbyId = (childdata) => {
        client.getMonster(childdata).then((data) => {
            setMonster(data.data)
          });
    }


    const fetchRegion = (RegionId) => {
        if (RegionId !== undefined) {
            client.getRegion(RegionId).then((data) => {
                // SET THE RECIPIES DATA
                setRegion(data.data)
                fetchEvents(RegionId)
                fetchEncounters(RegionId)
                handleArea(data.data)
            });
        }
    }
    const fetchEvents = (RegionId) => {
          if (RegionId !== undefined) {
              client.getEvents(RegionId).then((data) => {
                  // SET THE RECIPIES DATA
                  setEvents(data.data)
              });
          }
    }
    const fetchEncounters = (RegionId) => {
        if (RegionId !== undefined) {
            // GET THE MONSTERS FROM THE API
            client.getEncountergroups(RegionId).then((data) => {
                // SET THE RECIPIES DATA
                setEncounters(data.data)

            });
        }
    }

    const fetchBook = (bookId) => {
        client.getBook(bookId).then((data) => {
            setBook(data.data)
          });
    }

    const handleEncounterCheckbox = (event) => {
        let name = 'isplotencounter'
        let value = false
        if(event.target.value === "on")
        {
            value = true

        }
       setEncounterInputs(values => ({...values, [name]: value}))

    }

    const handleAddDescrToSection = (SectionId) => {
      client.putAddDescription(SectionId).then((data) => {
         selectArea(area.area_id)
      })

    }


    const handleArea = (region) => {
        setAreas(region.areas.sort((a,b)=>a.area_id - b.area_id))
        let flatlist = []
        region.areas.sort((a,b)=>a.area_id - b.area_id)
        region.areas?.map(area => {
            flatlist.push(area)
            area.child_areas?.sort((a,b)=>a.area_id - b.area_id)
            area.child_areas?.map(child => {
                flatlist.push(child)
            })
        })
        setFlatAreas(flatlist)
    }

    const handleEventAreaAdd = (area) => {
            eventInputs.areaIds.push(area.area_id)
      }

  const AddNewRegion = () => {
        setaddRegionModalShow(true)
  }

  const AddNewArea = (area_id) => {
        setaddAreaModalShow(true)
        initializeAddAreaInputs(area_id)
  }


    const handleEventAreaRemove = (area) => {
        let filteredinputs = eventInputs.areaIds.filter(item => item !== area.area_id);
        const name = 'areaIds';
        const value = filteredinputs;
        setEventInputs(values => ({...values, [name]: value}))
   }

    const handleEventEncounterAdd = (encounter) => {
        eventInputs.encounterIds.push(encounter.encounter_id)
    }
    const handleEventEncounterRemove = (encounter) => {

        let filteredinputs = eventInputs.encounterIds.filter(item => item !== encounter.encounter_id);
        const name = 'encounterIds';
        const value = filteredinputs;
        setEventInputs(values => ({...values, [name]: value}))

      }

    const selectMonsterModal = (monster_id) => {
        setEncounterMonster(monsters.filter(f=>f.monster_id === monster_id)[0]);
        setFormMonstersOpen(false);
    }

    const handleEventChange = (event) => {
          const name = event.target.name;
          const value = event.target.value;
          setEventInputs(values => ({...values, [name]: value}))

      }

      const handleEncounterInputChange = (event) => {
         const name = event.target.name;
          const value = event.target.value;
          setEncounterInputs(values => ({...values, [name]: value}))
      }


      const handleDescriptionChange = (event) => {

        if (event.target === undefined) {
            const name = 'name'
            const value = event.value
            setdescriptionInputs(values => ({...values, [name]: value}))

        }
        else {
            const name = event.target.name;
            const value = event.target.value;
            setdescriptionInputs(values => ({...values, [name]: value}))
        }
      }

    const handleAddRegionModelClose = () => {
        setaddRegionModalShow(false)
    }

    const handleRegionInputChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setRegionInputs(values => ({...values, [name]: value}))
    }



    const handleAddAreaModelClose = () => {
        setaddAreaModalShow(false)
    }

    const [AddAreaInputs, setAddAreaInputs] = useState({})
    const [addAreaModalShow, setaddAreaModalShow ] = useState(false)

    const initializeAddAreaInputs = (area_id) => {
        let object = {}
        let sectionObject = {}
        let descriptionObject = {}

        descriptionObject.name = "entries"
        descriptionObject.description = "Description goes here"

        sectionObject.name = "Section Name"
        sectionObject.description_list = []
        sectionObject.description_list.push(descriptionObject)

        object.name = ""
        object.parent_id = area_id
        object.IsHomeBrew = true
        object.region_id = region.region_id

        object.sectionList = []
        object.sectionList.push(sectionObject)
        setAddAreaInputs(object)
    }

    const handleAddAreaInputChange = (event) => {

        const name = event.target.name
        const value = event.target.value

       setAddAreaInputs(values => ({...values, [name]: value}))
    }

    const [editSectionModalShow, setEditSectionModalShow] = useState(false)
    const [editSectionInput, setEditSectionInput] = useState({})

    const handleEditSectionModalClose = () => {
        setEditSectionModalShow(false)

    }
    const handleEditSectionModalShow = (section_id, section_name) => {
        initializeAreaOptions()
        InitializeEditSectionInput(section_id, section_name)
        setEditSectionModalShow(true)

    }

    const InitializeEditSectionInput = (section_id, section_name) => {
        let object = {}
        object.section_id = section_id
        object.name = section_name

        setEditSectionInput(object)
    }

    const handleEncounterDelete = (encounter_id) => {
        client.delEncounter(encounter_id).then((data) => {
             handleEncounterSideClose()
            handleEncounterClose()
            selectArea(area.area_id)
        })

    }

    const handle_convert_to_area = (section_id) => {
        let object = {}
        object.parent_id = area.area_id
        object.section_id = section_id

        client.convertSection(object).then((data) => {
            setEditSectionModalShow(false)
            fetchRegion(region.region_id)
            fetchArea(area.area_id)
        })

    }

    const handleEditSectionInputChange = (event) => {
        if (event.target === undefined) {
            const name = 'area_id'
            const value = event.value
            setEditSectionInput(values => ({...values, [name]: value}))

        }

        const name = event.target.name
        const value = event.target.value

       setEditSectionInput(values => ({...values, [name]: value}))
    }


    const handleAddAreaSubmit = () => {
        console.log(AddAreaInputs)
        client.postArea(AddAreaInputs).then((data) => {
            setaddAreaModalShow(false)
            fetchRegion(region.region_id)
            initializeAddAreaInputs()
        })

    }

    const handleEditSectionModalSubmit = () => {
        client.putSection(editSectionInput).then((data) => {
            handleEditSectionModalClose()
        })

    }

    const MoveParty = (Area) => {
        console.log(Area)
        let object = {}
        object.area_id = Area.area_id
        object.campaign_id = campaign.campaign_id
        client.putCampaign(object).then((data) => {
            client.wsTalk("Area: "+ data.data.party.area.area_id)
            fetchArea(data.data.party.area.area_id)
         })
        }


    return (
        <div>
            <Menu
                book = {book}
                campaign = {campaign}
                setCampaign = {ChooseCampaign}
            />


     <div className="row">
                <div className="col-3">
                    {region.region_id === undefined &&
                    <Regionlist
                        Regions={regions}
                        selectArea={selectArea}
                        fetchRegion={fetchRegion}
                        AddNewRegion={AddNewRegion}
                    />
                    }
                    {region.region_id !== undefined &&
                        <Arealist
                            Areas={areas}
                            selectArea={selectArea}
                            AddNewArea={AddNewArea}
                            Region={region}
                        />
                    }
                </div>

                <div className="col-9">

                    {(areaid === undefined  || areaid === 0 )&&
                    <Region
                        Region={region}
                        selectArea={selectArea}
                        selectSpell={selectSpell}
                        selectMonster={selectMonster}
                        areas={areas}
                    />
                    }
                     {areaid !== undefined  && areaid !== 0  &&
                    <Area
                        Area={area}
                        areas={areas}
                        handleShow={handleShow}
                        selectArea={selectArea}
                        selectSpell={selectSpell}
                        selectMonster={selectMonster}
                        clickMonster={clickMonster}
                        clickEncounter ={clickEncounter}
                        handleEventShow={handleEventShow}
                        updateEvent={handleEventUpdateShow}
                        handleDescriptionShow = {handleDescriptionShow}
                        combats={combats}
                        handleEncounterShow = {handleEncounterShow}
                        handleAddDescrToSection = {handleAddDescrToSection}
                        handleEditSectionModalShow ={handleEditSectionModalShow}
                        canCombat={combatEnable}
                        postCombat={postCombat}
                        MoveParty={MoveParty}
                        localParty={campaign.party}
                        sceneMap={map}


                    />
                    }
                </div>


            </div>

             <Offcanvas className="EditArea" show={show} onHide={handleClose} placement={"end"} scroll={true} backdrop={false}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                     <Areaform
                            inputs={inputs}
                            arealist={areas}
                            eventlist={events}
                            encounterlist={encounters}
                            handleSubmit={handleSubmit}
                            setInputs={setInputs}
                            handleEventShow={handleEventShow}
                            handleEncounterShow={handleEncounterShow}
                            areaOptions={areaOptions}
                            regionOptions={regionOptions}
                            area_id={area.area_id}
                            localarea={area}
                     />
                     <Button variant="primary" onClick={handleSubmit}>
                        Save Changes
                      </Button>
                </Offcanvas.Body>
              </Offcanvas>
             <Modal show={descriptionShow} onHide={handleDescriptionClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Edit Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                          <div>
                            <Form.Group className="mb-3">
                              <Form.Label>Type</Form.Label>
                                <Select
                                    options = {entry_options}
                                    name='name'
                                    autoFocus
                                    defaultValue={entry_options.filter(option => option.value == descriptionInputs.name)}
                                    onChange={handleDescriptionChange} />

                            </Form.Group>
                            <Form.Group
                              className="mb-3"

                            >
                              <Form.Label>Description</Form.Label>
                              <Form.Control as="textarea" rows={3}
                                  name='description'
                                  value={descriptionInputs.description || ""}
                                  onChange={handleDescriptionChange} />
                            </Form.Group>
                              <Form.Group
                              className="mb-3"

                            >
                              <Form.Label>Description Translated</Form.Label>
                              <Form.Control as="textarea" rows={3}
                                  name='description_translated'
                                  value={descriptionInputs.description_translated || ""}
                                  onChange={handleDescriptionChange} />
                            </Form.Group>
                        </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                        <div>
                            <Button variant="warning" onClick={handleDescriptionDelete}>
                               Delete description
                            </Button>
                           <Button variant="primary" onClick={handleDescriptionSubmit}>
                               Save Changes
                            </Button>
                        </div>


                </Modal.Footer>
              </Modal>

             <Modal show={eventShow} onHide={handleEventClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Add Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                      <Collapse in={eventFormNextBtn}>
                          <div>
                            <Form.Group className="mb-3">
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                              name='name'
                              autoFocus
                              value={eventInputs.name || ""}
                              onChange={handleEventChange} />

                            </Form.Group>
                            <Form.Group
                              className="mb-3"

                            >
                              <Form.Label>Description</Form.Label>
                              <Form.Control as="textarea" rows={3}
                                  name='description'
                                  value={eventInputs.description || ""}
                                  onChange={handleEventChange} />
                            </Form.Group>
                              <Form.Group
                              className="mb-3"

                            >
                              <Form.Label>Condition</Form.Label>
                              <Form.Control
                                  name='condition'
                                  value={eventInputs.condition || ""}
                                  onChange={handleEventChange} />
                            </Form.Group>
                        </div>
                      </Collapse>
                      <Collapse in={eventFormSubmitBtn}>
                          <div>
                          <div>
                              {areas.filter(area => eventInputs?.areaIds?.includes(area.area_id)).map(area => {
                                return (
                                    <Link to="#" onClick={() => handleEventAreaRemove(area)} key={area.area_id} className="badge badge-pill badge-area m-1">{area.name}</Link>

                                )
                              })}
                          </div>
                          <div>
                              <Row>
                                <span><b>All Areas</b></span>
                                <div className="AreaForm_AreaList">
                                    {areas.filter(area => !eventInputs?.areaIds?.includes(area.area_id)).map(area => {
                                        return (
                                                <Link to="#" onClick={() => handleEventAreaAdd(area)} key={area.area_id} className="badge badge-pill badge-area m-1">{area.name}</Link>

                                        )
                                    })}
                                </div>

                              </Row>
                          </div>
                          </div>
                      </Collapse>

                  </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Collapse in={eventFormNextBtn}>
                        <div>
                            <Button variant="secondary" onClick={handleEventNext}>
                                Next
                           </Button>
                        </div>
                    </Collapse>
                    <Collapse in={eventFormSubmitBtn}>
                        <div>
                             <Button variant="secondary" onClick={handleEventBack}>
                                Back
                           </Button>
                           <Button variant="primary" onClick={handleEventSubmit}>
                               Save Changes
                            </Button>
                        </div>
                    </Collapse>

                </Modal.Footer>
              </Modal>
            <Modal show={encounterShow} onHide={handleEncounterClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Encounter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                             <Col>
                                <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Quantity:</InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="quantity"
                                  value={encounterInputs.quantity || ""}
                                  onChange={handleEncounterInputChange}
                                />
                          </InputGroup>
                            </Col>
                            <Col>
                                {encounterMonster !== undefined &&
                                <Link to="#" onClick='' key={encounterMonster.monster_id} className="badge badge-pill badge-monster m-1">{encounterMonster.name}</Link>
                                 }

                            </Col>
                            <Col>
                                In:
                            </Col>
                            <Col>
                                <div className="AreaForm_AreaList">
                                {areainputs.map(area => {
                                    return (
                                            <Link to="#" onClick='' key={area.area_id} className="badge badge-pill badge-area m-1">{area.name}</Link>

                                    )
                                })}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div><Form.Check
                                type="checkbox"
                                label="Plot character?"
                                onChange={handleEncounterCheckbox}
                      /></div>

                    <Collapse in={formMonstersOpen}>
                    <div className='MonsterListForm'>
                        <Row>
                        <Col>
                            <span>Search monsters</span>
                           <MonsterList
                               Monsters={monsters}
                               selectMonster={selectMonsterModal}
                               Limit={0}
                           />
                        </Col>
                        <div className='MonsterInput'>

                        </div>
                        <Col>

                        </Col>
                        </Row>
                    </div>
                    </Collapse>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={handleEncounterSubmit}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>

            <Modal show={addAreaModalShow} onHide={handleAddAreaModelClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Add Area</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                          <div>
                            <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Name</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="name"
                                  value={AddAreaInputs.name || ""}
                                  onChange={handleAddAreaInputChange}
                                />
                          </InputGroup>

                        </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                        <div>
                           <Button variant="primary" onClick={handleAddAreaSubmit}>
                               Save Changes
                            </Button>
                        </div>


                </Modal.Footer>
              </Modal>
            <Modal show={editSectionModalShow} onHide={handleEditSectionModalClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Edit Section</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                          <div>
                            <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Name</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="name"
                                  value={editSectionInput.name || ""}
                                  onChange={handleEditSectionInputChange}
                                />
                                </InputGroup>
                              <InputGroup>
                                <Select className="form-select"
                                options = {areaOptions}
                                name='area_id'
                                autoFocus
                                defaultValue={areaOptions.filter(option => option.value === area.area_id)}
                                onChange={handleEditSectionInputChange}
                                />
                                 </InputGroup>



                        </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                        <div>
                           <Button variant="primary" onClick={() => handle_convert_to_area(editSectionInput.section_id)}>
                               Convert to child area
                            </Button>
                        </div>
                        <div>
                           <Button variant="primary" onClick={handleEditSectionModalSubmit}>
                               Save Changes
                            </Button>
                        </div>


                </Modal.Footer>
              </Modal>



                <Offcanvas show={spellShow} onHide={handleSpellClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Spell
                      spell = {spell}
                    />
                </Offcanvas.Body>
              </Offcanvas>
            <Offcanvas show={monsterShow} onHide={handleMonsterClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Monster
                      Monster={monster}
                    />
                </Offcanvas.Body>
              </Offcanvas>
            <Offcanvas show={encounterSideShow} onHide={handleEncounterSideClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Encounter
                      EncounterGroup={encountergroup}
                      handledelete = {handleEncounterDelete}
                    />
                </Offcanvas.Body>
              </Offcanvas>
  </div>
        )
}

export default RegionPage;


