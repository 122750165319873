import {Link} from 'react-router-dom';
import React, {useState} from "react";

const Spell = ({spell}) => {

    const replaceText = (Text) => {
        var textlint = /(?<=\{)(.*?)(?=\})/g
            var matches = textlint.exec(Text)
            if (matches !== null) {
                if (matches.length > 0) {
                var othertext = Text.split(textlint)
                    return (
                othertext?.map(textpieces => {
                    if (textpieces.charAt(0) === '}' || textpieces.slice(-1) === '{') {
                        if (textpieces.charAt(0) === '}')
                            return textpieces.substring(1,textpieces.length).replace("{","")
                        else {
                            return textpieces.substring(0,textpieces.length-1).replace("}","")
                        }
                    } else {
                        //Determine type of replace:
                        if(textpieces.includes("@atk"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                if (match[0] == 'mw')
                                return (
                                    <span>(Melee weapon-attack)</span>
                                )
                            }
                        }

                        if(textpieces.includes("@hit"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>+{match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@h"))
                        {
                            var code = /(?<=h).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <u>{match[0]}</u>
                                )
                            }
                        }
                        if(textpieces.includes("@damage"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <i>{match[0]}</i>
                                )
                            }
                        }


                        if(textpieces.includes("@dc"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>dc {match[0]}</b>
                                )
                            }
                        }
                         if(textpieces.includes("@i"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <i>"{match[0]}"</i>
                                )
                            }
                        }
                        if(textpieces.includes("@dice"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>{match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@sense"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <i><u>{match[0]}</u></i>
                                )
                            }
                        }
                        if(textpieces.includes("@skill"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <u>{match[0]}</u>
                                )
                            }
                        }




                        else {
                            return textpieces
                        }
                    }

                }))
            }
        }else {
                return Text;
            }

    };

    if (spell.components !== undefined)
    {
      return (
            <div className='BookPage'> <div key='namefield' className='SpellTitle'> {spell.name}</div>
                <div key='typefield' className='SpellSubTitle'>
                    {spell.level === 0 &&
                        <p className="text-capitalize" key={spell.school}> Cantrip, {spell.school}, {spell.sourceabbreviation} </p>}
                    {spell.level > 0 &&
                        <p className="text-capitalize" key={spell.school}> Level: {spell.level}, {spell.school}, {spell.sourceabbreviation} </p>}
                </div>
                <div key='time' className='SubList'>
                        <b>Casting time </b> {spell.time?.time} {spell.time?.unit}
                </div>
                <div key='range' className='SubList'>
                        <b>Range</b> {spell.range?.distance} {spell.range?.distance_unit} ({spell.range?.type})
                </div>
                <div key='components' className='SubList'>
                        <b>Components</b> {spell.components.map(c => {
                        return c +", "
                        })}

                </div>
                {spell.duration.time !== '' && spell.duration.time !== null &&
                  <div key='duration' className='SubList'><b>Duration</b> {spell.duration.time} {spell.duration.unit} </div>
                }
                {spell.damagetype.length > 0 &&
                    <div key='damage type' className='SubList'><b>Damage type</b> {spell.damagetype?.map(type => {
                        return type + ", "
                    })}</div>
                }
                 {spell.savingThrow.length > 0 &&
                    <div key='saving throw' className='SubList'><b>Saving Throw</b> {spell.savingThrow?.map(save => {
                        return save + ", "
                    })}</div>
                }

             <div className='divider'></div>
            <div key='spelltext'>

                {spell.entries.map(entry => {
                    return [
                        <p>{entry.text}</p>,
                        entry.entries?.map(subentry => {
                            return <li key={subentry.name}><b>{subentry.name}: </b>{replaceText(subentry.description)}
                            </li>
                        })
                    ]

                })}
            </div>

            </div>
      )
    }


}
export default Spell;