import React, { useEffect, useState, useContext, useRef} from 'react';
import FastAPIClient from '../../client';
import config from '../../config';
import '../../my.css';
import 'JSON';
import 'bootstrap/dist/css/bootstrap.min.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {generatePath, Link, useNavigate, useParams} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from 'react-bootstrap/Container';
import { useDrag } from "../../components/useDrag";
import AreaListMap from "../../components/arealist_map";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import MonsterList from "../../components/monsterList";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import SceneList from "../../components/scenelist";

import Collapsible from "../../components/collapsible";
import Area from "../../components/area";



function useMousePosition({divRef}){
  const [position, setPosition] = useState({ xCord: 0, yCord: 0 });

  useEffect(() => {

    function setFromEvent(e) {
          return setPosition({ xCord: e.clientX, yCord: e.clientY-100 });
      }
      if(divRef.current){
          divRef.current.addEventListener("mousemove", setFromEvent);
      }

    return () => {
        if(divRef.current){
            divRef.current.removeEventListener("mousemove", setFromEvent);
        }
    };
  });
  return position;
};


const client = new FastAPIClient(config);

const Map = () => {

  const [coordinates, setCoordinates] = useState({})
  const [imagePos, setImagePos] = useState({})
  const svgRef = useRef(null);
  const divRef = useRef(null);
  const { xCord, yCord } = useMousePosition({ divRef });
  const [mousedown, setMouseDown] = useState(false);
  const [last_mousex, set_last_mousex] = useState(0);
  const [last_mousey, set_last_mousey] = useState(0);
  const [mousex, set_mousex] = useState(0);
  const [mousey, set_mousey] = useState(0);
  const [rectx, setrectx] = useState(0);
  const [recty, setrecty] = useState(0);
  const [rectwidth, setrectwidth] = useState(0);
  const [rectheight, setrectheight] = useState(0);
  const [editMode, setEditMode] = useState(-100)
  const [mode, setMode] = useState("")
  const [mapMode, setMapMode] = useState(false)
  const [areaMode, setAreaMode] = useState(false)
  const [encounterMode, setEncounterMode] = useState(false)
  const [eventMode, setEventMode] = useState(false)
  const [encounter, setEncounter] = useState({})
  const [event, setEvent] = useState({})
const [travelMode, setTravelMode] = useState(false)
const [travel, setTravel] = useState({})

  const mouseDown = () => {
    set_last_mousex(xCord-10);
    set_last_mousey(yCord);
    setMouseDown(true);
  };

  const toggleEdit = () =>
  {
      setLoading(true)
      if (editMode > 50) {
          setEditMode(-25)
      }else{
          setEditMode(-25)
      }
      setLoading(false)
  }


  const mouseUp = () => {
   let object = {}
      object.x = rectx
      object.y = recty
      object.width = rectwidth
      object.height = rectheight
    setCoordinates(object)
    setMouseDown(false);

  };

  const mouseMove = () => {
    set_mousex(xCord-10);
    set_mousey(yCord);
  };

  const addRectangle = () => {
    if (mousedown && areaMode) {
      const width = Math.abs(mousex - last_mousex);
      const height = Math.abs(mousey - last_mousey);

      const rx = mousex < last_mousex ? mousex : last_mousex;
      const ry = mousey < last_mousey ? mousey : last_mousey;
      if(rectx !== rx)  setrectx(rx);
      if(recty !== ry )setrecty(ry);
      if(rectheight !== height) setrectheight(height);
      if(rectwidth !== width)  setrectwidth(width);

      return (
        <rect
          className={"rectangle"}
          x={rx}
          y={ry}
          height={height}
          width={width}
        />
      );
    }
    if (mousedown && encounterMode) {
      const width = 50/2;
      const height = 50/2;

      const rx = mousex;
      const ry = mousey;
      if(rectx !== rx)  setrectx(rx);
      if(recty !== ry )setrecty(ry);
      if(rectheight !== height) setrectheight(height);
      if(rectwidth !== width)  setrectwidth(width);

      return (
        <circle
          className={"encountercircle"}
          cx={rx}
          cy={ry}
          r={height}
        />
      );
    }
    if (mousedown && eventMode) {
      const width = 50/2;
      const height = 50/2;

      const rx = mousex;
      const ry = mousey;
      if(rectx !== rx)  setrectx(rx);
      if(recty !== ry )setrecty(ry);
      if(rectheight !== height) setrectheight(height);
      if(rectwidth !== width)  setrectwidth(width);

      return (
        <circle
          className={"eventcircle"}
          cx={rx}
          cy={ry}
          r={height}
        />
      );
    }
    if (mousedown && travelMode) {
      const width = 50/2;
      const height = 50/2;

      const rx = mousex;
      const ry = mousey;
      if(rectx !== rx)  setrectx(rx);
      if(recty !== ry )setrecty(ry);
      if(rectheight !== height) setrectheight(height);
      if(rectwidth !== width)  setrectwidth(width);

      return (
        <circle
          className={"eventcircle"}
          cx={rx}
          cy={ry}
          r={height}
        />
      );
    }
  };

  const draggableRef = useRef(null);

  const handleMouseUp = () => {
        const name ="origin"
        const value = position.x+":"+position.y
        setMap(values => ({...values, [name]: value}))

  }

  const { position, handleMouseDown } = useDrag({
    ref: draggableRef
  });

    const {region_id} = useParams();
    const [loading, setLoading] = useState(true)
    const [region, setRegion] = useState([])
    const [backUrl, setBackUrl] = useState("")
    const navigate = useNavigate();


    const changeScale = (event) => {
        let object = {}
        object.scale = event.target.value
        setScene(object)
    }

    useEffect(() => {
         fetchRegion(region_id)
         fetchScenes(region_id)
         InitializeMapInputs()
    }, [region_id])

    const [mapInput, setMapInputs] = useState({})
    const [sceneInput, setSceneInput] = useState({})
    const [scene, setScene] = useState({})
    const [map, setMap] = useState({})
    const [sceneOptions, setSceneOptions] = useState({})
    const [sceneImg, setSceneImg] = useState("https://via.placeholder.com/500")
    const [addSceneShow, setAddSceneShow] = useState(false)
    const [scenes, setScenes] = useState([])
    const [area, setArea] = useState({})
    const [rectInputs, setRectInput] = useState({})
    const [rectList, setRectList] = useState([])

    let scalestats = {}
    scalestats.step = 1
    scalestats.min = 15
    scalestats.max = 250

    const handleEditScene = (scene_id) => {
        setAreaMode(false)
        setMapMode(true)
        setEncounterMode(false)
        setEventMode(false)
        setImageEvents("auto")
        setGridEvents("none")
        setrectheight(0)
        setrectwidth(0)

        setLoading(true)
        client.getScene(scene_id).then((data) => {
            setScene(data.data)
            setMap(data.data.maps[0])
            setLoading(false)
            })
    }

    const handleAddSceneClose = () => {
        setAddSceneShow(false)
    }

    const addScene = () => {
        let object = mapInput
        let sceneInput = {}
        sceneInput.name = "New scene"
        sceneInput.imageUrl = ""
        sceneInput.width = 0
        sceneInput.height = 0
        sceneInput.scale = 0
        sceneInput.rotation = 0
        sceneInput.origin = 0
        sceneInput.areas = []

        setSceneInput(sceneInput)

    }

    const handleSelectScene = (scene_id) => {
    let obj = {}
    obj.coordinates = []
    obj.area_id = 0
        setArea(obj)
        setAreaMode(true)
        setMapMode(false)
         setEncounterMode(false)
        setEventMode(false)
        setImageEvents("none")
        setGridEvents("auto")
        setrectheight(0)
        setrectwidth(0)
        map.scene_name = scene.name
       client.getScene(scene_id).then((data) => {
        setScene(data.data)
        setMap(data.data.maps[0])
        })
    }

    const handleSaveMap = () => {
        setAreaMode(true)
        setMapMode(false)
         setEncounterMode(false)
        setEventMode(false)
        setImageEvents("none")
        setGridEvents("auto")
        setrectheight(0)
        setrectwidth(0)
        map.scene_name = scene.name
        client.putScene(scene.scene_id, map).then((data) => {
            setScene(data.data)
            setMap(data.data.maps[0])
            fetchScenes(region_id)
            })

    }

    const handleAddSceneBtn = () => {
        setAddSceneShow(true)
        addScene()
    }

    const handleAddSceneSubmit = () => {
        sendFile()
    }

    useEffect(() => {
        if (map.origin != undefined) {
          position.x = parseInt(map.origin.split(":")[0])
            position.y = parseInt(map.origin.split(":")[1])
        }

    }, [map])


    const handleSceneInputChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setScene(values => ({...values, [name]: value}))
    }

    const [mapScale, setMapScale] = useState(100)
    const [gridScale, setGridScale] = useState(100)

    const handleMapScale = (event) => {
        setMapScale(event)

        const name = "scale"
        const grid = 1/(gridScale/100)
        const value = ((event/100)*grid) * 100
            setMap(values => ({...values, [name]: value}))
            setMap(values => ({...values, ["imagescale"]: event}))
    }
    const handleGridScale = (event) => {

        setGridScale(event)
        const name = "scale"
        const grid = 1/(event/100)
        const value = ((mapScale/100)*grid) * 100
            setMap(values => ({...values, [name]: value}))
            setMap(values => ({...values, ["gridscale"]: event}))

    }


    const handleMapInputChange = (event) => {
        if (event.target === undefined ){
            const name = "scale"
            const value = event
            setMap(values => ({...values, [name]: value}))

        }
        else {
            const name = event.target.name
            const value = event.target.value
            setMap(values => ({...values, [name]: value}))
        }

    }



    const [mapFile, setMapFile ] = useState(null)
    const formData = new FormData();


    const setFile = (event) => {

        setMapFile(event.target.files[0])
    }
    const sendFile = () => {

        formData.append("scenename", sceneInput.name)
        formData.append("file",  mapFile)

        client.postImage(region_id,formData).then((data) => {
            setAreaMode(false)
            setMapMode(true)
             setEncounterMode(false)
            setEventMode(false)
            setEditMode(-100)
            setScene(data.data)
            setMap(data.data.maps[0])
            })
        fetchScenes(region_id)
        handleAddSceneClose()
    }

    const InitializeMapInputs = (region_id) => {
        let object = {}
        object.region_id = region_id
        object.scenes = []
        object.scenes.push()
        setMapInputs(object)
    }

    const initializeRectInput = () => {
        let object = {}
        object.x = 0
        object.y = 0
        object.width = 0
        object.height = 0

        setRectInput(object)
    }

    useEffect(() => {
        setRectInput(coordinates)
    }, [coordinates])

    useEffect(() => {
        if (scene.maps !== undefined) {
            setSceneImg("https://api.sorenkoelbaek.dk/" + scene.maps[0].image_url)
        }
    }, [scene])

    const fetchArea = (area_id) => {
        setEncounter({})
        setrectheight(0)
        setrectwidth(0)
        initializeRectInput()
        client.getCoordinates(area_id).then((data) => {
                setArea(data.data)
                console.log(data.data)
                setRectList(data.data.coordinates)
          });
    }

    const fetchRegion = (region_id) => {
        setLoading(true)
            client.getRegion(region_id).then((data) => {
            setLoading(false)
             setBackUrl(generatePath("/region/:regionId",
                {regionId: data.data.region_id}))
                setRegion(data.data)

          });
    }
    const fetchScenes = (region_id) => {
        setLoading(true)
            client.getScenes(region_id).then((data) => {
            setLoading(false)
                setScenes(data.data)
          });
    }

    const handleSaveRect = () => {
        let object = {}
        object.area_id = area.area_id
        object.scene_id = scene.scene_id
        object.coordinates = area.coordinates
        object.coordinates.push(rectInputs)

        client.postCoordinates(object).then((data) => {
                setArea(data.data)
          });

    }

    const handleSelectTravel = (travel) => {
        console.log(travel)
            setAreaMode(false)
            setMapMode(false)
            setEncounterMode(false)
            setEventMode(false)
            setTravelMode(true)

            setTravel(travel)
    }

    const handleSelectEncounter = (encounter) => {
            setAreaMode(false)
            setMapMode(false)
            setEncounterMode(true)
            setEventMode(false)
        setTravelMode(false)
            setEncounter(encounter)
    }

    const handleSelectEvent = (event) => {
            setAreaMode(false)
            setMapMode(false)
            setEncounterMode(false)
            setEventMode(true)
        setTravelMode(false)
            setEvent(event)
    }


    const handleRectClick = (cord) => {

        let object = {}
        object.area_id = area.area_id
        object.scene_id = scene.scene_id
        object.coordinates = area.coordinates.filter(f => f.cord_id != cord.cord_id)
         client.postCoordinates(object).then((data) => {
                setArea(data.data)
          });
    }
    const handleSaveEncounter = () => {
        let obj = {}
        obj.encounter_id = encounter.encounter_id
        obj.damage = encounter.damage
        obj.origin = rectInputs.x+":"+rectInputs.y
        client.putdmg(obj).then((data) => {
            setEncounter(data.data)
        })
    }
    const handleSaveTravel = () => {
        console.log(rectInputs.x+":"+rectInputs.y)
    }
     const handleSaveEvent= () => {
        let obj = {}
        obj.event_id = event.event_id
        obj.origin = rectInputs.x+":"+rectInputs.y
        console.log(obj)
        client.putEvent(obj).then((data) => {
            setEvent(data.data)
            console.log(data.data)
        })
    }

    const clickEditArea = () => {
        setAreaMode(true)
        setMapMode(false)
        setEncounterMode(false)
        setEventMode(false)
        setTravelMode(false)
    }
    const clickEditEncounter = () => {
        setAreaMode(false)
        setMapMode(false)
        setEncounterMode(true)
        setEventMode(false)
        setTravelMode(false)
    }
    const clickEditTravel = () => {
        setAreaMode(false)
        setMapMode(false)
        setEncounterMode(false)
        setEventMode(false)
        setTravelMode(true)
        console.log(area)
    }
    const clickEditEvent = () => {
        setAreaMode(false)
        setMapMode(false)
        setEncounterMode(false)
        setEventMode(true)
        setTravelMode(false)
    }
    const [imageEvents, setImageEvents] = useState("Auto")
    const [gridEvents, setGridEvents] = useState("None")


    if(!loading) {
       return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
              <Container>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                      <Nav.Link href={backUrl}>Go Back</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>

            <div className="row">

                <div className="drawer_container" >
                    <div className="drawer_image" ref={draggableRef} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} style={{
                        backgroundImage: `url(${sceneImg})`,
                        backgroundRepeat: "no-repeat",
                        width: map.width*(map.imagescale/100),
                        height: map.height*(map.imagescale/100),
                        rotate: map.rotation+"deg",
                        top: position.y,
                        left: position.x,
                        zIndex: editMode,
                        pointerEvents: imageEvents
                    }}>
                    </div>
                    <div className="drawer" ref={divRef} style={{
                    backgroundSize: 1000*map.gridscale/100,
                        pointerEvents: gridEvents
                }}>
                      <svg
                        id="svg"
                        ref={svgRef}
                        onMouseDown={mouseDown}
                        onMouseUp={mouseUp}
                        onMouseMove={mouseMove}
                      >
                        {addRectangle() ? (
                          addRectangle()
                        ) : (
                        areaMode ? (
                          <rect
                            className={"rectangle"}
                            x={rectx}
                            y={recty}
                            height={rectheight}
                            width={rectwidth}
                          />
                          ) : encounterMode ? (
                          <circle
                            className={"encountercircle2"}
                            cx={rectx}
                            cy={recty}
                            r={rectheight}
                          />
                          ) : travelMode ? (
                          <circle
                            className={"encountercircle2"}
                            cx={rectx}
                            cy={recty}
                            r={rectheight}
                          />
                          ) : (
                            <circle
                            className={"eventcircle2"}
                            cx={rectx}
                            cy={recty}
                            r={rectheight}
                          />

                        ))}

                          {areaMode && area.coordinates?.map(rectangle => {
                              return (
                              <rect
                                  className={"rectangle2"}
                                    x={rectangle.x}
                                    y={rectangle.y}
                                    height={rectangle.height}
                                    width={rectangle.width}
                                    onClick={() =>handleRectClick(rectangle)}
                                  />)
                          })}
                             {scene.areas?.map(subarea => {
                                 if (subarea.area_id !== area.area_id) {
                                 return (
                                 subarea.coordinates?.map(rectangle => {
                                      return (
                                      <rect
                                          className={"rectangle3"}
                                            x={rectangle.x}
                                            y={rectangle.y}
                                            height={rectangle.height}
                                            width={rectangle.width}
                                          />
                                      )
                          }))}})}
                          {(areaMode || encounterMode || eventMode) && area.encountergroups?.map(encgrp => {
                          return(
                            encgrp.encounters.map(enc => {
                            if(enc.origin !== "" && enc.encounter_id !== encounter.encounter_id) {
                                return(
                                <circle
                                   className={"encountercircle3"}
                                    cx={parseInt(enc.origin?.split(":")[0])}
                                    cy={parseInt(enc.origin?.split(":")[1])}
                                    r="25"
                                />

                                )
                                }
                                 if(enc.origin !== "" && rectInputs.x < 1) {
                                return(
                                <circle
                                   className={"encountercircle2"}
                                    cx={parseInt(enc.origin?.split(":")[0])}
                                    cy={parseInt(enc.origin?.split(":")[1])}
                                    r="25"
                                />

                                )
                                }

                            })
                          )

                          })}
                          {(travelMode) && area.to_areas?.map(to_areas => {
                          return(
                              <circle
                                   className={"eventcircle3"}
                                    cx={parseInt(to_areas.origin?.split(":")[0])}
                                    cy={parseInt(to_areas.origin?.split(":")[1])}
                                    r="25"
                                />
                          )

                          })}

                          {(areaMode || encounterMode || eventMode) && area.events?.map(evt => {
                            if(evt.origin !== "" && evt.event_id !== event.event_id) {
                                return(
                                <circle
                                   className={"eventcircle3"}
                                    cx={parseInt(evt.origin?.split(":")[0])}
                                    cy={parseInt(evt.origin?.split(":")[1])}
                                    r="25"
                                />

                                )
                                }
                                 if(evt.origin !== "" && rectInputs.x < 1) {
                                return(
                                <circle
                                   className={"eventcircle2"}
                                    cx={parseInt(evt.origin?.split(":")[0])}
                                    cy={parseInt(evt.origin?.split(":")[1])}
                                    r="25"
                                />

                                )
                                }


                            })}



                      </svg>
                    </div>
                </div>
                <div className="MapMenu">
                    <SceneList
                    Scenes={scenes}
                    selectScene={handleSelectScene}
                    editScene = {handleEditScene}
                    AddScene={handleAddSceneBtn}

                    />
                    <Collapse in={areaMode || encounterMode || eventMode|| travelMode}>
                        <div>
                            <AreaListMap
                            Areas={region.areas}
                            selectArea={fetchArea}
                            selectedAreaId={area.area_id}
                            scene={scene}
                            />
                        </div>
                    </Collapse>
                </div>
            </div>
            <div className="MapButtonBelow">
                <Collapse in={mapMode}>
                    <div>
                        <Row>
                            <Col>
                                <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">Name</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="name"
                                          value={scene.name || ""}
                                          onChange={handleSceneInputChange}
                                        />
                                  </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">Scale</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="scale"
                                          value={map.imagescale/100 || ""}
                                          disabled="true"
                                        />
                                    <Slider
                                    onChange={handleMapScale}
                                    value={map.imagescale}
                                    min={scalestats.min}
                                    max={scalestats.max}
                                    step={scalestats.step}
                                    />

                                  </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">Position</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="origin"
                                          value={map.origin || ""}
                                          onChange={handleMapInputChange}
                                          disabled="true"
                                        />
                                  </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">Zoom</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="zoom"
                                          value={map.gridscale/100 || ""}
                                          disabled="true"
                                        />
                                    <Slider
                                        onChange={handleGridScale}
                                        value={map.gridscale}
                                        min={scalestats.min}
                                        max={scalestats.max}
                                        step={scalestats.step}
                                    />

                                  </InputGroup>

                                <Button onClick={() => handleSaveMap()}>Save Scene</Button>
                            </Col>
                        </Row>
                    </div>
                </Collapse>
                <Collapse in={areaMode || encounterMode || eventMode || travelMode}>
                <div>

                   <Button onClick={() =>clickEditArea()} >Rects</Button>
                   {area?.encountergroups?.length > 0 &&
                        <Button onClick={() =>clickEditEncounter()} >Encounters</Button>
                   }
                   {area?.events?.length > 0 &&
                        <Button onClick={() =>clickEditEvent()} >Events</Button>
                    }
                    {area?.to_areas?.length > 0 &&
                        <Button onClick={() =>clickEditTravel()} >Areas</Button>
                    }
                  </div>
                </Collapse>
                <Collapse in={areaMode}>
                    <div>
                        <Row>
                            <Col>
                                <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">X</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="x"
                                          value={rectInputs.x || ""}
                                          disabled="true"
                                        />
                                  </InputGroup>
                                  <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">Y</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="y"
                                          value={rectInputs.y || ""}
                                          disabled="true"
                                        />
                                  </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup size="sm" className="mb-3">
                                    <InputGroup.Text id="inputGroup-sizing-sm">Width</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="width"
                                          value={rectInputs.width || ""}
                                          disabled="true"
                                        />
                                  </InputGroup>
                                <InputGroup size="sm" className="mb-3">
                                    <InputGroup.Text id="inputGroup-sizing-sm">Height</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="width"
                                          value={rectInputs.height || ""}
                                          disabled="true"
                                        />
                                  </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={() => handleSaveRect()}>Add Rect to Area</Button>
                            </Col>

                        </Row>

                    </div>

                </Collapse>

                <Collapse in={encounterMode}>
                    <div>
                        <Row>
                            <Col>
                                <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">X</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="x"
                                          value={rectInputs.x || ""}
                                          disabled="true"
                                        />
                                  </InputGroup>
                                  <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">Y</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="y"
                                          value={rectInputs.y || ""}
                                          disabled="true"
                                        />
                                  </InputGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                             <div className="list-group list-small">
                            {area.encountergroups?.map(EncGrp => {
                            return(
                                    EncGrp.encounters.map(enc => {
                                    if(enc.encounter_id === encounter.encounter_id)
                                    {
                                    return(
                                     <Link to="#" onClick={() => handleSelectEncounter(enc)} className="list-group-item list-group-item-warning">
                                        {enc.monster.name !== EncGrp.name &&
                                                enc.monster.name + "  (grp: " + EncGrp.name+")"

                                        }
                                        {enc.monster.name === EncGrp.name &&
                                                enc.monster.name

                                        }

                                        </Link>
                                    )}
                                    else {
                                    return(
                                     <Link to="#" onClick={() => handleSelectEncounter(enc)} className="list-group-item">
                                        {enc.monster.name !== EncGrp.name &&
                                                enc.monster.name + "  (grp: " + EncGrp.name+")"

                                        }
                                        {enc.monster.name === EncGrp.name &&
                                                enc.monster.name

                                        }

                                        </Link>
                                    )}


                                    }))
                                 })
                             }

                        </div>
                            </Col>
                            <Col>
                                <Button onClick={() => handleSaveEncounter()}>Add Encounter position</Button>
                            </Col>
                        </Row>

                    </div>

                </Collapse>
                <Collapse in={travelMode}>
                    <div>
                        <Row>
                            <Col>
                                <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">X</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="x"
                                          value={rectInputs.x || ""}
                                          disabled="true"
                                        />
                                  </InputGroup>
                                  <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">Y</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="y"
                                          value={rectInputs.y || ""}
                                          disabled="true"
                                        />
                                  </InputGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                             <div className="list-group list-small">
                            {area.to_areas?.map(travelArea => {
                                if(travelArea.area_id === travel.area_id) {
                                    return(
                                        <Link to="#" onClick={() => handleSelectTravel(travelArea)} className="list-group-item list-group-item-warning">
                                        {travelArea.name}
                                        </Link>
                                    )}
                                    else {
                                        return(
                                     <Link to="#" onClick={() => handleSelectTravel(travelArea)} className="list-group-item">
                                        {travelArea.name}
                                        </Link>
                                   )
                                    }
                                })
                             }
                        </div>
                            </Col>
                            <Col>
                                <Button onClick={() => handleSaveTravel()}>Add Area Position position</Button>
                            </Col>
                        </Row>

                    </div>

                </Collapse>
                <Collapse in={eventMode}>
                    <div>
                        <Row>
                            <Col>
                                <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">X</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="x"
                                          value={rectInputs.x || ""}
                                          disabled="true"
                                        />
                                  </InputGroup>
                                  <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">Y</InputGroup.Text>
                                        <Form.Control
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="y"
                                          value={rectInputs.y || ""}
                                          disabled="true"
                                        />
                                  </InputGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                             <div className="list-group list-small">
                            {area.events?.map(evt => {
                                    if(evt.event_id === event.event_id)
                                    {
                                    return(
                                     <Link to="#" onClick={() => handleSelectEvent(evt)} className="list-group-item list-group-item-warning">
                                        {evt.name}
                                        </Link>
                                    )}
                                    else {
                                    return(
                                     <Link to="#" onClick={() => handleSelectEvent(evt)} className="list-group-item list-group-item-action">
                                        {evt.name}
                                        </Link>
                                    )}

                                 })
                             }

                        </div>
                            </Col>
                            <Col>
                                <Button onClick={() => handleSaveEvent()}>Add Event position</Button>
                            </Col>
                        </Row>

                    </div>

                </Collapse>

            </div>

            <Modal show={addSceneShow} onHide={handleAddSceneClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Add scene</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                              <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>Add a map image</Form.Label>
                                <Form.Control type="file" onChange={setFile}/>
                              </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={handleAddSceneSubmit}>
                    Save Changes
                  </Button>

                </Modal.Footer>
              </Modal>

</>




    )
    }


}

export default Map;


