import React, { useEffect, useState } from 'react';
import {generatePath, Link,useParams} from "react-router-dom";
import FastAPIClient from '../../client';
import config from '../../config';
import 'JSON';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import InputGroup from 'react-bootstrap/InputGroup';
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row";
import Monster from "../../components/monster";
import Offcanvas from "react-bootstrap/Offcanvas";
import Spell from "../../components/spell";
import SpellList from "../../components/spellList";
import MonsterList from "../../components/monsterList";
import Condition from "../../components/condition";
import {useNavigate} from "react-router";
import Rulelist from "../../components/rulelist";
import Rule from "../../components/rule";
import {Overlay} from "react-bootstrap";
const client = new FastAPIClient(config);


function RollDice(max) {
  return Math.floor(Math.random() * max);
}


const Combat = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [combat, setCombat] = useState({})
    const [backUrl, setBackUrl] = useState("")
    const [region, setRegion] = useState({})
    const [participants, setParticipants] = useState([])
    const [selectedParticipant, setSelectedParticipant] = useState([])
    const [editPartShow, setEditPartShow] = useState(false)
    const [participantInputs, setParticipantInputs] = useState({})
    const [lastUpdated, setLastUpdated] = useState(Date.now())
    const [activeParticipant, setActiveParticipant] = useState({})
    const [combatInput, setCombatInput] =useState({})
    const [roundCount, setRoundCount] = useState("")
    const [conditions, setConditions] = useState({})
    const [conditionOptions, setConditionOptions] = useState({})
    const [selectOptions, setSelectedOptions] = useState({})
    const [monster, setMonster] = useState({})
    const [spellShow, setSpellShow] = useState(false)
    const [spell, setSpell] = useState({})
    const [spells, setSpells] = useState({})
    const [monsters, setMonsters] = useState({})
    const [addmonsterShow, setAddmonsterShow] = useState(false)
    const [addMonster, setAddMonster] = useState({})
    const [condition, setCondition] = useState({})
    const [showMode, setShowMode] = useState("monster")
    const searchLimit = 0;
    let overlayactive = false;

    const fetchMonster = (childdata) => {
        client.getMonster(childdata).then((data) => {
            setMonster(data.data)
            setShowMode("monster")
          });
    }
    const fetchMonsters = () => {
        client.getMonsters().then((data) => {
            setMonsters(data.data)
          });
    }

    const searchAddMonster = (monsterName) => {
        client.searchMonster(monsterName,'mm').then((data) => {
            setAddMonster(data.data)
            setAddmonsterShow(true);
          });

    }
    const selectAddMonster = (monster_id) => {
        client.getMonster(monster_id).then((data) => {
            setAddMonster(data.data)
            setAddmonsterShow(true);
          });

    }

    const handleAddMonsterClose = () => {
        setAddmonsterShow(false)
    }

    const [spellInput, setSpellInput] = useState({})


    const fetchSpell = (SpellId) => {
         client.getSpell(SpellId).then((data) =>  {
             setSpell(data.data)
             setSpellShow(true)
             setSpellInput(data.data)
         })
    }
    const fetchSpells = () => {
         client.getSpells().then((data) =>  {
             setSpells(data.data)
         })
    }

    const [rules, setRules] = useState([])
    const [rule, setRule] = useState({})


    const fetchRules = () => {
         client.getRules().then((data) =>  {
             setRules(data.data)

         })
    }

    const pingWS = (combat_id) => {
        client.wsTalk("combat:"+combat_id.toString())
    }

    const fetchRule = (rule_id) => {
         client.getRule(rule_id).then((data) =>  {
             setRule(data.data)
             setShowMode("rule")
         })
    }


    const selectSearchSpell = (spelldata) => {
    searchSpell(spelldata);
    setSpellShow(true);
    }
    const selectSpell = (spellId) => {
        fetchSpell(spellId)

    }

      const searchSpell = (spelldata) => {
        client.searchSpell(spelldata).then((data)=>{
            setSpell(data.data)
        })
        }

    const handleEditPartClose = () => {
        setEditPartShow(false);
        overlayactive = false;
    }

     const health = (encounter) => {
         let damage = encounter.damage
         if (encounter.damage > encounter.hit_points)
         {
             damage = encounter.hit_points
         }
        return Math.round(((encounter.hit_points-damage)/encounter.hit_points)*100)
    }

    const colour = (health) => {

     if (health < 20) { return 'red' } else
     if (health < 50) { return 'orange'} else
     {return 'green'}

    }

    const alive = (hp) => {
         if(hp<=0) {
             return "Dead"
         }
         else {
             return "alive"
         }
    }


    const handleSpellClose = () => {
         setSpellShow(false)
    }

    const handleOptions = (cond) => {
     let condition_options = []
        cond.map(condition => {
             let option = {}
             option.label = condition.name
             option.value = condition.condition_id
             condition_options.push(option)
         })
        setConditionOptions(condition_options)
    }

    const partName = (part, parts) => {
         if (part.type === "encounter")
         {
             let participantGroup = parts.filter((parts) => parts.group_id === part.group_id)
             if (participantGroup.length > 1)
             {

                 participantGroup.sort((a, b) => a.id - b.id)
                 let index = participantGroup.map(function(e) {return e.id}).indexOf(part.id) + 1
                 return part.name + " - " + (index+9).toString(36).toUpperCase()
             }
             else {
                 return part.name
             }

         }
         else {
             return part.name
         }
    }

    const {combatId} = useParams();

    useEffect(() => {
        fetchCombat()
        fetchConditions()
        fetchMonsters()
        fetchSpells()
        fetchRules()
    }, [combatId])


    useEffect(() => {
        refreshParticipants()

    }, [lastUpdated])

    useEffect(() => {
        handleTurnSubmit()
    }, [combatInput])


    const initializeInputs = (participant) => {
        let object = {}
        object.initiative = participant.initiative
        object.damage = participant.damage
        object.condition = []
        participant.condition.map(cond => {
            object.condition.push(cond)
        })
        if (participant.type === 'encounter') {
            object.encounter_id = participant.encounter_id
            object.state = alive(participant.hit_points-participant.damage)
            object.hidden = participant.hidden

        }
        if (participant.type === 'character') {
            object.character_id = participant.character_id
            object.passive_perception = participant.passive_perception
            object.passive_investigation = participant.passive_investigation
            object.passive_insight = participant.passive_insight
            object.hit_points = participant.hit_points
            object.state = alive(participant.hit_points-participant.damage)
            object.armor_class = participant.armor_class
            object.hidden = false
        }
        if (conditionOptions !== undefined && conditionOptions.length > 1) {
            setSelectedOptions(conditionOptions.filter(condOp => object.condition?.some(cond => cond.condition_id === condOp.value)))

        }

        setParticipantInputs(object)

    }

    const setTurn = (participant, round_count) => {
        let object = {}
        object.combat_id = combatId
        if (round_count !== undefined) {
            setRoundCount(round_count)
            object.round_count = round_count
        }
        if (participant.type === 'encounter') {
            object.turn_id = participant.encounter_id
            object.turn_type = 'encounter'
        }
        if (participant.type === 'character') {
            object.turn_id = participant.character_id
            object.turn_type = 'character'
        }
        setCombatInput(object)

    }

   const EndCombat = () => {
        let object = {}
        object.combat_id = combatId
        object.status = 'Inactive'
        setCombatInput(object)

    }

   const startCombat = () => {
    let object = {}
    object.combat_id = combatId
    object.status = 'Active'
    setCombatInput(object)


    }

    const DeleteCombat = (combat_id) => {
        client.deleteCombat(combat_id).then((data) => {
            pingWS(0)
            navigate(backUrl)
        })


    }

    const handleRollInitiative = (encounter) => {
        const value = RollDice(20)+encounter.dex_mod;
                setParticipantInputs(values => ({...values, ['initiative']: value}))
    }

    const handleGroupRollInitiative = (encounter) => {
        const value = RollDice(20)+encounter.dex_mod;
        handleEncounterGroup(encounter.group_id, value)
        setEditPartShow(false)
        overlayactive = false;
    }


    const handleInputCheck = (event) => {
        let name = "hidden";
        let value = event.target.checked;
        if (event.target.checked === "")
        {
            value = false
        }
        setParticipantInputs(values => ({...values, [name]: value}))
    }

    const handleInputChange = (event) => {
            if (event.target === undefined) {
            let object = participantInputs
                object.condition = []
                event.map(condition => {
                    let conobject = {}
                    conobject.name = ""
                    conobject.condition_id = condition.value
                    object.condition.push(conobject)
                })
                setParticipantInputs(object)
            }
            else {
                const name = event.target.name;
                const value = event.target.value;
                setParticipantInputs(values => ({...values, [name]: value}))
            }

        }


    const handleParticipants = (combat) => {
        let groupList = []
        let groupIndex = 0;
        let ParticipantList = []
        combat.party.party_members?.sort((a, b) => b.initiative - a.initiative).map(char => {
            char.id = char.character_id
            char.type = 'character'
            char.selected=""
            char.state = alive(char.hit_points - char.damage)
            char.turn = ""
            if (combat.turn_type === 'character' && combat.turn_id === char.character_id) {
                char.turn = 'Active'
                char.selected = "selected"
                setSelectedParticipant(char)
                initializeInputs(char)
                setActiveParticipant(char)
            }
            ParticipantList.push(char)
        })
        let encounterlist = []
        combat.encountergroups.map(encgroup => {
            encgroup.encounters.map(enc => {

                encounterlist.push(enc)
                if(groupList.includes(encgroup.encountergroup_id)) {
                    enc.group_name = 'Group_' + groupIndex
                }
                else {
                    groupIndex= groupIndex+1
                    enc.group_name = 'Group_' + groupIndex
                    groupList.push(encgroup.encountergroup_id)

                }
                enc.id = enc.encounter_id
                enc.type = 'encounter'
                enc.group_id = encgroup.encountergroup_id
                enc.hit_points = enc.monster.hit_points
                enc.dex_mod = enc.monster.abilities[1].modifier
                enc.state = alive(enc.monster.hit_points - enc.damage)
                enc.armor_class = enc.monster.armor_class[0].ac
                enc.name = enc.monster.name
                enc.selected = ""
                enc.turn = ""
                if (combat.turn_type === 'encounter' && combat.turn_id === enc.encounter_id) {
                    enc.turn = 'Active'
                    enc.selected = "selected"
                    setSelectedParticipant(enc)
                    initializeInputs(enc)
                    setActiveParticipant(enc)
                } else {

                }
                ParticipantList.push(enc)
            })
        })
        setEncounters(encounterlist)
        setParticipants(ParticipantList)
    }

    const [selectedEncounter, setSelectedEncounter] = useState({})
    const [encounters, setEncounters] = useState([])

    const handleSelection = (event) => {
        let participantList = []

        participants.map(part => {
            if (part.character_id === event.character_id && part.encounter_id === event.encounter_id) {
                part.selected = "selected"
                setSelectedParticipant(part)
                initializeInputs(part)
                if(part.type === 'encounter') {
                    fetchMonster(part.monster.monster_id)
                    setSelectedEncounter(encounters.filter((encs) => encs.encounter_id === part.encounter_id)[0])
                }
            }
            else {
                part.selected = ""
            }

            participantList.push(part)
        })
        setParticipants(participantList.sort((a, b) => b.initiative - a.initiative))

    }

    const handleCastSpell = (spell_level) => {

        let obj = {}
        let spellslotobj = {}


        if (selectedEncounter.used_spell_slots === null) {
            obj.used_spell_slots = []
            spellslotobj.level = spell_level.toString()
            spellslotobj.slots = 1
            obj.used_spell_slots.push(spellslotobj)
        }
        else {
            spellslotobj = selectedEncounter.used_spell_slots.filter((slot) => slot.level === spell_level.toString())[0]
            if (spellslotobj === undefined)
            {
                spellslotobj = {}
                spellslotobj.level = spell_level.toString()
                spellslotobj.slots = 1
            }
            else {
                spellslotobj.slots = spellslotobj.slots+1
            }
            obj.used_spell_slots = selectedEncounter.used_spell_slots.filter((slot) => slot.level !== spell_level.toString())
            obj.used_spell_slots.push(spellslotobj)

        }

        client.putEncounterSpells(selectedEncounter.encounter_id, obj).then((data) => {
            setLastUpdated(Date.now())
            setSelectedEncounter(data.data)
            console.log(data.data)
        })


    }
     const handleUncastSpell = (spell_level) => {
                let obj = {}
        let spellslotobj = {}


        if (selectedEncounter.used_spell_slots === null) {
            obj.used_spell_slots = []
            spellslotobj.level = spell_level.toString()
            spellslotobj.slots = 1
            obj.used_spell_slots.push(spellslotobj)
        }
        else {
            spellslotobj = selectedEncounter.used_spell_slots.filter((slot) => slot.level === spell_level.toString())[0]
            if (spellslotobj === undefined)
            {
                spellslotobj = {}
                spellslotobj.level = spell_level.toString()
                spellslotobj.slots = 0
            }
            else {
                spellslotobj.slots = spellslotobj.slots-1
            }
            obj.used_spell_slots = selectedEncounter.used_spell_slots.filter((slot) => slot.level !== spell_level.toString())
            obj.used_spell_slots.push(spellslotobj)

        }

        client.putEncounterSpells(selectedEncounter.encounter_id, obj).then((data) => {
            setLastUpdated(Date.now())
            setSelectedEncounter(data.data)
            console.log(data.data)
        })
    }

    const  handleKeyDown =(e) => {
    if (e.keyCode === 37 && !overlayactive) {
        console.log(editPartShow)
        console.log("Prev")
        participants.sort((a, b) => b.initiative - a.initiative || a.id - b.id).some((part, index) => {
            if(part.turn === 'Active') {
                if (participants[index-1] === undefined)
                {
                    setTurn(participants[participants.length-1], roundCount-1)
                    return true
                } else {
                    setTurn(participants[index-1])
                    return true
                }
            }
        })
    }
    if (e.keyCode === 39 && !overlayactive) {
        console.log(editPartShow)
        console.log("Next")
        participants.sort((a, b) => b.initiative - a.initiative || a.id - b.id).some((part, index) => {
            if(part.turn === "Active") {
                if (participants[index+1] === undefined)
                {
                    setTurn(participants[0], roundCount+1)

                    return true
                } else {
                    setTurn(participants[index+1])
                    return true
                }
            }
        })
    }
    if (e.keyCode === 32 && !overlayactive) {
        if(selectedParticipant !== undefined) {
            setEditPartShow(true)
            overlayactive = true;
        }
    }
    if (e.keyCode === 13 && !overlayactive) {
        if(selectedParticipant !== undefined) {
            if(combat.status !== "Active") {
                        startCombat()
                    }
            setTurn(selectedParticipant)
        }
    }
  }

  const refreshParticipants = () => {
        client.getcombat(combatId).then((data) => {
            handleParticipants(data.data)
            pingWS(combatId)
          });
    }


    const fetchCombat = () => {
        setLoading(true)
        client.getcombat(combatId).then((data) => {
            pingWS(combatId)
            setCombat(data.data)
            fetchRegion(data.data.area?.area_id)
            handleParticipants(data.data)
            setRoundCount(data.data.round_count)
            setLoading(false)

          });
    }
    const fetchConditions = () => {
        client.getconditions().then((data) => {
            setConditions(data.data)
            handleOptions(data.data)
          });
    }

    const handleTurnSubmit = () =>{
            client.putcombat(combatInput).then((data) => {
                setLastUpdated(Date.now())
                if (combatInput.status === "Inactive")
                {
                    pingWS(0)
                }
                else {
                    pingWS(combatId)
                }

            })


    }
    const handleEncounterGroup = (encountergroupid, initiative) =>{
            client.putEncountergroup(encountergroupid,initiative).then((data) => {
                setLastUpdated(Date.now())
            })


    }

   const handleRuleClick = (rule_id) => {
       fetchRule(rule_id)
    }

    const handleInputSubmit = () =>{
        setEditPartShow(false)
        overlayactive = false;
        if(participantInputs.character_id !== undefined)
            client.putcharacter(participantInputs).then((data) => {
                setLastUpdated(Date.now())
            })
        if(participantInputs.encounter_id !== undefined)
            client.putdmg(participantInputs).then((data) => {
                setLastUpdated(Date.now())
            });


    }

    const fetchRegion = (areaId) => {
        setLoading(true)
          // GET THE MONSTERS FROM THE API
        client.getRegionFromAreaId(areaId).then((data) => {
            setLoading(false)
            // SET THE RECIPIES DATA
            setRegion(data.data)
             setBackUrl(generatePath("/region/:regionId/:area_Id",
                {regionId: data.data.region_id, area_Id: areaId}))

          });

    }

    const selectCondition = (condition_id) => {
        client.getcondition(condition_id).then((data) => {
            setShowMode("condition")
            setCondition(data.data)
        });
    }


    const AddMonsterToEncounter = (monster_id) => {

        let obj = {}
        obj.region_id = combat.area?.region_id
        obj.damage = 0
        obj.areaIds = []
        obj.areaIds.push(combat.area?.area_id)
        obj.quantity = 1
        obj.isplotencounter = false
        obj.combat_id = parseInt(combatId)

        console.log(obj)
        client.postEncounter(monster_id, obj).then((data) => {
            handleAddMonsterClose()
            setLastUpdated(Date.now())
        })
    }

    useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
}, [participants]);



    if (loading)
        return ("Loading...")
    return (
<>
        <div>


            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
              <Container>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">

                  <Nav className="me-auto">
                      <Nav.Link href={backUrl}>Go Back</Nav.Link>
                  </Nav>
                        <MonsterList
                        Monsters={monsters}
                        selectMonster={selectAddMonster}
                        Limit={searchLimit}
                        />
                    <SpellList
                        Spells={spells}
                        selectSpell={selectSpell}
                        Limit={searchLimit}
                        />
                </Navbar.Collapse>
              </Container>
            </Navbar>
            <div className="combat_container">
                <div className='frame'>
                    <div className="roundText">Round: {roundCount}</div>
                    <div>
                        {combat.status}
                        {combat.status === "Active" &&
                            <Button  size="sm" onClick={() => EndCombat()}>End combat</Button>
                        }
                        {combat.status === "Waiting" &&
                            <Button  size="sm" onClick={() => startCombat()}>Start combat</Button>
                        }
                        <Button variant="warning" size="sm" onClick={() => DeleteCombat(combatId)}>Delete combat</Button>
                    </div>

                </div>
                <div className="combat_participants">
                    {participants.sort((a, b) => b.initiative - a.initiative || a.id - b.id).map(part => {
                        return (
                                <div className={`participant ${part.state} ${part.selected} ${part.turn} ${part.type} ${part.group_name}`} onClick={(e) =>
                                        {handleSelection(part)}}>
                                    <div className='part_name'>
                                        {partName(part, participants)}
                                        <div className="part_init"> {part.initiative || 0} </div>
                                    </div>
                                    <div className="HealthContainer">
                                        <div className="part_health">
                                            <div className='progress'>
                                                <div className="progress_bar" style={{width: health(part)+"%", backgroundColor: colour(health(part))}}>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`part_image ${part.type}`}>
                                    </div>
                                    <div className='part_conditions'>
                                        {part.condition.map(cond => {
                                            return(
                                             <div className="part_condition">
                                                 <div className="cond_img">
                                                     <img src={'https://api.sorenkoelbaek.dk/static/conditions/'+cond.name.toLowerCase()+'.png'} alt={cond.name} />
                                                 </div>
                                                 <div className="cond_name">
                                                     {cond.name}
                                                 </div>
                                             </div>

                                            )
                                        })}
                                    </div>

                                    <div className='part_numbers'>
                                         <div className='part_number hp'>
                                             {part.hit_points || 0}
                                         </div>
                                         <div className='part_number dmg'>
                                            {part.damage || 0}
                                        </div>
                                        <div className='part_number health'>
                                                {health(part) || 0}%
                                        </div>
                                        <div className='part_number ac'>
                                            {part.armor_class || 0}
                                        </div>
                                    </div>
                                </div>


                        )
                    })}
                </div>
            </div>
            <div className="sub-container">
                <Container fluid>
                    <Row>
                    <Col>

                        <div className="BookPage">
                            <div className="list-group">
                                {conditions.length > 0 &&
                                    conditions.map(condition => {
                                    return (
                                      <Link to="#" onClick={() => selectCondition(condition.condition_id)} key={condition.condition_id} className="list-group-item list-group-item-action">{condition.name}
                                    </Link>
                                    )
                                })
                                }

                            </div>

                        </div>
                    </Col>
                    <Col sm={6}>
                        {showMode == "monster" &&
                            <Monster
                                Monster={monster}
                                selectSpell={fetchSpell}
                                searchSpell={selectSearchSpell}
                                selectMonster={searchAddMonster}
                                Encounter={selectedEncounter}
                                handleCastSpell = {handleCastSpell}
                                handleUncastSpell = {handleUncastSpell}
                            />
                        }
                        {showMode == "rule" &&
                            <Rule
                                Rule={rule}
                                selectSpell={selectSearchSpell}
                                selectMonster={searchAddMonster}
                            />
                        }
                        {showMode == "condition" &&
                                <Condition
                                    condition={condition}
                                    selectSpell={selectSearchSpell}
                                    selectMonster={searchAddMonster}
                                />
                        }

                    </Col>
                    <Col>
                        <div className="BookPage">
                            <div className="list-group">
                                <Rulelist
                                    Rules={rules}
                                    selectRule={handleRuleClick}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                </Container>


            </div>

        </div>
    <Modal show={editPartShow} onHide={handleEditPartClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                          <div>
                            <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Initiative</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="initiative"
                                  value={participantInputs.initiative || ""}
                                  onChange={handleInputChange}
                                />
                                {participantInputs.character_id === undefined &&
                                    <div>
                                      <Button size="sm" onClick={() => handleRollInitiative(selectedParticipant)}>Roll</Button>
                                        <Button variant="secondary" size="sm" onClick={() => handleGroupRollInitiative(selectedParticipant)}>Roll for group</Button>
                                    </div>

                                }


                          </InputGroup>
                            <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Hidden</InputGroup.Text>
                                <Form.Check
                                type="checkbox"
                                checked={participantInputs.hidden === false ? "" : true}
                                onChange={handleInputCheck}

                              />
                          </InputGroup>
                          <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Damage</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="damage"
                                  value={participantInputs.damage || ""}
                                  onChange={handleInputChange}
                                />

                          </InputGroup>

                             <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Conditions</InputGroup.Text>
                                 <Select
                                    options = {conditionOptions}
                                    name='condition'
                                    isMulti="true"
                                    onChange={handleInputChange}
                                    defaultValue={selectOptions}
                                 />

                          </InputGroup>
                              {participantInputs.character_id !== undefined &&
                                  <div>
                              <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">AC</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="armor_class"
                                  value={participantInputs.armor_class || ""}
                                  onChange={handleInputChange}
                                />
                          </InputGroup>
                              <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">hit points</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="hit_points"
                                  value={participantInputs.hit_points || ""}
                                  onChange={handleInputChange}
                                />
                            </InputGroup>
                          <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">passive perception</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="passive_perception"
                                  value={participantInputs.passive_perception || ""}
                                  onChange={handleInputChange}
                                />
                          </InputGroup>
                          <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">passive perception</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="passive_investigation"
                                  value={participantInputs.passive_investigation || ""}
                                  onChange={handleInputChange}
                                />
                          </InputGroup>
                          <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">passive insight</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="passive_insight"
                                  value={participantInputs.passive_insight || ""}
                                  onChange={handleInputChange}
                                />
                          </InputGroup>

                          </div>}

                        </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                        <div>
                           <Button variant="primary" onClick={handleInputSubmit}>
                               Save Changes
                            </Button>
                        </div>


                </Modal.Footer>
              </Modal>

     <Offcanvas show={spellShow} onHide={handleSpellClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Spell
                      spell = {spell}
                    />
                </Offcanvas.Body>
              </Offcanvas>
    <Offcanvas show={addmonsterShow} onHide={handleAddMonsterClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        <Button onClick={() => AddMonsterToEncounter(addMonster.monster_id)}>Add to combat</Button>
                    </div>
                    <Monster
                      Monster={addMonster}
                    />
                </Offcanvas.Body>
              </Offcanvas>

</>
    )

}

export default Combat;


