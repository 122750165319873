import React, {useEffect, useState} from "react";
import Characterpage from "./characterpage";
import partypage from "./partypage";
import PartyPage from "./partypage";

const CampaignPage = ({campaign}) => {
    return (

        <div className='BookPage'> <div key='namefield' className='Title'> {campaign.name}</div>
           <PartyPage
           party={campaign.party}
           />

        </div>
        )
}

export default CampaignPage