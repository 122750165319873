import {Link} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import { useAuth } from "../Auth.js";

const RegionList = ({Regions,selectArea, fetchRegion, AddNewRegion}) => {

    const { user, setUser } = useAuth();



    const handleclick = (RegionId) => {
        selectArea(0)
        fetchRegion(RegionId)
    }


      return(
          <div>
          <div className="list-group">
              {
                Regions?.map(region => (
                    <Link to={"/region/"+region.region_id} className="list-group-item list-group-item-action">{region.name}
                    </Link>
                ))}
              {user.editmode &&
                <Link to="#" onClick={() => AddNewRegion()} key={"newRegion"} className="list-group-item list-group-item-info"> + Region
                </Link>
              }



      </div>
      </div>
    )




}
export default RegionList;