import React, {useState} from "react";
import Collapsible from "./collapsible";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Link} from "react-router-dom";
import Popover from "react-bootstrap/Popover";
import ModalImage from "react-modal-image";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {FiEdit2, FiPlus} from "react-icons/fi";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router";


const Region = ({Region, selectArea,selectSpell, selectMonster, areas}) => {
    const navigate = useNavigate();

    const health = (encounter) => {
        return Math.round(((encounter.monster.hit_points-encounter.damage)/encounter.monster.hit_points)*100)
    }

    const colour = (health) => {

     if (health < 20) { return 'red' } else
     if (health < 50) { return 'orange'} else
     {return 'green'}

}

    const popover = (pop_item) => (
      <Popover id="popover-basic">
        <Popover.Header as="h3">{pop_item.name}</Popover.Header>
        <Popover.Body>
            { pop_item.monster !== undefined &&
                <div>
                    <b>{pop_item.monster.name}</b>
                    <p>Health: {health(pop_item)}%</p>
                    <p>{pop_item.encounter_id}</p>
                </div>
            }
            { pop_item.monster === undefined &&
                pop_item.description
            }
        </Popover.Body>
      </Popover>
    )


    const replaceText = (Text) => {
        var textlint = /(?<=\{)(.*?)(?=\})/g
            var matches = textlint.exec(Text)
            if (matches !== null) {
                if (matches.length > 0) {
                var othertext = Text.split(textlint)
                    return (
                othertext?.map(textpieces => {
                    if (textpieces.charAt(0) === '}' || textpieces.slice(-1) === '{') {
                        if (textpieces.charAt(0) === '}')
                            return textpieces.substring(1,textpieces.length).replace("{","")
                        else {
                            return textpieces.substring(0,textpieces.length-1).replace("}","")
                        }
                    } else {
                        //Determine type of replace:
                        if(textpieces.includes("@area"))
                        {
                            var areacode = /(?<=\|)(.*?)(?=\|)/g
                            var areamatch = areacode.exec(textpieces)
                            if (areamatch !== null) {
                                var foundarea = areas.filter(el => el.area_source_key === areamatch[0])
                                if (foundarea[0] !== undefined) {
                                    return (
                                        <OverlayTrigger
                                                    placement="bottom"
                                                    delay={{show: 250, hide: 400}}
                                                    overlay={popover(foundarea[0])}
                                                >
                                                    <Link to="#" onClick={() => selectArea(foundarea[0].area_id)}
                                                          >{foundarea[0].name}</Link>
                                                </OverlayTrigger>
                                    )
                                }
                            }
                        }
                        if(textpieces.includes("@skill"))
                        {
                            var code = /(?<=skill ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>{match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@item"))
                        {
                            var code = /(?<= )(.*?)(?=\|)/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>{match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@LocalImg"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            var backend = 'https://api.sorenkoelbaek.dk/static/'
                            if (match !== null) {
                                return (
                                    <div className='image_container'>
                                        <ModalImage className='image'
                                          small={backend+match[0]}
                                          large={backend+match[0]}
                                        />

                                    </div>
                                )
                            }
                        }
                        if(textpieces.includes("@spell"))
                        {
                            var code = /(?<=spell ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <Link to="#" onClick={() => selectSpell(match[0])}
                                                          >{match[0]}</Link>
                                )
                            }
                        }
                        if(textpieces.includes("@dice"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>{match[0]}</b>
                                )
                            }
                        }

                        if(textpieces.includes("@creature"))
                        {
                            var code = /(?<= )(.*?)(?=\|)/g
                            var match = code.exec(textpieces)
                            var bookcode = /(?<=\|)(.*?)(?=\|)/g
                            var bookmatch = bookcode.exec(textpieces)
                            if (match === null)
                            {
                               var code = /(?<= ).*$/g
                                var match = code.exec(textpieces)
                            }
                            if (bookmatch === null) {
                                bookcode = /(?<=\|).*$/g
                                bookmatch = bookcode.exec(textpieces)
                            }
                            if (bookmatch !== null) {
                                if (bookmatch[0].length === 0) {
                                bookmatch = null;
                                }
                            }

                            if (match !== null && bookmatch !== null) {
                                return (
                                    <Link to="#" onClick={() => selectMonster(match[0], bookmatch[0])}
                                    >{match[0]}</Link>
                                )
                            }
                            if (match !== null && bookmatch ===  null ){

                                return (
                                    <Link to="#" onClick={() => selectMonster(match[0], 'mm')}
                                                          >{match[0]}</Link>
                                )
                            }

                            else {
                                return textpieces
                            }
                        }

                        else {
                            return textpieces
                        }
                    }

                }))
            }
        }else {
                return Text;
            }

    };


    if (Region.hasOwnProperty('name'))
    {
      return (
          <div className='BookPage'>
                <div key={Region.region_id} className='Region'>
                    <div key='namefield' className='Region Title'> {Region.name}

                        <Button className="badge badge-editsmall badge-edit m-1" onClick={() => navigate("/map/"+Region.region_id)}>Edit Map</Button>


                    </div>
                    <div key='typefield' className='Region SubTitle'><p>
                        {Region.book.name} - {Region.chapter}
                    </p>
                    </div>
                    <div key='divider' className='divider'></div>
                    <div key='Description' className='Description'>
                        {Region.sections.map(de => {
                            var titleclass = 'Description';
                            if (de.name === 'Gallery') {
                                titleclass = 'GalleryDescription';
                            }
                           return (
                               <div className={titleclass} key={de.name}>
                                   <Collapsible
                                       Title={de.name} >
                                   <div className='Descriptor_name'></div>
                                       <div className='Descriptor_description'>{replaceText(de.description)}</div>
                            {de.description_list?.map(subde => {
                                if (subde.name === 'table') {
                                        try {
                                            var tableObject = JSON.parse(subde.description);
                                        }
                                        catch (error) {
                                            console.log(subde.description)
                                            var tableObject = {}
                                        }

                                            return(<div className={`description_list`}>
                                                <div className='Description_description'>
                                                    <table><tr>
                                                    {tableObject.Header.map(header => {
                                                        return(
                                                            <th>{replaceText(header)}</th>
                                                        )
                                                    })}
                                                        </tr>
                                                        {tableObject.rows.map(row => {
                                                           return(
                                                               <tr>
                                                                {row.map(cell => {
                                                                    return(
                                                                        <td>{replaceText(cell)}</td>
                                                                    )
                                                                })}
                                                               </tr>
                                                            )
                                                    })}


                                                        </table>
                                                     </div>
                                                    <Link to="#" key='editDescriptionBtn' className="badge badge-pill badge-editsmall m-1 hoverhidden"><FiEdit2/></Link>
                                                    </div>
                                            )
                                        }
                                        if (subde.name === 'List') {
                                            var tableObject = JSON.parse(subde.description);
                                            return(<div className={`description_list`}>
                                                    <div className='Description_description'>
                                                    <ul>
                                                        {tableObject.items.map(row => {
                                                           return(
                                                               <li>{replaceText(row)}</li>
                                                            )
                                                    })}


                                                        </ul>
                                                     </div>
                                                    <Link to="#" key='editDescriptionBtn' className="badge badge-pill badge-editsmall m-1 hoverhidden"><FiEdit2/></Link>

                                                 </div>
                                            )
                                        }
                                else
                                {
                                    return (

                                        <div className={`description_list ${subde.name}`}>
                                        <div className='description_description' key ={subde.description}>{replaceText(subde.description)}</div>

                                            </div>
                                         )
                                }




                            })}
                               </Collapsible>
                               </div>
                        )
                        })}
                    </div>

                </div>
        </div>
    )
    }

}
export default Region;