import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import React, {useEffect, useState} from "react";
import FastAPIClient from "../client";
import config from "../config";
import { useAuth } from "../Auth.js";
import {generatePath, Navigate, useNavigate} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import PartySelect from "./partyselect";

const client = new FastAPIClient(config);



const Menu = ({book, campaign, setCampaign}) => {

    const navigate = useNavigate();
    const [AddCampaignModalShow, setAddCampaignModalShow] = useState(false)
    const [campaignInputs, setCampaignInputs] = useState({})
    const { user, setUser } = useAuth();


    const AddCampaignSubmit = () => {
        console.log(campaignInputs)
        client.postCampaign(campaignInputs)
        handleAddCampaignModalClose()
    }

    const logout =() => {
        localStorage.removeItem("auth_token");
        setUser(null)
    }
    const setedit =(state) => {
        let localuser = user
        localuser.editmode = state
        setUser(localuser)
    }

    useEffect(() => {
        fetchParties()
        fetchCampaigns()
    }, [])

    const handleAddCampaignModalClose = () => {
        setAddCampaignModalShow(false)
    }
      const handleAddCampaignModalShow = () => {
        InitializeCampaignInput()
        setAddCampaignModalShow(true)
    }

    const handleCampaignInputChange = (event) => {
        if (event !== undefined) {
            if (event.target === undefined) {
                    const name = 'party_id'
                    const value = event.value
                    setCampaignInputs(values => ({...values, [name]: value}))

                }
                else {
                    const name = event.target.name;
                    const value = event.target.value;
                    setCampaignInputs(values => ({...values, [name]: value}))
                }
        }

    }

    const InitializeCampaignInput = () => {
        let object = {}
        object.name = ""
        object.user_id = user.user_id
        object.book_id = book.book_id
        object.party_id = null

        setCampaignInputs(object)
    }

    const [campaigns, setCampaigns] = useState([])
    const [parties, setParties] = useState([])

    const fetchParties = () => {
    client.getparties().then((data) => {
        setParties(data.data)
        console.log(data.data)
      });
    }

    const fetchCampaigns = () => {
    client.getCampaigns().then((data) => {
        setCampaigns(data.data)
      });
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
              <Container>
                <Navbar.Brand href="/">{book?.name || "DND Toolkit"}</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                      <Nav.Link href="/monster">Monsters</Nav.Link>
                    <Nav.Link href="/spell">Spells</Nav.Link>
                  </Nav>
                    <Nav>
                        {user != null &&
                            <NavDropdown title={user.name} id="basic-nav-dropdown">
                                <NavDropdown.Item className='dropdown' key="Profile" onClick={(e) => {
                                    navigate("/profile");
                                }}>Profile
                                </NavDropdown.Item>
                                <NavDropdown.Item className='dropdown' key="SignOut" onClick={(e) => {
                                    logout()
                                }}>Log Out
                                </NavDropdown.Item>
                                {(user.type==="admin" &&  (user.editmode === undefined || user.editmode === false)) &&
                                    <NavDropdown.Item className='dropdown' key="editenable" onClick={(e) => {
                                        setedit(true)}}>
                                    Enable Edit
                                    </NavDropdown.Item>}
                                {(user.editmode === true) &&
                                    <NavDropdown.Item className='dropdown' key="editdisable" onClick={(e) => {
                                        setedit(false)}}>
                                    Disable Edit
                                    </NavDropdown.Item>}
                                {book?.book_id !== undefined &&
                                    <NavDropdown.Item className='dropdown' key="addcampaign" onClick={(e) => {
                                        handleAddCampaignModalShow()}}>
                                    Start Campaign
                                    </NavDropdown.Item>}

                            </NavDropdown>
                        }
                    </Nav>
                     <Nav>
                         {campaign?.campaign_id === undefined && setCampaign && <NavDropdown title="Campaigns" id="basic-nav-dropdown">
                                  {campaigns?.map(campaign => {
                                return <NavDropdown.Item className='dropdown' key={campaign?.campaign_id} onClick={(e) =>
                                {
                                    setCampaign(campaign)
                                }}>{campaign?.name}</NavDropdown.Item>
                                })}
                              </NavDropdown>
                         }

                  </Nav>
                  <Nav>
                    <Nav.Link onClick={(e) =>
                        {
                            setCampaign(campaign)
                        }}>{campaign?.name}
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            <Modal show={AddCampaignModalShow} onHide={handleAddCampaignModalClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Edit Section</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                          <div>
                            <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Name</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="name"
                                  value={campaignInputs.name || ""}
                                  onChange={handleCampaignInputChange}
                                />
                                </InputGroup>
                              <PartySelect
                                  parties={parties}
                                  onChangeParty={handleCampaignInputChange()}
                              />



                        </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                        <div>
                           <Button variant="primary" onClick={AddCampaignSubmit}>
                               Start Campaign
                            </Button>
                        </div>


                </Modal.Footer>
              </Modal>

            </Navbar>


        )
}
export default Menu;