import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './pages/home';
import Monsters from './pages/monster';
import Book from "./pages/book";
import Spell from "./pages/spell";
import Combat from "./pages/combat";
import Login from "./pages/login";
import Map from "./pages/map";
import RegionPage from "./pages/region"
import Profile from "./pages/profile";
import PlayMap from "./pages/playmap";
import {RequireToken, AuthProvider} from "./Auth";


const App = () => {

  return (
      <div className="Container">
        <AuthProvider>
            <BrowserRouter>
              <Routes>
                  <Route path="/login" element = {<Login/>}/>
                  <Route path="/" element={ <RequireToken><Home/></RequireToken>}/>
                      <Route path="/monster" element={<RequireToken><Monsters/></RequireToken>}/>
                      <Route path="/profile" element={<RequireToken><Profile/></RequireToken>}/>
                      <Route path="/spell" element={<RequireToken><Spell/></RequireToken>}/>
                      <Route path="/book/:bookId" element={<RequireToken><Book/></RequireToken>}/>
                        <Route path="/book/:bookId/:regionId/:area_Id" element={<RequireToken><Book/></RequireToken>}/>
                      <Route path="/region/:regionId" element={<RequireToken><RegionPage/></RequireToken>}/>
                        <Route path="/region/:regionId/:area_Id" element={<RequireToken><RegionPage/></RequireToken>}/>
                      <Route path="/combat/:combatId" element={<RequireToken><Combat/></RequireToken>}/>
                      <Route path="/map/:region_id" element={<RequireToken><Map/></RequireToken>}/>
                      <Route path="/playmap/:token" element={<PlayMap/>}/>
              </Routes>
            </BrowserRouter>
        </AuthProvider>
      </div>
  );
}


export default App;
