import React, {useEffect, useState} from "react";


const CharacterPage = ({character}) => {

    return (

       <div className='BookPage Creature'>

                <div className="dividerTitle"><div key='namefield' className='Title'> {character.name}</div></div>
                <div key='username' className='SpellSubTitle'>
                    <div key='time' className='CharacterList'>
                        <p className="text-capitalize" key="Race"><b>Race:</b> {character.race} </p>
                        <p className="text-capitalize" key="size"><b>Size:</b> {character.size} </p>
                        <p className="text-capitalize" key="hit_points"><b>Max hit-points:</b> {character.hit_points} </p>
                        <p className="text-capitalize" key="damage"><b>Damage taken:</b> {character.damage} </p>
                        <p className="text-capitalize" key="passive_perception"><b>passive perception:</b> {character.passive_perception} </p>
                        <p className="text-capitalize" key="passive_investigation"><b>passive invesstigation:</b> {character.passive_investigation} </p>
                        <p className="text-capitalize" key="hit_insight"><b>Passive Insight:</b> {character.passive_insight} </p>
                        <p className="text-capitalize" key="ac"><b>Armor Class (AC):</b> {character.armor_class} </p>
                        <p className="text-capitalize" key="ac"><b>Colour:</b> {character.colour} </p>
                    </div>

                </div>

             <div className='divider'></div>
            <div key='campaigns'><b>Conditions:</b>
                {character.condition?.map(condition => {
                    return(
                        <div>
                            <p key={condition.condition_id}>{condition.name}</p>
                        </div>
                    )
                    })
                }
            </div>

            </div>
        )
}

export default CharacterPage