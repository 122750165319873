import {Link} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import { useAuth } from "../Auth.js";

const AreaList = ({Areas,selectArea, AddNewArea, selectedAreaId, Region}) => {

    const { user, setUser } = useAuth();
    const [arealist, setAreaList] = useState([])

      return(
      <div>
      <div className="list-group">
          <Link to={"/book/"+Region.book.book_id} key={"GoBack"} className="list-group-item list-group-item-secondary">Return
          </Link>
          <Link to="#" onClick={() => selectArea(0)} key={"RegionSelect"} className="list-group-item list-group-item">
          {Region.name}
          </Link>
          {
            Areas?.sort((a,b)=>a.area_id - b.area_id).map(area => {
                if (area.child_areas.length === 0) {
                    if (area.parent_id === null) {
                        if (area.area_id === selectedAreaId)
                        {
                             return (
                                <div>
                                    <Link to={"/region/"+Region.region_id+"/"+area.area_id}  key={area.area_id}
                                          className="list-group-item list-group-item-warning">{area.name}
                                    </Link>
                                    {user.editmode &&
                                        <Link to="#" onClick={() => AddNewArea(area.area_id)} key={"newArea"}
                                              className="list-group-item list-group-item-info list-group-item-sub"> +
                                            Child
                                            Area</Link>
                                    }
                                </div>
                            )
                        }
                        else {
                            return (
                                <div>
                                    <Link to={"/region/"+Region.region_id+"/"+area.area_id} key={area.area_id}
                                          className="list-group-item list-group-item-action">{area.name}
                                    </Link>
                                    {user.editmode &&
                                        <Link to="#" onClick={() => AddNewArea(area.area_id)} key={"newArea"}
                                              className="list-group-item list-group-item-info list-group-item-sub"> +
                                            Child
                                            Area</Link>
                                    }
                                </div>
                            )
                        }
                    }


                }
                else {

                    return (

                        <div>
                            <Link to={"/region/"+Region.region_id+"/"+area.area_id} key={area.area_id}
                                  className="list-group-item list-group-item-action">{area.name}</Link>
                            {area.child_areas.sort((a, b) => a.area_id - b.area_id).map(subarea => {
                                if (subarea.area_id === selectedAreaId) {
                                    return <Link to={"/region/"+Region.region_id+"/"+subarea.area_id}
                                                 key={subarea.area_id}
                                                 className="list-group-item list-group-item-warning list-group-item-sub">{subarea.name}</Link>
                                }
                                else {
                                    return <Link to={"/region/"+Region.region_id+"/"+subarea.area_id}
                                                 key={subarea.area_id}
                                                 className="list-group-item list-group-item-action list-group-item-sub">{subarea.name}</Link>
                                }
                            })}
                            {user.editmode &&
                                <Link to="#" onClick={() => AddNewArea(area.area_id)} key={"newArea"}
                                      className="list-group-item list-group-item-info list-group-item-sub"> + Child
                                    Area</Link>

                            }
                        </div>

                    )
                }




            })
          }
          {user.editmode &&
              <Link to="#" onClick={() => AddNewArea()} key={"newArea"}
                    className="list-group-item list-group-item-info"> + Area
              </Link>
          }

  </div>
  </div>
)



}
export default AreaList;