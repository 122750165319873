import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Modal from "bootstrap/js/src/modal";
import Button from "react-bootstrap/Button";
import Tooltip from 'react-bootstrap/Tooltip';
import 'JSON';
import Popup from "./popup";
import Collapsible from "./collapsible";
import encounter from "./encounter";
import {FiEdit2, FiPlus} from "react-icons/fi";
import ModalImage from "react-modal-image";
import Nav from "react-bootstrap/Nav";
import { useAuth } from "../Auth.js";


const Area = ({Area, areas, selectArea, handleShow, selectSpell, selectMonster, clickMonster, clickEncounter, handleEventShow, updateEvent, handleDescriptionShow, combats, handleEncounterShow, handleAddDescrToSection, handleEditSectionModalShow, postCombat, MoveParty, localParty, sceneMap}) => {
    const { user, setUser } = useAuth();
    const replaceText = (Text) => {
        var textlint = /(?<=\{)(.*?)(?=\})/g
            var matches = textlint.exec(Text)
            if (matches !== null) {
                if (matches.length > 0) {
                var othertext = Text.split(textlint)
                    return (
                othertext?.map(textpieces => {
                    if (textpieces.charAt(0) === '}' || textpieces.slice(-1) === '{') {
                        if (textpieces.charAt(0) === '}')
                            return textpieces.substring(1,textpieces.length).replace("{","")
                        else {
                            return textpieces.substring(0,textpieces.length-1).replace("}","")
                        }
                    } else {
                        //Determine type of replace:
                        if(textpieces.includes("@area"))
                        {
                            var areacode = /(?<=\|)(.*?)(?=\|)/g
                            var areamatch = areacode.exec(textpieces)
                            if (areamatch !== null) {
                                var foundarea = areas.filter(el => el.area_source_key === areamatch[0])
                                if (foundarea[0] !== undefined) {
                                    return (
                                            <Link to="#" onClick={() => selectArea(foundarea[0].area_id)}
                                                  >{foundarea[0].name}</Link>
                                    )
                                }
                            }
                        }
                        if(textpieces.includes("@skill"))
                        {
                            var code = /(?<=skill ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>{match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@item"))
                        {
                            var code = /(?<= )(.*?)(?=\|)/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>{match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@dice"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <b>{match[0]}</b>
                                )
                            }
                        }
                        if(textpieces.includes("@spell"))
                        {
                            var code = /(?<=spell ).*$/g
                            var match = code.exec(textpieces)
                            if (match !== null) {
                                return (
                                    <Link to="#" onClick={() => selectSpell(match[0])}
                                                          >{match[0]}</Link>
                                )
                            }
                        }
                        if(textpieces.includes("@LocalImg"))
                        {
                            var code = /(?<= ).*$/g
                            var match = code.exec(textpieces)
                            var backend = 'https://api.sorenkoelbaek.dk/static/'
                            if (match !== null) {
                                return (
                                   <div className='image_container'>
                                        <ModalImage className='image'
                                          small={backend+match[0]}
                                          large={backend+match[0]}
                                        />
                                    </div>
                                )
                            }
                        }
                        if(textpieces.includes("@creature"))
                        {
                            var code = /(?<= )(.*?)(?=\|)/g
                            var match = code.exec(textpieces)
                            var bookcode = /(?<=\|)(.*?)(?=\|)/g
                            var bookmatch = bookcode.exec(textpieces)
                            if (match === null)
                            {
                               var code = /(?<= ).*$/g
                                var match = code.exec(textpieces)
                            }
                            if (bookmatch === null) {
                                bookcode = /(?<=\|).*$/g
                                bookmatch = bookcode.exec(textpieces)
                            }
                            if (bookmatch !== null) {
                                if (bookmatch[0].length === 0) {
                                bookmatch = null;
                                }
                            }

                            if (match !== null && bookmatch !== null) {
                                return (
                                    <Link to="#" onClick={() => selectMonster(match[0], bookmatch[0])}
                                    >{match[0]}</Link>
                                )
                            }
                            if (match !== null && bookmatch ===  null ){
                                return (
                                    <Link to="#" onClick={() => selectMonster(match[0], 'mm')}
                                                          >{match[0]}</Link>
                                )
                            }

                            else {
                                return textpieces
                            }
                        }

                        else {
                            return textpieces
                        }
                    }

                }))
            }
        }else {
                return Text;
            }

    };
    const grouphealth = (encountergroup) => {
        let sum = 0;
        let count = 0;
        encountergroup.encounters.map(encounter => {
            sum+=health(encounter)
            count +=1
        })
        return sum/count
    }

    const health = (encounter) => {
        return Math.round(((encounter.monster.hit_points-encounter.damage)/encounter.monster.hit_points)*100)
    }

    const colour = (health) => {

     if (health < 20) { return 'red' } else
     if (health < 50) { return 'orange'} else
     {return 'green'}

}

    const popover = (pop_item) => {
        return (
            <Popover id="popover-basic">
                <Popover.Header as="h3">{pop_item.name}</Popover.Header>
                <Popover.Body>
                    {pop_item.monster !== undefined &&
                        <div>
                            <b>{pop_item.monster.name}</b>
                            <p>Health: {health(pop_item)}% ({pop_item.monster.hit_points - pop_item.damage})</p>
                            <p><i></i></p>
                        </div>
                    }
                    {pop_item.sections === undefined &&
                        <div>
                            <p>{pop_item.name}</p>
                        </div>
                    }
                    {pop_item.monster === undefined &&
                        pop_item.description
                    }
                </Popover.Body>
            </Popover>
        )
    }

      const CheckPartyHere = () => {
        setCanMove(false)
        Area.parties?.map(party => {
            if(party.party_id === localParty?.party_id) {
                                setCanMove(true)
        }else {
                setCanMove(false)
            }
        })
    }

    const [canMove, setCanMove] = useState(false)

    const CheckCanCombat = () => {
        setCanCombat(false)
        Area.parties?.map(party => {
            if(party.party_id === localParty?.party_id) {
                                if(Area.encountergroups.length > 0) {
                                    setCanCombat(true)
                                }else{
                                    setCanCombat(false)
                                }
        }else {
                setCanCombat(false)
            }
        })
    }

    const [canCombat, setCanCombat] = useState(false)

    useEffect(() => {
        CheckCanCombat()
        CheckPartyHere()
    }, )

    if (Area.hasOwnProperty('name') && combats !== undefined)
    {{Area.sections.sort((a, b) => a.order - b.order)}
      return (
          <div className='BookPage'>
                <div key={Area.area_id} className='Area'>
                    <div key='namefield' className='Area Title'>
                        {Area.name}
                        {user.editmode &&
                            <Link to="#" onClick={handleShow} className="badge badge-pill badge-edit m-1"><FiEdit2/></Link>
                        }

                    </div>
                    <div className='divider'></div>
                    <div>
                        {canMove &&
                            <Link to="#" onClick={() => MoveParty(Area)} className="badge badge-editsmall badge-edit m-1">{localParty.name}</Link>
                        }
                        {localParty !== undefined && !canMove &&
                            <Link to="#" onClick={() => MoveParty(Area)} className="badge badge-editsmall badge-edit m-1">Go
                            Here</Link>

                        }

                    </div>
                    <div className='divider'></div>

                    {Area.sections.map(description => {
                        {description.description_list?.sort((a, b) => a.description_id - b.description_id)}
                        return (
                            <Collapsible
                                Title={description.name}
                                handleShow = {handleEditSectionModalShow}
                                ID={description.section_id}
                                Name={description.name}
                                >
                                <div className='Descriptor_name' key={description.section_id}>
                                <div className='Descriptor_description'>
                                    {description.description_translated || description.description}
                                </div>

                                    {description.description_list?.map(subde => {
                                        if (subde.name === 'table') {
                                            var tableObject = JSON.parse(subde.description);
                                            return(
                                                <div className={`description_list`}>
                                                <div className='Description_description'>
                                                    <table><tr>
                                                    {tableObject.Header.map(header => {
                                                        return(
                                                            <th>{header}</th>
                                                        )
                                                    })}
                                                        </tr>
                                                        {tableObject.rows.map(row => {
                                                           return(
                                                               <tr>
                                                                {row.map(cell => {
                                                                    return(
                                                                        <td>{replaceText(cell)}</td>
                                                                    )
                                                                })}
                                                               </tr>
                                                            )
                                                    })}


                                                        </table>
                                                    {user.editmode &&
                                                        <div className="btnright"><Link to="#" onClick={() => handleDescriptionShow(subde)} key='editDescriptionBtn' className="badge badge-pill badge-editsmall m-1 hoverhidden"><FiEdit2/></Link></div>
                                                    }

                                                    </div>
                                                </div>
                                            )
                                        }
                                        if (subde.name === 'List') {
                                            var tableObject = JSON.parse(subde.description);
                                            return(<div className={`description_list`}>
                                                    <div className='Description_description'>
                                                    <ul>
                                                        {tableObject.items.map(row => {
                                                           return(
                                                               <li>{replaceText(row)}</li>
                                                            )
                                                    })}


                                                        </ul>

                                                    {user.editmode &&
                                                        <div className="btnright"><Link to="#" onClick={() => handleDescriptionShow(subde)} key='editDescriptionBtn' className="badge badge-pill badge-editsmall m-1 hoverhidden"><FiEdit2/></Link></div>
                                                    }
                                                 </div>
                                                </div>
                                            )
                                        }
                                         if (subde.name === 'insetReadaloud') {
                                            return (<div className={`description_list ${subde.name}`}>

                                                    <div className='Description_description'>
                                                        <div className="description_eng">
                                                         {replaceText(subde.description_translated || subde.description)}
                                                        </div>

                                                        {user.editmode &&
                                                        <div className="btnright"><Link to="#" onClick={() => handleDescriptionShow(subde)} key='editDescriptionBtn' className="badge badge-pill badge-editsmall m-1 hoverhidden"><FiEdit2/></Link></div>
                                                        }
                                                     </div>
                                                    </div>
                                                )
                                        }
                                        else {
                                            return (<div className={`description_list ${subde.name}`}>

                                                    <div className='Description_description'>
                                                         {replaceText(subde.description_translated || subde.description)}

                                                    {user.editmode &&
                                                        <div className="btnright"><Link to="#" onClick={() => handleDescriptionShow(subde)} key='editDescriptionBtn' className="badge badge-pill badge-editsmall m-1 hoverhidden"><FiEdit2/></Link></div>
                                                    }
                                                    </div>
                                                    </div>
                                                )
                                            }

                                    })}
                                    <div className={`description_list`}>
                                    {user.editmode &&
                                     <div className=""><Link to="#" onClick={() => handleAddDescrToSection(description.section_id)} key='editDescriptionBtn' className="badge badge-pill badge-area m-1 btnmid hoverhidden"><FiPlus/></Link></div>
                                    }
                                    </div>





                                </div>
                            </Collapsible>
                            )

                    })}


                    {Area.to_areas.length > 0 &&
                        <div key='Areas' className='Description_text'>
                            <Collapsible
                                Title='Areas Reachable:'
                                >
                                {Area.to_areas?.map(area => {
                                    return (
                                        <div className='areas' key={area.area_id}>
                                            <Link to="#" onClick={() => selectArea(area.area_id)}
                                                  className="badge badge-pill badge-area m-1">{area.name}</Link>
                                        </div>
                                    )

                                })}
                            </Collapsible>
                        </div>
                    }
                    {Area.encountergroups?.length > 0 &&
                        <div key='Encounters' className='Description_text'>
                            <Collapsible
                                Title='Encounters in this area'
                                >
                                 <Link to="#" onClick={handleEncounterShow} className="badge badge-pill badge-add btnright m-1">Add Encounter</Link>
                                {Area.encountergroups?.map(encountergroup => {
                                    return (
                                        <div className='areas' key={encountergroup.encountergroup_id}>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{show: 250, hide: 400}}
                                                overlay={popover(encountergroup)}
                                                >
                                                <Link to="#" onClick={() => clickEncounter(encountergroup.encountergroup_id)}
                                                      className="badge badge-pill badge-monster m-1">
                                                    {encountergroup.encounters.length + " X " +encountergroup.name}
                                                <div className='progress'>
                                                    <div className="progress_bar" style={{width: grouphealth(encountergroup)+"%", backgroundColor: colour(grouphealth(encountergroup))}}></div>
                                                </div>
                                                </Link>
                                                </OverlayTrigger>

                                        </div>
                                    )

                                })}
                                </Collapsible>
                        </div>
                    }
                    {Area.events.length > 0 &&
                    <div key='Events' className='Description_text'>
                        <Collapsible
                            Title='Events'>
                            <Link to="#" onClick={handleEventShow} className="badge badge-pill badge-add btnright m-1">Add Event</Link>

                              {Area.events?.map(event => {
                                    return (
                                        <div className='events' key={event.event_id}>
                                            <div className='DescriptionEventTitle'>
                                                <b>{event.name}</b><Link to="#" onClick={() => updateEvent(event)} className="badge badge-pill badge-editsmall m-1"><FiEdit2/></Link>
                                            </div>
                                            <div className="event_description">
                                            <p>{replaceText(event.description)}</p>
                                            <p><b><i>{event.condition}</i></b></p>
                                            </div>
                                                {event.encounters?.length > 0 &&
                                                    <div key={event.name+'_Encounters'} className='event_encounters'>
                                                        <div className='event_subtitle'>Encounters</div>
                                                            {event.encounters?.map(encounter => {
                                                                return (
                                                                    <div className='areas' key={encounter.encounter_id}>
                                                                        <OverlayTrigger
                                                                            placement="right"
                                                                            delay={{show: 250, hide: 400}}
                                                                            overlay={popover(encounter)}
                                                                            >
                                                                            <Link to="#" onClick={() => clickEncounter(encounter.encounter_id)}
                                                                                  className="badge badge-pill badge-monster m-1">
                                                                                {encounter.monster.name}
                                                                            <div className='progress'>
                                                                            <div className="progress_bar" style={{width: health(encounter)+"%", backgroundColor: colour(health(encounter))}}></div>
                                                                        </div>
                                                                        </Link>
                                                                        </OverlayTrigger>

                                                                    </div>
                                                                )

                                                            })}

                                                    </div>
                                                    }

                                        </div>
                                    )

                                 })}
                            </Collapsible>
                    </div>

                    }
                    <div className="Combats">
                        <Collapsible
                            Title='Combats'>
                            {combats?.map(combat => {
                                return (
                                    <Link to={"/combat/"+combat.combat_id} className="badge badge-pill badge-add m-1">Combat - {combat.status}</Link>
                                )
                            })}
                            {canCombat && <Link to="#" onClick={(e) =>
                                {
                                    postCombat()
                                }}>Start Combat</Link>}
                            </Collapsible>
                    </div>
                    <div className="Map">
                        <Collapsible
                            Open="False"
                            Title='Map'>
                            {sceneMap !== undefined &&
                                <svg style={{
                                    width: (sceneMap.maps[0].width*(sceneMap.maps[0].imagescale/100)),
                                    height: (sceneMap.maps[0].height*(sceneMap.maps[0].imagescale/100)),}}>
                                    <mask id="svgmask1" >

                                    {sceneMap.areas?.filter((area) => area.area_id === Area.area_id)[0]?.coordinates?.map(coord => {
                                        return(
                                            <rect
                                                className={"rectangle_mask"}
                                                x={coord.x-(parseInt(sceneMap.maps[0].origin.split(":")[0]))+10}
                                                y={coord.y-(parseInt(sceneMap.maps[0].origin.split(":")[1]))+5}
                                                height={coord.height-10}
                                                width={coord.width}
                                              />
                                        )
                                    })}
                                    </mask>
                                    <image
                                        xlinkHref={"https://api.sorenkoelbaek.dk/"+sceneMap.maps[0].image_url}
                                        xmlnsXlink={"http://www.w3.org/1999/xlink"}
                                        width={sceneMap.maps[0].width*(sceneMap.maps[0].imagescale/100)}
                                        height={sceneMap.maps[0].height*(sceneMap.maps[0].imagescale/100)}

                                        style={{filter: "brightness(20%)", zIndex: -50}}
                                    >

                                    </image>
                                    <image
                                        xlinkHref={"https://api.sorenkoelbaek.dk/"+sceneMap.maps[0].image_url}
                                        xmlnsXlink={"http://www.w3.org/1999/xlink"}
                                        width={sceneMap.maps[0].width*(sceneMap.maps[0].imagescale/100)}
                                        height={sceneMap.maps[0].height*(sceneMap.maps[0].imagescale/100)}
                                        mask="url(#svgmask1)"
                                    >

                                    </image>
                                    {Area.encountergroups?.map(encgrp => {
                                    return(
                                    encgrp.encounters.map(enc => {
                                     if(enc.origin !== "" && enc.origin != undefined) {
                                    return(
                                        <g className="encounter-circle" onClick={() => clickEncounter(encgrp.encountergroup_id)}>
                                        <circle

                                           className={"encountercircle2"}
                                            cx={parseInt(enc.origin?.split(":")[0])-(parseInt(sceneMap.maps[0].origin.split(":")[0]))+10}
                                            cy={parseInt(enc.origin?.split(":")[1])-(parseInt(sceneMap.maps[0].origin.split(":")[1]))}
                                            r="25"
                                        />
                                        <text
                                            x={parseInt(enc.origin?.split(":")[0])-(parseInt(sceneMap.maps[0].origin.split(":")[0]))+10}
                                            y={parseInt(enc.origin?.split(":")[1])-(parseInt(sceneMap.maps[0].origin.split(":")[1]))}
                                            text-anchor="middle"
                                            className="encountercircle-text"
                                        >{enc.monster.name}</text></g>
                                                                               )
                                        }
                                    }))})}

                                    {Area.events.map(evt => {
                                     if(evt.origin !== "" && evt.origin != undefined) {
                                    return(
                                        <g className="encounter-circle">
                                        <circle
                                           className={"eventcircle2"}
                                            cx={parseInt(evt.origin?.split(":")[0])-(parseInt(sceneMap.maps[0].origin.split(":")[0]))+10}
                                            cy={parseInt(evt.origin?.split(":")[1])-(parseInt(sceneMap.maps[0].origin.split(":")[1]))}
                                            r="25"
                                        />
                                        <text
                                            x={parseInt(evt.origin?.split(":")[0])-(parseInt(sceneMap.maps[0].origin.split(":")[0]))+10}
                                            y={parseInt(evt.origin?.split(":")[1])-(parseInt(sceneMap.maps[0].origin.split(":")[1]))}
                                            text-anchor="middle"
                                            className="encountercircle-text"
                                        >{evt.name}</text></g>
                                                                               )
                                        }
                                    })}
                                </svg>


}
                            </Collapsible>
                    </div>

                </div>

        </div>
    )
    }

}
export default Area;