import config from './config';
import * as moment from 'moment';
import * as url from "url";
import Spell from "./components/spell";
import {fetchToken} from "./Auth"

const axios = require('axios');
const FormData = require('form-data');
const headers = {
    Authorization: `Bearer ${fetchToken()}`,
  };



class FastAPIClient {

  constructor(overrides) {
    this.config = {
      ...config,
      ...overrides,
    };

    this.apiClient = this.getApiClient(this.config);
  }


  /* ----- Client Configuration ----- */
  /* Create Axios client instance pointing at the REST api backend */
  getApiClient(config) {
    const initialConfig = {
      baseURL: `${config.apiBasePath}`,
    };
    const client = axios.create(initialConfig);
    return client;
  }


  paramLogin(username, password) {
      const form = new FormData()
      form.append("username", username)
      form.append("password", password)
      return this.apiClient.post("/login/token", form, {headers: {"Content-Type": "application/x-www-form-urlencoded"}});
  }

  postLogin(loginPayload) {
      const form = new FormData()
      form.append("username", loginPayload.username)
      form.append("password", loginPayload.password)
      return this.apiClient.post("/login/token", form, {headers: {"Content-Type": "application/x-www-form-urlencoded"}});
  }



  getMonsters() {
    return this.apiClient.get(`/monster/all`, {headers});
  }

  getMonster(monsterId) {
    return this.apiClient.get(`/monster/single/${monsterId}`, {headers});
  }
  searchMonster(monstername, book) {
    return this.apiClient.get(`/monster/search/${monstername}?source_abbreviation=${book}`, {headers});
  }
  getSpell(SpellId) {
    return this.apiClient.get(`/spell/single/${SpellId}`, {headers});
  }
  getSpells() {
    return this.apiClient.get(`/spell/all/`, {headers});
  }
  getCampaigns() {
    return this.apiClient.get(`/party/campaigns/`, {headers});
  }
  getCampaign(campaign_id) {
    return this.apiClient.get(`/party/campaigns/${campaign_id}`, {headers});
  }
  putCampaign(payload) {
    return this.apiClient.put(`/party/campaigns/`,payload, {headers});
  }
  postImage(Region_Id,formData) {
    return this.apiClient.post(`/area/uploadImage/${Region_Id}`,formData, {headers});
  }
  putScene(scene_id,mapData) {
    return this.apiClient.put(`/area/scene/${scene_id}`,mapData, {headers});
  }
  searchSpell(SpellName) {
    return this.apiClient.get(`/spell/search/${SpellName}`, {headers});
  }
  getRegions(BookId) {
    return this.apiClient.get(`/area/region/all/${BookId}`, {headers});
  }
   getRegion(RegionId) {
    return this.apiClient.get(`/area/region/${RegionId}`, {headers});
  }
  getEvents(RegionId) {
    return this.apiClient.get(`/area/event/region/${RegionId}` ,{headers} );
  }
  getScenes(RegionId) {
    return this.apiClient.get(`/area/scene/all/${RegionId}` ,{headers});
  }
  getScene(scene_id) {
    return this.apiClient.get(`/area/scene/${scene_id}`,{headers});
  }
  getEncountergroups(RegionId) {
    return this.apiClient.get(`/encounter/encountergroup/region/${RegionId}` , {headers});
  }
   getProfile() {
    return this.apiClient.get(`/login/me/`, {headers});
  }
  getArea(AreaId) {
    return this.apiClient.get(`/area/area/${AreaId}` , {headers});
  }
  putArea(AreaId, areapayload) {
    return this.apiClient.put(`/area/area/${AreaId}`,areapayload, {headers});
  }
  putdmg(payload) {
    return this.apiClient.put(`/encounter/damage/`,payload, {headers});
  }
  postEncounter(MonsterId, payload) {
    return this.apiClient.post(`/encounter/encounter/${MonsterId}`,payload, {headers});
  }
  postMonsterClone(payload) {
    return this.apiClient.post(`/monster/clone/`,payload, {headers});
  }
 postEvent(payload) {
    return this.apiClient.post(`/area/event/`,payload, {headers});
  }
  postCampaign(payload) {
    return this.apiClient.post(`/party/campaign/`,payload, {headers});
  }
   putEvent(payload) {
    return this.apiClient.put(`/area/event/`,payload, {headers});

  }
  putDescription(payload) {
    return this.apiClient.put(`/area/description/`,payload, {headers});
  }
  putDeleteDescription(description_id) {
    return this.apiClient.delete(`/area/description/${description_id}`, {headers});
  }
  deleteCombat(combat_id) {
    return this.apiClient.delete(`/party/combat/${combat_id}`, {headers});
  }
  getEvent(EventId) {
    return this.apiClient.get(`/area/event/${EventId}`, {headers});
  }
  getEncounter(EncounterId) {
    return this.apiClient.get(`/encounter/encounter/${EncounterId}`, {headers} );
  }
  delEncounter(EncounterId) {
    return this.apiClient.delete(`/encounter/encounter/${EncounterId}`, {headers});
  }
  getEncountergroup(EncountergroupId) {
    return this.apiClient.get(`/encounter/encountergroup/${EncountergroupId}`, {headers});
  }
  putAddDescription(sectionId) {
    return this.apiClient.put(`/area/section/${sectionId}`, '',{headers});
  }
  putSection(payload) {
      return this.apiClient.put(`/area/section/edit/`,payload, {headers});
  }
  convertSection(payload) {
     return this.apiClient.put(`/area/section/convert/`,payload, {headers});
  }
  putEncountergroup(EncountergroupId, initiative) {
    return this.apiClient.put(`/encounter/encountergroup/${EncountergroupId}/${initiative}`, '',{headers});
  }
  putEncounterSpells(EncounterId, payload) {
    return this.apiClient.put(`/encounter/spells/${EncounterId}`, payload,{headers});
  }
  getBooks() {
    return this.apiClient.get(`/book/adventures/all`, {headers});
  }
  getBook(bookId) {
    return this.apiClient.get(`/book/single/${bookId}`, {headers});
  }
  getRegionFromAreaId(areaId) {
    return this.apiClient.get(`/area/region/area/${areaId}`, {headers});
  }
  postcharacter(payload) {
    return this.apiClient.post(`/party/character/`,payload, {headers});
  }
  postplayer(payload) {
    return this.apiClient.post(`/party/player/`,payload, {headers});
  }
  postBook(payload) {
      return this.apiClient.post(`/book/homebrew`,payload, {headers});
  }
  getPlayers() {
    return this.apiClient.get(`/party/all/player/`, {headers});
  }
  wsTalk(msg) {
    return this.apiClient.get(`/ws/talk/${msg}`, {headers});
  }
  getcharacter() {
    return this.apiClient.get(`/party/all/character/`, {headers});
  }
  getcharacter(playerId) {
    return this.apiClient.get(`/party/character/${playerId}`, {headers});
  }
  postRegion(payload) {
     return this.apiClient.post(`/area/region/new/`,payload, {headers});
  }
  postArea(payload) {
      return this.apiClient.post(`/area/area/`, payload, {headers});
  }

  putcharacter(payload) {
    return this.apiClient.put(`/party/character/`,payload, {headers});
  }
  postparty(payload) {
    return this.apiClient.post(`/party/party/`,payload, {headers});
  }
  getconditions() {
    return this.apiClient.get(`/condition/all/`, {headers});
  }
  getcondition(conditionId) {
    return this.apiClient.get(`/condition/single/${conditionId}`, {headers});
  }
  getCoordinates(area_id) {
    return this.apiClient.get(`/area/coords/${area_id}`, {headers});
  }
  postCoordinates(payload) {
    return this.apiClient.post(`/area/coords/`, payload, {headers});
  }
  getparties() {
      console.log(this.apiClient())
    return this.apiClient.get(`/party/all/party/`, {headers});
  }
  getCharacters() {
    return this.apiClient.get(`/party/all/character/`, {headers});
  }
  getCharacter(character_id) {
    return this.apiClient.get(`/party/character/${character_id}`, {headers});
  }
  getparty(partyId) {
    return this.apiClient.get(`/party/party/${partyId}`, {headers});
  }
  putparty(payload) {
    return this.apiClient.put(`/party/party/`,payload, {headers});
  }
  postcombat(payload) {
    return this.apiClient.post(`/party/combat/`,payload, {headers});
  }
  getcombat(combatId) {
    return this.apiClient.get(`/party/combat/${combatId}`, {headers});
  }
  getcombats(areaId) {
    return this.apiClient.get(`/party/combat/area/${areaId}`, {headers});
  }
  putcombat(payload) {
    return this.apiClient.put(`/party/combat/`,payload, {headers});
  }
  getRules() {
    return this.apiClient.get(`/rule/all/`, {headers});
  }
  getRule(rule_id) {
    return this.apiClient.get(`/rule/single/${rule_id}`, {headers});
  }
  getSceneFromArea(area_id) {
    return this.apiClient.get(`/area/scene/map/area/${area_id}`, {headers});
  }
}



export default FastAPIClient;