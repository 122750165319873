import Collapse from 'react-bootstrap/Collapse';
import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FiEdit2, FiMoreVertical} from "react-icons/fi";
import {Link} from "react-router-dom";
import { useAuth } from "../Auth.js";


const Collapsible = (props) => {
    const { user, setUser } = useAuth();
    const [Open, SetOpen] = useState(props.Open || true);



    return (
        <div className='Description_text'>
            <div key='AreasCollapse' className='DescriptionSubtitle'

            >
                <Row>
                    <Col>
                        {props.Title}
                    </Col>

                    <Col><div className='btnright'>
                        {props.ID !== undefined && user.editmode &&
                                    <Link className="badge badge-pill badge-editsmall m-1" to="#"
                                          onClick={() => props.handleShow(props.ID, props.Name)}><FiEdit2/></Link>
                        }
                        <FiMoreVertical onClick={() => SetOpen(!Open)} />
                    </div>
                    </Col>
                </Row>
             </div>
            <Collapse in={Open}>
                <div>
                    {props.children}
                </div>
            </Collapse>
        </div>
    )

}

export default Collapsible;
