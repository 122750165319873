import React, { useEffect, useState, useContext } from 'react';
import FastAPIClient from '../../client';
import config from '../../config';
import '../../my.css';
import 'JSON';
import 'bootstrap/dist/css/bootstrap.min.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown from "react-bootstrap/NavDropdown";
import {generatePath, Navigate, useNavigate} from "react-router-dom";
import Menu from "../../components/menu";
import ProfileMenu from "../../components/profilemenu";
import ProfilePage from "../../components/profilePage";
import Characterpage from "../../components/characterpage";
import PartyPage from "../../components/partypage";
import CampaignPage from "../../components/campaignPage";
const client = new FastAPIClient(config);



const Profile = () => {

    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState({})
    const [party, setParty] = useState({})
    const [character, setCharacter] = useState({})
    const [campaign, setCampaign] = useState({})
    const [show, SetShow] = useState("profile")
    const [region, setRegion] = useState({})

    const SelectParty = (party_id) => {
        SetShow("party")
        fetchParty(party_id)

    }
    const selectCharacter = (character_id) => {
        SetShow("character")
        fetchCharacter(character_id)
    }

    const selectCampaign = (campaign_id) => {
         SetShow("campaign")
        fetchCampaign(campaign_id)
    }

    const fetchCharacter = (character_id) => {
    client.getCharacter(character_id).then((data) => {
        setCharacter(data.data)

      });
    }

    const fetchRegionFromArea = (area_id) => {
    client.getRegionFromAreaId(area_id).then((data) => {
        setRegion(data.data)

      });
    }

    const fetchCampaign = (campaign_id) => {
    client.getCampaign(campaign_id).then((data) => {
        setCampaign(data.data)

      });
    }

    const fetchParty = (party_id) => {
    client.getparty(party_id).then((data) => {
        setParty(data.data)
        if(data.data.area !== undefined) {
            fetchRegionFromArea(data.data.area.area_id)
        }

      });
    }

    const fetchUser = () => {
        setLoading(true)
          // GET THE MONSTERS FROM THE API
        client.getProfile().then((data) => {
            setLoading(false)
            // SET THE RECIPIES DATA
            setUser(data.data)
          });
    }

    useEffect(() => {
        fetchUser()
        setLoading(false)
    }, [])



    if (loading)
        return ("Loading...")

    return (
        <>
        <Menu/>

            <div className="row">
                <div className="col-3">
                <ProfileMenu
                    SelectParty={SelectParty}
                    selectCharacter={selectCharacter}
                    selectCampaign={selectCampaign}
                    SetShow={SetShow}

                />

                </div>

                <div className="col-9">
                    {show === "profile" && user !== undefined  &&
                         <ProfilePage
                            user={user}
                        />
                    }
                    {show === "character" && character !== undefined &&
                         <Characterpage
                            character={character}
                        />
                    }
                     {show === "party" && party !== undefined &&
                         <PartyPage
                            party={party}
                            region={region}
                        />
                    }
                    {show === "campaign" && campaign !== undefined  &&
                         <CampaignPage
                            campaign={campaign}
                        />
                    }

                </div>


            </div>

</>




    )

}

export default Profile;


