import React, {useEffect, useState} from "react";
import Characterpage from "./characterpage";
import {generatePath} from "react-router-dom";
import {useNavigate} from "react-router";

const PartyPage = ({party, region}) => {
    const navigate = useNavigate();

    const GoBack = () => {
        let url = ""
         if(region.book !== undefined) {
            url = (generatePath("/region/:regionId/:area_Id",
                {regionId: party.area?.region_id, area_Id: party.area?.area_id}))
             console.log(url)
             navigate(url)
        }
    }

    if (party !== undefined) {


    return (

       <div className='BookPage'> <div key='namefield' className='Title'> {party.name}

       </div>

                <div key='username' className='SpellSubTitle'>
                    <div key='time' className='CharacterList'>
                        <p className="text-capitalize" key={party.area?.area_id}><b>currently in:</b> <button onClick={() => GoBack()}> {party.area?.name} </button> </p>
                    </div>

                </div>

             <div className='divider'></div>
            <div key='campaigns'>
                {party.party_members?.map(character => {
                    return(
                        <Characterpage
                        character={character}
                        />
                    )
                    })
                }
            </div>

            </div>
        )
        }
}

export default PartyPage