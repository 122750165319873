import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import PartySelect from "./partyselect";
import Button from "react-bootstrap/Button";
import FastAPIClient from "../client";
import config from "../config";
import {useAuth} from "../Auth";
import BookSelect from "./bookselect";
import Select from "react-select";

const client = new FastAPIClient(config);


const ProfileMenu = ({SelectParty, selectCampaign, selectCharacter, SetShow}) => {

    const [AddCampaignModalShow, setAddCampaignModalShow] = useState(false)
    const [campaignInputs, setCampaignInputs] = useState({})
    const [campaigns, setCampaigns] = useState([])
    const [parties, setParties] = useState([])
    const { user, setUser } = useAuth();
    const [books, setBooks] = useState([])
    const [book, setBook] = useState({})
    const [characters, setCharacters] = useState([])

    useEffect(() => {
        fetchParties()
        fetchCampaigns()
        fetchBooks()
        fetchCharacters()
        handlePlayerOptions()
    }, [])


    const AddCampaignSubmit = () => {
        client.postCampaign(campaignInputs).then((data) => {
            fetchCampaigns()
         })
        handleAddCampaignModalClose()
    }

    const handleAddCampaignModalClose = () => {
        setAddCampaignModalShow(false)
    }
      const handleAddCampaignModalShow = () => {


        InitializeCampaignInput()
        setAddCampaignModalShow(true)
    }


     const handleChangeParty = (event) => {
            const name = "party_id"
            const value = event.value
            setCampaignInputs(values => ({...values, [name]: value}))

    }
     const handleChangeBook = (event) => {
            const name = "book_id"
            const value = event.value
            setCampaignInputs(values => ({...values, [name]: value}))

    }

    const handleCampaignInputChange = (event) => {
        if (event !== undefined) {
                const name = event.target.name;
                const value = event.target.value;
                setCampaignInputs(values => ({...values, [name]: value}))

        }

    }

    const initializePartyInput = () => {
        let object = {}
        object.name = ""
        object.member_ids = []

        setAddPartyInput(object)
    }
    const handlePartyInputChange = (event) => {
            if (event.target === undefined) {
            let object = addPartyInput
                object.member_ids = []
                event.map(character => {
                    let conobject = {}
                    conobject.character_id = character.value
                    object.member_ids.push(conobject)
                })
                setAddPartyInput(object)
            } else {
                const name = event.target.name;
                const value = event.target.value;
                setAddPartyInput(values => ({...values, [name]: value}))

        }

    }


    const InitializeCampaignInput = () => {
        let object = {}
        object.name = ""
        object.user_id = user.user_id
        object.book_id = book.book_id
        object.party_id = null

        setCampaignInputs(object)
    }
    const [addPartyModalShow, setAddPartyModalShow] = useState(false)
    const [addPartyInput, setAddPartyInput] = useState({})
    const [addCharacterInput, setAddCharacterInput] = useState({})
    const [addCharacterModalShow, setAddCharacterModalShow] = useState(false)
    const [addPlayerModalShow, setAddPlayerModalShow] = useState(false)
    const [addPlayerInput, setAddPlayerInput] = useState({})

    const addPlayerModalClose = () => {
        setAddPlayerModalShow(false)
    }
    const addPlayerShow = () => {
        initializePlayerInput()
        setAddPlayerModalShow(true)
    }

    const addPartModalClose = () => {
        setAddPartyModalShow(false)
    }
    const addPartModalShow = () => {
        handleOptions(characters)
        initializePartyInput()
        setAddPartyModalShow(true)
    }

    const AddCharacterSubmit = () => {
        client.postcharacter(addCharacterInput).then((data) => {
            fetchCharacters()
         });

        setAddCharacterModalShow(false)
    }

    const handleChangePlayer = (event) => {
            const name = "player_id"
            const value = event.value
            setAddCharacterInput(values => ({...values, [name]: value}))

    }

    const [playerOptions, setPlayerOptions] = useState([])

    const handlePlayerOptions = () => {
        let options = []
        client.getPlayers().then((data) => {
            data.data.map(player => {
                let option = {}
                option.label = player.name
                option.value = player.player_id
                options.push(option)
            })

      });
        setPlayerOptions(options)
    }

    const addPlayerSubmit = () => {
        client.postplayer(addPlayerInput).then((data) => {
            handlePlayerOptions()
         });

        setAddPlayerModalShow(false)
    }
    const addPartModalSubmit = () => {
        client.postparty(addPartyInput).then((data) => {
            fetchParties()
         });

        setAddPartyModalShow(false)
    }
    const addCharacterModalClose = () => {
        setAddCharacterModalShow(false)
    }
    const addCharModalShow = () => {
        initializeCharacterInput()
        setAddCharacterModalShow(true)
    }
    const initializePlayerInput = () => {
        let object = {}
        object.name = ""
        setAddPlayerInput(object)
    }

    const initializeCharacterInput = () => {
        let object = {}
        object.name = ""
        object.size = ""
        object.hit_points = 0
        object.damage = 0
        object.passive_perception = 0
        object.passive_investigation = 0
        object.passive_insight = 0
        object.race = ""
        object.armor_class = 0
        setAddCharacterInput(object)
    }

    const handlePlayerInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setAddPlayerInput(values => ({...values, [name]: value}))
    }

    const handleCharacterInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setAddCharacterInput(values => ({...values, [name]: value}))
    }

    const AddPlayer = () => {
        addPlayerShow()
    }

    const AddParty = () => {
        addPartModalShow()
    }
    const AddCharacter = () => {
        addCharModalShow()
    }

    const fetchParties = () => {
    client.getparties().then((data) => {
        setParties(data.data)
      });
    }
    const fetchCharacters = () => {
    client.getCharacters().then((data) => {
        handleOptions(data.data)
        setCharacters(data.data)

      });
    }

    const [characterOptions, setCharacterOptions] = useState({})

    const handleOptions = (cond) => {
     let options = []
        cond.map(character => {
             let option = {}
             option.label = character.name
             option.value = character.character_id
             options.push(option)
         })
        setCharacterOptions(options)
    }

    const fetchCampaigns = () => {
    client.getCampaigns().then((data) => {
        setCampaigns(data.data)
      });
    }
    const fetchBooks = () => {
        client.getBooks().then((data) => {
            setBooks(data.data)
          });
    }


    return (
        <div>
            <div className="list-group">
                <Link to="#" onClick={() => SetShow("profile")} key="Profile" className="list-group-item list-group-item">Profile
                </Link>
                 <Link to="#" onClick={() => AddPlayer()} key="Add Player" className="list-group-item list-group-item-secondary">Add Player
                </Link>
                <Link to="#" onClick={() => AddCharacter()} key="Add Character" className="list-group-item list-group-item-secondary">Add Character
                </Link>
                 {characters.map(character => {
                    return (
                        <Link to="#" onClick={() => selectCharacter(character.character_id)} key={character.character_id} className="list-group-item list-group-item-sub">{character.name}
                        </Link>
                        )

                })}
                 <Link to="#" onClick={() => AddParty()} key="AddParty" className="list-group-item list-group-item-secondary">Add party
                </Link>
                {parties.map(party => {
                    return (
                        <Link to="#" onClick={() => SelectParty(party.party_id)} key={party.party_id} className="list-group-item list-group-item-sub">{party.name}
                        </Link>
                        )

                })}
                 <Link to="#" onClick={() => handleAddCampaignModalShow()} key="AddCampaign" className="list-group-item list-group-item-secondary">Add Campaign
                </Link>
                 {campaigns.map(campaign => {
                    return (
                        <Link to="#" onClick={() => selectCampaign(campaign.campaign_id)} key={campaign.campaign_id} className="list-group-item list-group-item-sub">{campaign.name}
                        </Link>
                        )

                })}
            </div>
            <Modal show={addPlayerModalShow} onHide={addPlayerModalClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Add new Player in your group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                          <div>
                            <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Name</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="name"
                                  value={addPlayerInput.name || ""}
                                  onChange={handlePlayerInputChange}
                                />
                                </InputGroup>
                        </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                        <div>
                           <Button variant="primary" onClick={addPlayerSubmit}>
                               Add Player
                            </Button>
                        </div>


                </Modal.Footer>
              </Modal>
             <Modal show={addCharacterModalShow} onHide={addCharacterModalClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Add new Character</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                          <div>
                            <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Name</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="name"
                                  value={addCharacterInput.name || ""}
                                  onChange={handleCharacterInputChange}
                                />
                                </InputGroup>
                              <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Size</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="size"
                                  value={addCharacterInput.size || ""}
                                  onChange={handleCharacterInputChange}
                                />
                                </InputGroup>
                              <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Race</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="race"
                                  value={addCharacterInput.race || ""}
                                  onChange={handleCharacterInputChange}
                                />
                                </InputGroup>
                              <InputGroup size="sm" className="mb-3">
                                 <InputGroup.Text id="inputGroup-sizing-sm">Hit_Points</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  type="number"
                                  name="hit_points"
                                  value={addCharacterInput.hit_points || ""}
                                  onChange={handleCharacterInputChange}
                                />
                                </InputGroup>
                              <InputGroup size="sm" className="mb-3">
                                 <InputGroup.Text id="inputGroup-sizing-sm">Passive Perception</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  type="number"
                                  name="passive_perception"
                                  value={addCharacterInput.passive_perception || ""}
                                  onChange={handleCharacterInputChange}
                                />
                                </InputGroup>
                                <InputGroup size="sm" className="mb-3">
                                 <InputGroup.Text id="inputGroup-sizing-sm">Passive Investigation</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  type="number"
                                  name="passive_investigation"
                                  value={addCharacterInput.passive_investigation || ""}
                                  onChange={handleCharacterInputChange}
                                />
                                </InputGroup>
                              <InputGroup size="sm" className="mb-3">
                                 <InputGroup.Text id="inputGroup-sizing-sm">Passive Insight</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  type="number"
                                  name="passive_insight"
                                  value={addCharacterInput.passive_insight || ""}
                                  onChange={handleCharacterInputChange}
                                />
                                </InputGroup>
                              <InputGroup size="sm" className="mb-3">
                                 <InputGroup.Text id="inputGroup-sizing-sm">AC</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  type="number"
                                  name="armor_class"
                                  value={addCharacterInput.armor_class || ""}
                                  onChange={handleCharacterInputChange}
                                />
                                </InputGroup>
                              <Select className="form-select"
                                options = {playerOptions}
                                name='player_id'
                                onChange={handleChangePlayer}
                            />

                        </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                        <div>
                           <Button variant="primary" onClick={AddCharacterSubmit}>
                               Add Character
                            </Button>
                        </div>


                </Modal.Footer>
              </Modal>
            <Modal show={addPartyModalShow} onHide={addPartModalClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Add new Party</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                          <div>
                            <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Name</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="name"
                                  value={addPartyInput.name || ""}
                                  onChange={handlePartyInputChange}
                                />
                                </InputGroup>
                                 <Select
                                    options = {characterOptions}
                                    name='members'
                                    isMulti="true"
                                    onChange={handlePartyInputChange}
                                    defaultValue={null}
                                 />




                        </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                        <div>
                           <Button variant="primary" onClick={addPartModalSubmit}>
                               Add Party
                            </Button>
                        </div>


                </Modal.Footer>
              </Modal>
            <Modal show={AddCampaignModalShow} onHide={handleAddCampaignModalClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Add new campaign</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                          <div>
                            <InputGroup size="sm" className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-sm">Name</InputGroup.Text>
                                <Form.Control
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="name"
                                  value={campaignInputs.name || ""}
                                  onChange={handleCampaignInputChange}
                                />
                                </InputGroup>
                              <PartySelect
                                  parties={parties}
                                  name="party_id"
                                  onChangeParty={handleChangeParty}
                              />
                              <BookSelect
                                  books={books}
                                  name="book_id"
                                  onChangeBook={handleChangeBook}
                              />




                        </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                        <div>
                           <Button variant="primary" onClick={AddCampaignSubmit}>
                               Add Campaign
                            </Button>
                        </div>


                </Modal.Footer>
              </Modal>
        </div>


    )
}

export default ProfileMenu