import React from 'react';
import {useLocation, Navigate, Outlet} from "react-router-dom";
import { createContext, useContext, useState } from "react";
import jwt_decode from "jwt-decode";


const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {

    let token = localStorage.getItem('auth_token')
    let username = decodeToken(token)

    const [user, setUser] = useState(username);

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const decodeToken = (token) => {
  if (token !== undefined && token !== null) {
      var decoded = jwt_decode(token);
      let currentTime = new Date();
        if (Date.now() >= decoded.exp * 1000) {
            console.log("token is expired");
            localStorage.removeItem("auth_token");
        }
        else {
            return decoded
        }

  }
  return null

}

export const setToken = (token)=>{
    localStorage.setItem('auth_token', token)

}

export const fetchToken = (token)=>{
    return localStorage.getItem('auth_token')

}

export const SignOut = () => {
    const { setUser } = useAuth();

    localStorage.removeItem("auth_token");
    localStorage.removeItem('edit_mode')
    setUser(null)
    Navigate("/");
};

export function RequireToken({children}){

    const { user } = useAuth();
    let location = useLocation()
    if(!user){
        return <Navigate to='/login' state ={{from : location}}/>;
    }

    return children;
}



