import {Link} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import Monster from "./monster";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {InputGroup, Form} from "react-bootstrap";
import Select from "react-select";



 const health = (encounter) => {
     if (encounter != undefined) {
          return Math.round(((encounter.monster.hit_points-encounter.damage)/encounter?.monster.hit_points)*100)
     }

    }

const colour = (health) => {

     if (health < 20) { return 'red' } else
     if (health < 50) { return 'orange'} else
     {return 'green'}

}



const Encounter = ({EncounterGroup, handledelete}) => {


    const [encounterIndex, setEncounterIndex] = useState(0)
    const [encounter, setEncounter] = useState(EncounterGroup.encounters[encounterIndex])
    const [selectOptions, setSelectedOptions] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        setLoading(false)
    }, [encounter])


    const handleEncounterChange = (index) => {
        setEncounterIndex(index)
        setEncounter(EncounterGroup.encounters[index])
    }


    if (loading !== true) {
      return (
          <div>
             <div>
                 <div>
                     <Row>
                         <Col>
                             {encounterIndex > 0 &&
                                 <Button variant="light" onClick={() => handleEncounterChange(encounterIndex-1)}>Previous</Button>
                             }

                         </Col>
                         <Col>
                             {encounterIndex+1} / {EncounterGroup.encounters.length}
                         </Col>
                         <Col>
                             {
                                 encounterIndex+1 < EncounterGroup.encounters.length &&
                                <Button variant="light" onClick={() => handleEncounterChange(encounterIndex+1)}>Next</Button>
                             }

                         </Col>

                     </Row>

                 </div>
                 <div className='progress'>
                    <div className="progress_bar" style={{width: health(encounter)+"%", backgroundColor: colour(health(encounter))}}></div>
                </div>
                 <div className="part_condition">
                     {encounter.condition.map(condition => {
                             return(<div>{condition.name}</div>)

                     })}
                 </div>
                 <Row>
                    <Col>

                    </Col><Col>

                    </Col>
                     <Col>
                         <Button variant="warning" onClick={() => handledelete(encounter.encounter_id)}>Delete</Button>
                    </Col>
                 </Row>




<p></p>

                 <div className='divider'></div>
             </div>
                <Monster
                    Monster={encounter.monster}
                    />
          </div>
    )
        }

}
export default Encounter;