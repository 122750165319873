import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
import Monster from "./monster";
import {Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Accordion from 'react-bootstrap/Accordion';
import {FiMoreVertical} from "react-icons/fi";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import ContentEditable from 'react-contenteditable'
import Collapsible from "./collapsible";
import Select from 'react-select'
import {useNavigate} from "react-router";


const Areaform = ({inputs, arealist, eventlist, encounterlist, handleSubmit, setInputs, handleEventShow, handleEncounterShow, areaOptions, regionOptions, area_id, localarea}) => {

    useEffect(() => {
        console.log(inputs)
        console.log(regionOptions)
    }, [inputs])

    const navigate = useNavigate();
    const { Panel } = Collapse;
    let  [areainputs, setAreaInputs] = useState(arealist.filter(el => inputs.area_to?.some(f => f === el.area_id)));
    let  [eventinputs, setEventInputs] = useState(eventlist.filter(el => inputs.event_list?.some(f => f === el.event_id)));


    let  [localAreaList, setLocalAreaList] = useState(() => {
                if(areainputs.length > 0) {
                   return arealist.filter(el => areainputs.some(f => f.area_id !== el.area_id))
                }else {
                    return arealist
                }
    });



    let  [localEventList, setLocalEventList] = useState(() => {
                if(eventinputs.length > 0) {
                   return eventlist.filter(el => eventinputs.some(f => f.event_id !== el.event_id))
                }else {
                    return eventlist
                }

    });


    const [areasOpen, setAreasOpen] = useState(false);
    const [eventsOpen, setEventsOpen] = useState(false);
    const [encountersOpen, setEncountersOpen] = useState(false);


    const handleChange = (event) => {
        if (event.target === undefined) {
            const name = 'parent_id'
            const value = event.value
            setInputs(values => ({...values, [name]: value}))

        }
        else {
          const name = event.target.name;
          const value = event.target.value;
          setInputs(values => ({...values, [name]: value}))
        }


      }


      const addDescr = (after) => {
        let result = inputs
        result.sections.map(description => {
            if (description.name == after) {
                description.description_list.push({name: "entries", description: "Test", description_translated: ""})
            }
            //return description;
        });
        setInputs(result)
    }



    const handleAreaAdd = (area) => {
            inputs.area_to.push(area.area_id)
      }
      const handleEventAdd = (event) => {
            inputs.event_list.push(event.event_id)

      }

      const popover = (pop_item) => (
      <Popover id="popover-basic">
        <Popover.Header as="h3">{pop_item.name}</Popover.Header>
        <Popover.Body>
            {pop_item.description}
        </Popover.Body>
      </Popover>
    )


      const handleEncounteradd = (encounter) => {
            inputs.encountergroup_list.push(encounter.encountergroup_id)
      }

      const handleAreaRemove = (area) => {

            let filteredinputs = inputs.area_to.filter(item => item !== area.area_id);
            const name = 'area_to';
            const value = filteredinputs;
            setInputs(values => ({...values, [name]: value}))

      }
      const handleEventRemove = (event) => {

            let filteredinputs = inputs.event_list.filter(item => item !== event.event_id);
            const name = 'event_list';
            const value = filteredinputs;
            setInputs(values => ({...values, [name]: value}))

      }
      const handleEncounterRemove = (encounter) => {

            let filteredinputs = inputs.encountergroup_list.filter(item => item !== encounter.encountergroup_id);
            const name = 'encountergroup_list';
            const value = filteredinputs;
            setInputs(values => ({...values, [name]: value}))

      }


    return (

<Container>

        <Form onSubmit={handleSubmit}>
            <Row>
            <label>name:</label>
              <input
                type="text"
                name="name"
                value={inputs.name || ""}
                onChange={handleChange}
              />
                <label>parent area:</label>
                <Select
                options = {areaOptions.filter(option => option.label !== inputs.name && !option.label.includes(": "))}
                name='parent_area'
                autoFocus
                defaultValue={areaOptions.filter(option => option.value === inputs.parent_id)}
                onChange={handleChange}
                />

             </Row>
                <div key='AreasCollapse' className='collapseTitle'
                    onClick={() => setAreasOpen(!areasOpen)}
                  >
                <Row>
                    <Col>
                       Areas
                    </Col>
                    <Col>
                        <div className='btnright'>
                          <FiMoreVertical/>
                        </div>
                    </Col>


            </Row>
             </div>

                <Collapse in={areasOpen}>
                <div>
                 <div className="row">
                            <div className="col-6">
                            <span><b>All Areas</b></span>
                             <div className="AreaForm_AreaList">
                            <Accordion defaultActiveKey={localarea.region_id}>
                                {regionOptions.filter(reg=> reg.areas.length > 0).map((region) => {
                                  return(
                                      <Accordion.Item eventKey={region.region_id}>
                                        <Accordion.Header>{region.name}</Accordion.Header>
                                        <Accordion.Body>
                                            <Accordion defaultActiveKey={localarea.area_id}>
                                                {region.areas.map((area) => {
                                                  return(
                                                      <Accordion.Item eventKey={area.area_id}>
                                                        <Accordion.Header>{area.name}</Accordion.Header>
                                                        <Accordion.Body>
                                                            {area.child_areas.map((child_area) => {
                                                                return(
                                                                    <Link to="#" onClick={() => handleAreaAdd(child_area)} key={child_area.area_id} className="badge badge-pill badge-area m-1">{child_area.name}</Link>
                                                                    )

                                                            })}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                  )
                                                })}
                                            </Accordion>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                  )
                                })}
                            </Accordion>

                                { /*arealist.filter(area => !inputs.area_to.includes(area.area_id)).map(area => {
                                    return (
                                    <OverlayTrigger
                                          placement="left"
                                          delay={{show: 250, hide: 400 }}
                                          overlay={popover(area)}
                                        >
                                            <Link to="#" onClick={() => handleAreaAdd(area)} key={area.area_id} className="badge badge-pill badge-area m-1">{area.name}</Link>
                                        </OverlayTrigger>
                                    )

                                })*/}
                             </div>
                        </div>
                        <div className="col-6">
                            <span><b>Areas from this area</b></span>
                            <div className="AreaForm_AreaList">
                                {arealist.filter(area => inputs.area_to.includes(area.area_id)).map(area => {
                                      return (
                                          <Link to="#" onClick={() => handleAreaRemove(area)} key={area.area_id} className="badge badge-pill badge-area m-1">{area.name}</Link>

                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                </Collapse>
            <div key='EventsCollapse' className='collapseTitle'
                    onClick={() => setEventsOpen(!eventsOpen)}
                  >
                <Row>
                    <Col>
                       Events
                    </Col>
                    <Col>
                        <div className='btnright'>
                          <FiMoreVertical/>
                        </div>
                    </Col>


            </Row>
             </div>

                <Collapse in={eventsOpen}>
                <div>
                    <div className="row">
                        <div className="col-6">
                            <span><b>All Events</b></span>
                            <div className="AreForm_AreaList">
                                <Link to="#" onClick={() => handleEventShow()} key='newEventButton' className="badge badge-pill badge-add m-1">New Event</Link>

                                {eventlist.filter(event => !inputs.event_list.includes(event.event_id)).map(event => {
                                    return (
                                    <OverlayTrigger
                                          placement="right"
                                          delay={{show: 250, hide: 400 }}
                                          overlay={popover(event)}
                                        >
                                        <Link to="#" onClick={() => handleEventAdd(event)} key={event.event_id} className="badge badge-pill badge-event m-1">{event.name}</Link>

                                    </OverlayTrigger>
                                    )
                                })}

                            </div>
                        </div>
                        <div className="col-6">
                            <span><b>Events in this area</b></span>
                            <div className="AreaForm_AreaList">
                                {eventlist.filter(event => inputs.event_list.includes(event.event_id)).map(event => {
                                    return (
                                        <OverlayTrigger
                                          placement="left"
                                          delay={{show: 250, hide: 400 }}
                                          overlay={popover(event)}
                                        >
                                            <Link to="#" onClick={() => handleEventRemove(event)} key={event.event_id} className="badge badge-pill badge-event m-1">{event.name}</Link>
                                        </OverlayTrigger>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                </Collapse>
        <div key='EncountersCollapse' className='collapseTitle'
                    onClick={() => setEncountersOpen(!encountersOpen)}
                  >
                <Row>
                    <Col>
                       Encounters
                    </Col>
                    <Col>
                        <div className='btnright'>
                          <FiMoreVertical/>
                        </div>
                    </Col>


            </Row>
             </div>

                <Collapse in={encountersOpen}>
                <div>
                     <div className="row">
                            <div className="col-6">
                            <span><b>All Encounters</b></span>
                            <div className="AreaForm_AreaList">
                                <Link to="#" onClick={() => handleEncounterShow()} key='newEncounterButton' className="badge badge-pill badge-add m-1">New Encounter</Link>
                                {encounterlist.filter(encounter => !inputs.encountergroup_list.includes(encounter.encountergroup_id)).map(encountergroup => {
                                    return (
                                        <OverlayTrigger
                                          placement="right"
                                          delay={{show: 250, hide: 400 }}
                                          overlay={popover(encountergroup)}
                                        >
                                            <Link to="#" onClick={() => handleEncounteradd(encountergroup)} key={encountergroup.encountergroup_id} className="badge badge-pill badge-monster m-1">{encountergroup.name}</Link>
                                        </OverlayTrigger>
                                    )
                                })}
                            </div>
                        </div>
                         <div className="col-6">
                            <span><b>Encounters in this area</b></span>
                            <div className="AreaForm_AreaList">
                                {encounterlist.filter(encounter => inputs.encountergroup_list.includes(encounter.encountergroup_id)).map(encountergroup => {
                                    return (
                                        <OverlayTrigger
                                          placement="left"
                                          delay={{show: 250, hide: 400 }}
                                          overlay={popover(encountergroup)}
                                        >
                                            <Link to="#" onClick={() => handleEncounterRemove(encountergroup)} key={encountergroup.encountergroup_id} className="badge badge-pill badge-monster m-1">{encountergroup.name}</Link>
                                        </OverlayTrigger>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                </Collapse>

        </Form>


        </Container>
    )
}
export default Areaform;