import Select from "react-select";
import React, {useEffect, useState} from "react";


const BookSelect = ({books,onChangeBook}) => {

    const [BookOptions, setBookOptions] = useState([])

    const initializeBookOptions = () => {
        let objectlist = []
        let newObj = {}
        books?.map(book => {
            let object = {}
            object.label = book.name
            object.value = book.book_id
            objectlist.push(object)
        })

        setBookOptions(objectlist)
    }

    useEffect(() => {
        initializeBookOptions()
    }, [books])


    return (
            <Select className="form-select"
                options = {BookOptions}
                name='party_id'
                onChange={onChangeBook}
            />
        )


}

export default BookSelect