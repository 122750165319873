import React, {useEffect, useState} from "react";


const ProfilePage = ({user}) => {

    return (

       <div className='BookPage'> <div key='namefield' className='SpellTitle'> {user.full_name}

       </div>

                <div key='username' className='SpellSubTitle'>
                    <div key='time' className='CharacterList'>
                        <p className="text-capitalize" key="username"><b>Username:</b> {user.username} </p>
                        <p className="text-capitalize" key="email"><b>Email:</b> {user.email} </p>
                    </div>

                </div>

             <div className='divider'></div>
            <div key='campaigns'><b>Campaigns</b>
                {user.campaigns?.map(campaign => {
                    return(
                        <div>
                            <p key={campaign.campaign_id}>{campaign.name}</p>
                            <p key={campaign.party.party_id}>{campaign.party.name}</p>
                        </div>
                    )
                    })
                }
            </div>

            </div>
        )
}

export default ProfilePage